import React from "react";
import "../src/css/MainComponent.css";
import KnowledgeCategoryCompo from "./component/KnowledgeCategoryCompo";
import AllNotification from "./component/AllNotification";
import ClientInfoRecCompo from "./component/ClientInfoRecCompo";
import LoadData from "./component/LoadData";
import KnowledgeBase from "./component/KnowledgeBase";
import KnowledgeCompo from "./component/KnowledgeCompo";
import EditorComponent from "./component/EditorComponent";
import PortalComponent from "./component/PortalComponent";
import ClientInfoCompo from "./component/ClientInfoCompo";
import MultiTableRelation from "./component/MultiTableRelation";
import MultiInsertComponent from "./component/MultiInsertComponent";
import RoleSelectionComponent from "./component/RoleSelectionComponent";
import UpdatePassword from "./component/UpdatePassword";
import ChecklistComponent from "./component/ChecklistComponent";
import UiPolicyAction from "./component/UiPolicyAction";
import UiPolicy from "./component/UiPolicy";
import FormViewComponent from "./component/FormViewCompo";
import ReportCompo from "./component/ReportCompo";
import FlowComponent from "./component/FlowComponent";
import VarBlankCompo from "./component/VarBlankCompo";
import ApplicationSet from "./component/ApplicationSet";
import OwnerPrefComp from "./component/OwnerPrefrenceComponent";
import ListComponent from "./component/ListComponent";
import FormComponent from "./component/FormComponent";
import DashboradComponent from "./component/DashboradComponent";
import InvoiceLabel from "./component/InvoiceLabel";
import UserWrapper from "./Wrappers/UserWrapper";
import HtmlPage from "./component/HtmlPage";
import ApplicationSetup from "./component/AppSetup";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import WebWrapper from "./Wrappers/WebWrapper";
import ErrorPage from "./component/ErrorPage";
import LoginForm from "./component/LoginForm";
import RegisterComponent from "./component/RegisterComponent";
import ForgetPassword from "./component/ForgetPassword";
import OtpVerification from "./component/OtpVerification";
import GenerateNewPin from "./component/GenerateNewPin";
import SetNewPassword from "./component/SetNewPassword";
import NewApplication from "./component/NewApplication";
import TestCompo from "./TestCompo/TestCompo";
import PrivateWrapper from "./Wrappers/PrivateWrapper";
import ScriptCompo from "./component/ScriptCompo";
import MultipleAppReq from "./component/MultipleAppReq";
import MultipleAppAccess from "./component/MultipleAppAccess";
import ImportMapping from "./component/ImportMapping";
import PortalCompo from "./component/PortalCompo";
import PortalResult from "./component/PortalResult";
import GridCompo from "./component/GridCompo";
import AdminComponent from "./component/AdminComponent";
import InitialSetupComponentNew from "./component/InitialSetupComponentNew";
import ConfigureCompo from "./component/ConfigureCompo";
import AppAccess from "./component/AppAccess";
import AuthWrapper from "./Wrappers/AuthWrapper";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function AppRouters() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <WebWrapper />,
      children: [
        {
          path: "/error",
          element: <ErrorPage error={null} />,
        },
        {
          path: "/setNewPassword",
          element: <SetNewPassword />,
        },
        {
          path: "/NewApplication",
          element: <NewApplication />,
        },
        {
          path: "/testt",
          element: <TestCompo />,
        },
        {
          element: <AuthWrapper />,
          children: [
            {
              path: "/login",
              element: <LoginForm />,
            },
            {
              path: "/ForgetPassword",
              element: <ForgetPassword />,
            },
            {
              path: "/otpverify",
              element: <OtpVerification />,
            },
            {
              path: "/Newpin",
              element: <GenerateNewPin />,
            },
            {
              path: "/signup",
              element: <RegisterComponent />,
            },
          ],
        },
        {
          path: "/",
          element: <PrivateWrapper />,
          children: [
            {
              path: "/script",
              element: <ScriptCompo />,
            },
            {
              path: "/invoice",
              element: <InvoiceLabel />,
            },
            {
              path: "/multipleAppRequest",
              element: <MultipleAppReq />,
            },
            {
              path: "/multipleAppAccess",
              element: <MultipleAppAccess />,
            },
            {
              path: "/ImportMapping",
              element: <ImportMapping />,
            },
            {
              path: "/portal",
              element: <PortalCompo />,
            },
            {
              path: "/test",
              element: <TestCompo />,
            },
            {
              path: "/portalOutPut",
              element: <PortalResult />,
            },
            {
              path: "/grid",
              element: <GridCompo />,
            },
            {
              path: "/admin",
              element: <AdminComponent />,
            },
            {
              path: "/dashboard",
              element: <DashboradComponent />,
            },
            {
              path: "/initialsetup",
              element: <InitialSetupComponentNew />,
            },
            // {
            //   path: "/initialsetup",
            //   element: <InitialSetupcomponent />,
            // },
            {
              path: "/form",
              element: <FormComponent />,
            },
            {
              path: "/nvoice",
              element: <InvoiceLabel />,
            },
            {
              path: "/list",
              element: <ListComponent />,
            },
            {
              path: "/config_list",
              element: <ConfigureCompo />,
            },
            {
              path: "/OwnerPref",
              element: <OwnerPrefComp />,
            },
            {
              path: "/application",
              element: <ApplicationSet />,
            },
            {
              path: "/flow",
              element: <FlowComponent />,
            },
            {
              path: "/report",
              element: <ReportCompo />,
            },
            {
              path: "/viewcompo",
              element: <FormViewComponent />,
            },
            {
              path: "/policy",
              element: <UiPolicy />,
            },
            {
              path: "/policy_action",
              element: <UiPolicyAction />,
            },
            {
              path: "/multiinsert",
              element: <MultiInsertComponent />,
            },
            {
              path: "/multitable",
              element: <MultiTableRelation />,
            },
            {
              path: "/checklicompo",
              element: <ChecklistComponent />,
            },
            {
              path: "/updatepassword",
              element: <UpdatePassword />,
            },
            {
              path: "/appaccess",
              element: <AppAccess />,
            },
            {
              path: "/clientinfo",
              element: <ClientInfoCompo />,
            },
            {
              path: "/var",
              element: <VarBlankCompo />,
            },
            {
              path: "/appsetup",
              element: <ApplicationSetup />,
            },
            {
              path: "/role-selection",
              element: <RoleSelectionComponent />,
            },
            {
              path: "/knowledge",
              element: <KnowledgeCompo />,
            },
            {
              path: "/knowledgeBase",
              element: <KnowledgeBase />,
            },
            {
              path: "/knowledgeCategory",
              element: <KnowledgeCategoryCompo />,
            },
            {
              path: "/htmlcall",
              element: <HtmlPage />,
            },
            {
              path: "/importdata",
              element: <LoadData />,
            },
          ],
        },
        {
          element: <UserWrapper />,
          children: [
            {
              path: "/invoice",
              element: <InvoiceLabel />,
            },
            {
              path: "/user/dashboard",
              element: <DashboradComponent />,
            },
            {
              path: "/user/form",
              element: <FormComponent />,
            },
            {
              path: "/user/list",
              element: <ListComponent />,
            },
            {
              path: "/user/OwnerPref",
              element: <OwnerPrefComp />,
            },
            {
              path: "/user/application",
              element: <ApplicationSet />,
            },
            {
              path: "/user/var",
              element: <VarBlankCompo />,
            },
            {
              path: "/user/flow",
              element: <FlowComponent />,
            },
            {
              path: "/user/report",
              element: <ReportCompo />,
            },
            {
              path: "/user/viewcompo",
              element: <FormViewComponent />,
            },
            {
              path: "/user/policy",
              element: <UiPolicy />,
            },
            {
              path: "/user/policy_action",
              element: <UiPolicyAction />,
            },
            {
              path: "/user/checklicompo",
              element: <ChecklistComponent />,
            },
            {
              path: "/user/updatepassword",
              element: <UpdatePassword />,
            },
            {
              path: "/user/role-selection",
              element: <RoleSelectionComponent />,
            },
            {
              path: "/user/multiinsert",
              element: <MultiInsertComponent />,
            },
            {
              path: "/user/multitable",
              element: <MultiTableRelation />,
            },
            {
              path: "/user/clientinfo",
              element: <ClientInfoCompo />,
            },
            {
              path: "/user/clientinforecord",
              element: <ClientInfoRecCompo />,
            },
            {
              path: "/user/portal",
              element: <PortalComponent />,
            },
            {
              path: "/user/texteditor",
              element: <EditorComponent />,
            },
            {
              path: "/user/knowledge",
              element: <KnowledgeCompo />,
            },
            {
              path: "/user/knowledgeBase",
              element: <KnowledgeBase />,
            },
            {
              path: "/user/knowledgeCategory",
              element: <KnowledgeCategoryCompo />,
            },
            {
              path: "/user/allNotification",
              element: <AllNotification />,
            },
            {
              path: "/user/clientinforecord",
              element: <ClientInfoRecCompo />,
            },
            {
              path: "/user/importdata",
              element: <LoadData />,
            },
          ],
        },
      ],
    },
  ]);
  return (
    <div>
      <SkeletonTheme baseColor="#d7d7d7ed" highlightColor="#b9b9b9">
        <RouterProvider router={router} />
      </SkeletonTheme>
    </div>
  );
}
