import { Editor } from "@monaco-editor/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "./Header";
import { toast } from "react-toastify";
import axios from "axios";
import { onEditorMount } from "../HelperClasses/Mayadocument";
import { MarketContext } from "../Context";

const ScriptCompo = () => {
  const { token, loca } = useContext(MarketContext);

  const [script, setScript] = useState("");
  const [overlayH, setOverlayH] = useState(0);
  const [isClick, setIsClick] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const loadtext = useRef(null);
  const [subLoadAck, setSubLoadAck] = useState("");

  const runScript = () => {
    setSubmitLoading(true);
    let json = { script: script };
    axios
      .post(loca + "/marketplace/run/script", json, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          var record = resp.data;
          setSubmitLoading(false);
          if (record instanceof Object && "Error" in record) {
            toast.error(record.Error);
          } else {
            toast.success("successfully executed");
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(() => {
        toast("Something went wrong", {
          position: "top-center",
          theme: "colored",
          type: "error",
        });
      });
  };

  useEffect(() => {
    if (submitLoading) {
      const messageTimeouts = [
        setTimeout(() => {
          loadtext.current?.classList.add("show");
          setSubLoadAck("Hang tight, we're processing your request...");
        }, 2000),
        setTimeout(
          () =>
            setSubLoadAck("Working on it... This might take a few moments."),
          7000
        ),
      ];

      // Clear timeouts when component unmounts
      return () => {
        loadtext.current?.classList.add("hide");
        setSubLoadAck("");
        messageTimeouts.forEach((timeout) => clearTimeout(timeout));
      };
    }
  }, [submitLoading]);
  useEffect(() => {
    let h = document.getElementById("scr-form")?.getBoundingClientRect().height;
    console.log("hhhVal", h);

    if (h > 10) {
      setOverlayH(h);
    }
  }, []);

  // const onMount = (editor, monaco) => {
  //   monaco.languages.typescript.javascriptDefaults.addExtraLib(`
  //     declare class MayaRecord {
  //       constructor(tableName: string);
  //       something(): void;
  //       anotherMethod(): void;
  //     }
  //   `);
  //   monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
  //     noSemanticValidation: false,
  //     noSyntaxValidation: false,
  //   });
  // };

  const clearScript = () => {
    setScript("");
  };

  useEffect(() => {
    clearScript();
  }, [refresh]);

  return (
    <div className="w-100" id="scr-form">
      {submitLoading && (
        <>
          <div
            style={{ height: overlayH, width: "75%" }}
            className="loading-overlay d-flex align-items-center justify-content-center"
          ></div>
          <div style={{ height: overlayH }} className="loading-ack">
            <h3 ref={loadtext} className="loading-msg">
              {subLoadAck}
            </h3>
          </div>
        </>
      )}
      <div style={{ position: "sticky", top: "0", zIndex: "1" }}>
        <Header
          tabName={"Script Executer"}
          isClick={isClick}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
      <div
        className="container h-75 my-2 "
        style={{ border: "2px solid black" }}
      >
        <Editor
          language="javascript"
          theme="vs"
          value={script}
          onChange={(e) => {
            setScript(e);
          }}
          options={{
            codeLens: false,
            inlineSuggest: true,
            fontSize: "16px",
            formatOnType: true,
            // readOnly: obj.uivalid.read_only === "true" ? true : false,
            minimap: {
              enabled: false,
              showSlider: "always",
            },
          }}
          onMount={onEditorMount}
          //   onBlur={(e) => {
          //     if (obj.uivalid.read_only !== "true") {
          //       afterCellEdit(e.target.value, obj.name);
          //     }
          //   }}
        />
      </div>
      <div className="container">
        <button
          disabled={script === "" ? true : false}
          className=" btn btn-primary me-2"
          onClick={() => {
            runScript();
          }}
        >
          Run
        </button>
        <button
          disabled={script === "" ? true : false}
          className=" btn btn-primary"
          onClick={() => {
            clearScript();
          }}
        >
          Clear
        </button>
      </div>
    </div>
  );
};

export default ScriptCompo;
