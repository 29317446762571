import React, { useContext, useEffect } from "react";
import { MarketContext } from "../Context";
import { Outlet, useNavigate } from "react-router-dom";

export default function AuthWrapper() {
  const { adminVerified } = useContext(MarketContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (adminVerified) {
      navigate(-1, { replace: true });
    }
  }, [adminVerified, navigate]); // Run effect when `adminVerified` or `navigate` changes

  if (!adminVerified) {
    return <Outlet />;
  }

  return null;
}
