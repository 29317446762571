import React, { useRef, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { MarketContext } from "../Context";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

const CogCompo = ({ cogChangefn, cancelCog }) => {
  const {
    showAll,
    setShowAll,
    token,
    loca,
    appname,
    userDetails,
    getVerify,
    setAppName,
  } = useContext(MarketContext);
  const raf = useRef(false);
  const [file, setFile] = useState();
  const [modal, setModal] = useState(false);
  const [isClick, setIsClick] = useState(false);

  useEffect(() => {
    if (raf.current) {
      axios
        .get(loca + "/marketplace/get/visible", {
          headers: {
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            let result = resp.data;
            // alert(result)
            setShowAll(result.toString());
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      raf.current = true;
    }
  }, [showAll]);

  const deleteApp = () => {
    axios
      .get(loca + "/delete/application", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (response) => {
          let res = response.data;
          if (res.Success) {
            setAppName("Marketplace");
            toast(res.Success, {
              position: "top-center",
              theme: "colored",
              type: "success",
              style: {
                marginBottom: userDetails.OS !== "null" ? 12 : 0,
              },
            });
            // getVerify();
          } else {
            toast(res.Error, {
              position: "top-center",
              theme: "colored",
              type: "error",
              style: {
                marginBottom: userDetails.OS !== "null" ? 12 : 0,
              },
            });
          }
          setModal(false);
          cancelCog();
        },
        (err) => {
          console.log(err);
        }
      );
  };

  const csvDownload = () => {
    axios
      .get(loca + "/exportFullSchema", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
        responseType: "blob",
      })
      .then(
        (response) => {
          let res = response.data;
          const link = document.createElement("a");
          const url = URL.createObjectURL(res);
          link.href = url;
          link.download = "full_db";
          link.click();
          link.remove();
        },
        (err) => {
          console.log(err);
        }
      );
  };

  const csvUpload = () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }
    // cancelCog();
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(loca + "/importFullSchema", formData, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (response) => {
          let res = response.data;
          toast(res, {
            position: "top-center",
            theme: "colored",
            type: "success",
            style: {
              marginBottom: userDetails.OS !== "null" ? 12 : 0,
            },
          });
        },
        (err) => {
          console.log(err);
        }
      );
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <Modal show={modal} onHide={() => setModal(!modal)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModal(!modal)}
            disabled={isClick}
            style={{ backgroundColor: isClick ? "gray" : "" }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteApp()}
            disabled={isClick}
            style={{ backgroundColor: isClick ? "gray" : "" }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex align-items-center">
        <input
          type="checkbox"
          className="checkpadd mb-0"
          // value={obj.value === "true" ? true : false}

          checked={showAll === "true" ? true : false}
          // checked={showAll === "true" }

          onChange={(e) => {
            cogChangefn(e.target.checked.toString());
          }}
        ></input>
        <span>Show All</span>
      </div>
      {/* <hr />
      <h5>Import/Export Application</h5>

      <ul>
        <li>
          <button
            type="button"
            className="btn btn-info btn-sm ms-0"
            onClick={csvDownload}
          >
            Export
          </button>
        </li>

        <li className="mt-2">
          <input
            type="file"
            accept=".zip"
            className="bg-slate-50"
            onChange={handleFileChange}
          />
          <br />
          <button
            className="btn btn-success mt-2 btn-sm"
            onClick={() => {
              csvUpload();
            }}
            style={{ margin: "0px" }}
          >
            Import
          </button>
        </li>
      </ul> */}

      {/* <hr />
      <div>
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => setModal(!modal)}
          style={{ margin: "0px", marginTop: "2%" }}
        >
          Delete Application
        </button>
      </div> */}
    </div>
  );
};

export default CogCompo;
