import axios from "axios";
import { MarketContext } from "../Context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import "../css/initialCSS.css";

const InitialSetupComponentNew = () => {
  const { token, loca, getVerify, setReloadLeft, user, appname } =
    useContext(MarketContext);
  const [isProcessing, setProcessing] = useState([
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
  ]);
  const [disableArr, setDisableArr] = useState([
    { state: "true" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
  ]);
  const [disableLast, setDisableLast] = useState(true)
  const [processingLast, setProcessingLast] = useState(false)
  const navigate = useNavigate();
  const [text, setText] = useState("");


  const initialsetuprecordcallapi = () => {
    let proArr = isProcessing;
    proArr[0].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/marketplace/initial/setuprecord", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        proArr[0].state = "false";
        setProcessing([...proArr]);
        let arr = disableArr;
        arr[0].state = "success";
        arr[1].state = "true";
        setDisableArr(arr);
        initialsetupcallapi1()
        initialsetupcallapi2()
        initialsetupcallapi3()
        initialsetupcallapi4()
        initialsetupcallapi5()
        initialsetupcallapi6()
        initialsetupcallapi7()
        initialsetupcallapi8()
        initialsetupcallapi9()
        initialsetupcallapi10()
        initialsetupcallapi11()
      });
  };


  const initialsetupcallapi1 = () => {
    let proArr = isProcessing;
    proArr[1].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/marketplace/initial/setup1", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        proArr[1].state = "false";
        setProcessing([...proArr]);
        let arr = disableArr;
        arr[1].state = "success";
        setDisableArr(arr);
      }).catch((err) => {
        console.log(err);
      });
  };

  const initialsetupcallapi2 = () => {
    let proArr = isProcessing;
    proArr[2].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/marketplace/initial/setup2", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        proArr[2].state = "false";
        setProcessing([...proArr]);
        let arr = disableArr;
        arr[2].state = "success";
        setDisableArr(arr);
      }).catch((err) => {
        console.log(err);
      });
  };

  const initialsetupcallapi3 = () => {
    let proArr = isProcessing;
    proArr[3].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/marketplace/initial/setup3", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        proArr[3].state = "false";
        setProcessing([...proArr]);
        let arr = disableArr;
        arr[3].state = "success";
        setDisableArr(arr);
      }).catch((err) => {
        console.log(err);
      });
  };

  const initialsetupcallapi4 = () => {
    let proArr = isProcessing;
    proArr[4].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/marketplace/initial/setup4", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        proArr[4].state = "false";
        setProcessing([...proArr]);
        let arr = disableArr;
        arr[4].state = "success";
        setDisableArr(arr);
      }).catch((err) => {
        console.log(err);
      });
  };

  const initialsetupcallapi5 = () => {
    let proArr = isProcessing;
    proArr[5].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/marketplace/initial/setup5", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        proArr[5].state = "false";
        setProcessing([...proArr]);
        let arr = disableArr;
        arr[5].state = "success";
        setDisableArr(arr);
      }).catch((err) => {
        console.log(err);
      });
  };

  const initialsetupcallapi6 = () => {
    let proArr = isProcessing;
    proArr[6].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/marketplace/initial/setup6", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        proArr[6].state = "false";
        setProcessing([...proArr]);
        let arr = disableArr;
        arr[6].state = "success";
        setDisableArr(arr);
      }).catch((err) => {
        console.log(err);
      });
  };

  const initialsetupcallapi7 = () => {
    let proArr = isProcessing;
    proArr[7].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/marketplace/initial/setup7", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        proArr[7].state = "false";
        setProcessing([...proArr]);
        let arr = disableArr;
        arr[7].state = "success";
        setDisableArr(arr);
      }).catch((err) => {
        console.log(err);
      });
  };

  const initialsetupcallapi8 = () => {
    let proArr = isProcessing;
    proArr[8].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/marketplace/initial/setup8", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        proArr[8].state = "false";
        setProcessing([...proArr]);
        let arr = disableArr;
        arr[8].state = "success";
        setDisableArr(arr);
      }).catch((err) => {
        console.log(err);
      });
  };

  const initialsetupcallapi9 = () => {
    let proArr = isProcessing;
    proArr[9].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/marketplace/initial/setup9", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        proArr[9].state = "false";
        setProcessing([...proArr]);
        let arr = disableArr;
        arr[9].state = "success";
        setDisableArr(arr);
      }).catch((err) => {
        console.log(err);
      });
  };
  const initialsetupcallapi10 = () => {
    let proArr = isProcessing;
    proArr[10].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/marketplace/initial/setup10", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        proArr[10].state = "false";
        setProcessing([...proArr]);
        let arr = disableArr;
        arr[10].state = "success";
        setDisableArr(arr);
        setDisableLast(false)
      }).catch((err) => {
        console.log(err);
      });
  };

  const initialsetupcallapi11 = () => {
    let proArr = isProcessing;
    proArr[11].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/marketplace/initial/setupImportData", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        proArr[11].state = "false";
        setProcessing([...proArr]);
        let arr = disableArr;
        arr[11].state = "success";
        setDisableArr(arr);
      }).catch((err) => {
        console.log(err);
      });
  };

  const initialsetupcallapiFinal = () => {
    setProcessingLast(true)
    axios
      .get(loca + "/marketplace/initial/setup11", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        initialdisplaynull();
      }).catch((err) => {
        console.log(err);
      });
  };

  const initialdisplaynull = () => {
    axios
      .get(loca + "/marketplace/initialsetup/delete", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        const modul = res.data;
        if ("result" in modul) {
          setReloadLeft(true);
          setCurrentApplication();
        }
      }).catch((err) => {
        console.log(err);
      });
  };

  const setCurrentApplication = () => {
    axios
      .get(loca + "/marketplace/set/userapplication/marketplace", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          getVerify();
          navigate(user + "/dashboard");
          setText("your current application setup is done!");
        }
      }).catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container text-center">
      <div className="alert alert-warning mt-2">Do not refresh this page!</div>
      <div className="row justify-content-center  mt-2">
        <div className="col">
          <button
            onClick={() => initialsetuprecordcallapi()}
            disabled={
              disableArr[0].state === "false" ||
              disableArr[0].state === "success" ||
              isProcessing[0].state === "true"
            }
            className={
              disableArr[0].state === "success"
                ? "btn btn-success text-nowrap"
                : "btn btn-primary text-nowrap"
            }
          >
            {isProcessing[0].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"

                ></span>
                Loading...
              </span>
            ) : disableArr[0].state === "success" ? (
              "Completed"
            ) : (
              "Setup Core Data"
            )}
          </button>
        </div>
      </div>
      <div className="px-5 row justify-content-center gap-5  mt-4">
        <div className="col-md-3">
          <button
            type="button"
            disabled={
              disableArr[1].state === "false" ||
              disableArr[1].state === "success" ||
              isProcessing[1].state === "true"
            }
            className={
              disableArr[1].state === "success"
                ? "btn btn-success text-nowrap"
                : "btn btn-primary text-nowrap"
            }
          >
            {isProcessing[1].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"

                ></span>
                Loading...
              </span>
            ) : disableArr[1].state === "success" ? (
              "Completed"
            ) : (
              "Initial Setup 1"
            )}
          </button>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            disabled={
              disableArr[2].state === "false" ||
              disableArr[2].state === "success" ||
              isProcessing[2].state === "true"
            }
            className={
              disableArr[2].state === "success"
                ? "btn btn-success text-nowrap"
                : "btn btn-primary text-nowrap"
            }
          >
            {isProcessing[2].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"

                ></span>
                Loading...
              </span>
            ) : disableArr[2].state === "success" ? (
              "Completed"
            ) : (
              "Initial Setup 2"
            )}
          </button>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            disabled={
              disableArr[3].state === "false" ||
              disableArr[3].state === "success" ||
              isProcessing[3].state === "true"
            }
            className={
              disableArr[3].state === "success"
                ? "btn btn-success text-nowrap"
                : "btn btn-primary text-nowrap"
            }
          >
            {isProcessing[3].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"

                ></span>
                Loading...
              </span>
            ) : disableArr[3].state === "success" ? (
              "Completed"
            ) : (
              "Initial Setup 3"
            )}
          </button>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            disabled={
              disableArr[4].state === "false" ||
              disableArr[4].state === "success" ||
              isProcessing[4].state === "true"
            }
            className={
              disableArr[4].state === "success"
                ? "btn btn-success text-nowrap"
                : "btn btn-primary text-nowrap"
            }
          >
            {isProcessing[4].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"

                ></span>
                Loading...
              </span>
            ) : disableArr[4].state === "success" ? (
              "Completed"
            ) : (
              "Initial Setup 4"
            )}
          </button>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            disabled={
              disableArr[5].state === "false" ||
              disableArr[5].state === "success" ||
              isProcessing[5].state === "true"
            }
            className={
              disableArr[5].state === "success"
                ? "btn btn-success text-nowrap"
                : "btn btn-primary text-nowrap"
            }
          >
            {isProcessing[5].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"

                ></span>
                Loading...
              </span>
            ) : disableArr[5].state === "success" ? (
              "Completed"
            ) : (
              "Initial Setup 5"
            )}
          </button>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            disabled={
              disableArr[6].state === "false" ||
              disableArr[6].state === "success" ||
              isProcessing[6].state === "true"
            }
            className={
              disableArr[6].state === "success"
                ? "btn btn-success text-nowrap"
                : "btn btn-primary text-nowrap"
            }
          >
            {isProcessing[6].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"

                ></span>
                Loading...
              </span>
            ) : disableArr[6].state === "success" ? (
              "Completed"
            ) : (
              "Initial Setup 6"
            )}
          </button>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            disabled={
              disableArr[7].state === "false" ||
              disableArr[7].state === "success" ||
              isProcessing[7].state === "true"
            }
            className={
              disableArr[7].state === "success"
                ? "btn btn-success text-nowrap"
                : "btn btn-primary text-nowrap"
            }
          >
            {isProcessing[7].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"

                ></span>
                Loading...
              </span>
            ) : disableArr[7].state === "success" ? (
              "Completed"
            ) : (
              "Initial Setup 7"
            )}
          </button>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            value={text}
            disabled={
              disableArr[8].state === "false" ||
              disableArr[8].state === "success" ||
              isProcessing[8].state === "true"
            }
            className={
              disableArr[8].state === "success"
                ? "btn btn-success text-nowrap"
                : "btn btn-primary text-nowrap"
            }
          >
            {isProcessing[8].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"

                ></span>
                Loading...
              </span>
            ) : disableArr[8].state === "success" ? (
              "Completed"
            ) : (
              "Initial Setup 8"
            )}
          </button>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            value={text}
            disabled={
              disableArr[9].state === "false" ||
              disableArr[9].state === "success" ||
              isProcessing[9].state === "true"
            }
            className={
              disableArr[9].state === "success"
                ? "btn btn-success text-nowrap"
                : "btn btn-primary text-nowrap"
            }
          >
            {isProcessing[9].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"

                ></span>
                Loading...
              </span>
            ) : disableArr[9].state === "success" ? (
              "Completed"
            ) : (
              "Initial Setup 9"
            )}
          </button>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            value={text}
            disabled={
              disableArr[10].state === "false" ||
              disableArr[10].state === "success" ||
              isProcessing[10].state === "true"
            }
            className={
              disableArr[10].state === "success"
                ? "btn btn-success text-nowrap"
                : "btn btn-primary text-nowrap"
            }
          >
            {isProcessing[10].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"

                ></span>
                Loading...
              </span>
            ) : disableArr[10].state === "success" ? (
              "Completed"
            ) : (
              "Initial Setup 10"
            )}
          </button>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            value={text}
            disabled={
              disableArr[11].state === "false" ||
              disableArr[11].state === "success" ||
              isProcessing[11].state === "true"
            }
            className={
              disableArr[11].state === "success"
                ? "btn btn-success text-nowrap"
                : "btn btn-primary text-nowrap"
            }
          >
            {isProcessing[11].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"

                ></span>
                Loading...
              </span>
            ) : disableArr[11].state === "success" ? (
              "Completed"
            ) : (
              "Initial Setup 11"
            )}
          </button>
        </div>
      </div>
      <div className="flex justify-content-center mx-5 mt-3">
        <div>
          <button
            type="button"
            value={text}
            onClick={() => initialsetupcallapiFinal()}
            disabled={
              disableLast ||
              processingLast
            }
            className={
              disableLast
                ? "btn btn-success text-nowrap w-100"
                : "btn btn-primary text-nowrap w-100"
            }
          >
            {processingLast ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"

                ></span>
                Loading...
              </span>
            ) : (
              "Click to finish"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InitialSetupComponentNew;
