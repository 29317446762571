import React from "react";
import CheckMandatoryField from "../component/CheckMandatoryField";
import { UncontrolledTooltip } from "reactstrap";
import AlertCompo from "./AlertCompo";

export default function FormLabels({ obj, m_rid, setContextMenu, isSub, form_id,multiUpdate }) {
  return (
    <>
      <CheckMandatoryField obj={obj} />
      <span
        id={"tiptar" + obj.index}
        className="field_hd"
        onTouchStart={(e) => {
          setTimeout(() => {
            e.preventDefault();
            m_rid.current = obj.id;
            setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
          }, 100);
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          m_rid.current = obj.id;
          if (isSub.current) {
            let parent = document
              .getElementById(form_id)
              .getBoundingClientRect();
            setContextMenu(
              e.button,
              e.nativeEvent.clientX - parent.left,
              e.nativeEvent.clientY - parent.top
            );
          } else {
            setContextMenu(
              e.button,
              e.nativeEvent.pageX,
              e.nativeEvent.pageY
            );
          }
        }}
      >
       {!multiUpdate && obj.label.name}
      </span>
      {obj.label.showTooltip === "true" && (
        <UncontrolledTooltip
          arrowClassName="tip"
          className="tip"
          innerClassName="tip text-dark text-nowrap"
          target={"tiptar" + obj.index}
          placement="right"
        >
          <pre className="form-pre-tooltip">{obj.label.tooltip}</pre>
        </UncontrolledTooltip>
      )}
      {obj.label.showHelper === "true" && (
        <div className="my-2">
          <AlertCompo
            type={"warning"}
            msg={<pre className="form-pre-helper">{obj.label.helper}</pre>}
          />
        </div>
      )}
    </>
  );
}
