import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { MarketContext } from "../Context";
import WorkInProgress from "./WorkInProgress";

const KnowledgeCompo = () => {
  // Base Hooks
  const { token,loca,appname } = useContext(MarketContext);
  const navigation = useNavigate();
  const location = useLocation().state;
  const [searchParam, setSearchParam] = useSearchParams();

  // UseStates
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [pageError, setPageError] = useState(true);
  const [title, setTitle] = useState("");
  const [html, setHtml] = useState("");
  const [records, setRecords] = useState([]);

  // UseRefs
  const tableName = useRef("");
  const catId = useRef("");
  const baseId = useRef("");
  const knowledgeName = useRef("");

  // Functions
  const getKnowledge = () => {
    tableName.current = searchParam.get("tableName");
    setLoading(true);
    if (tableName.current) {
      axios
        .get(loca + "/marketplace/get/knowledge/" + tableName.current, {
          headers: {
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            const rvrecord = resp.data.knowledge;
            console.log(resp, rvrecord);
            if ("Error" in rvrecord) {
              setPageError(true);
              setError(rvrecord.error);
              setLoading(false);
            } else {
              let arr = [];
              arr.push(rvrecord.knowledge);
              setRecords([...arr]);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      toast.error("Table Name not Found");
      setLoading(false);
      return;
    }
  };

  const getKnowledgeByCategoryAndBase = () => {
    setPageError(false);
    setError("");
    setLoading(true);
    if (catId.current && baseId.current && knowledgeName.current) {
      axios
        .get(
          loca +
            "/marketplace/get/knowledge/" +
            catId.current +
            "/" +
            baseId.current +
            "/" +
            knowledgeName.current,
          {
            headers: {
              authorization: "Bearer " + token,
              "market-application": appname,
            },
          }
        )
        .then(
          (resp) => {
            const rvrecord = resp.data;
            console.log(rvrecord);
            if ("Error" in rvrecord) {
              setPageError(true);
              setError(rvrecord.Error);
              setLoading(false);
            } else {
              // setHtml(rvrecord.knowledge.html);
              // setTitle(rvrecord.knowledge.title);
              // let arr = [];
              // arr.push(rvrecord.knowledge);
              setRecords(rvrecord.knowledge);
              setLoading(false);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else if (catId.current && baseId.current) {
      axios
        .get(
          loca + "/marketplace/get/knowledge/" + catId.current + "/" + baseId.current,
          {
            headers: {
              authorization: "Bearer " + token,
              "market-application": appname,
            },
          }
        )
        .then(
          (resp) => {
            const rvrecord = resp.data;
            if ("Error" in rvrecord) {
              setPageError(true);
              setError(rvrecord.Error);
              setLoading(false);
            } else {
              // setHtml(rvrecord.knowledge.html);
              // setTitle(rvrecord.knowledge.title);
              setRecords(rvrecord.knowledge);
              setLoading(false);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      toast.error("Please give correct categoryid , baseid and name");
      setLoading(false);
      return;
    }
  };

  // UseEffects
  useEffect(() => {
    if (location) {
      if (location.knowledge.knowledge !== "") {
        // setTitle(location.knowledge.knowledge.title);
        // setHtml(location.knowledge.knowledge.html);
        let arr = [];
        arr.push(location.knowledge.knowledge);
        setRecords(arr);
        setLoading(false);
      } else {
        getKnowledge();
      }
    } else {
      catId.current = searchParam.get("categoryid");
      baseId.current = searchParam.get("baseid");
      knowledgeName.current = searchParam.get("name");
      getKnowledgeByCategoryAndBase();
    }

    return () => {
      setPageError(false);
      setError("");
    };
  }, []);

  if (!loading) {
    return (
      <div className="pagesetup">
        {pageError && <div className="alert alert-danger">{error}</div>}
        {records.length > 0 &&
          records.map((obj, ind) => (
            <div key={ind}>
              <h1 className=" text-center">{obj.title}</h1>
              <div className="container justify-content-center">
                <div
                  className=" card card-body"
                  dangerouslySetInnerHTML={{ __html: obj.html }}
                ></div>
              </div>
            </div>
          ))}
      </div>
    );
  } else {
    return <WorkInProgress />;
  }
};

export default KnowledgeCompo;
