
import axios from "axios";
import { ApplicationProperties } from "../ApplicationProperties";

const appname = ApplicationProperties.appname

export function  formChangeFunction (
   { location,vl,
    index,
    ob,
    type,
    id,
    vrf,
    obj,
    outerIndex,
    innerIndex,
    nme,
    props,
    depCol,
    readonly ,record  ,setRecord ,tableName ,addInChangedRowAndCols,loca,token}
  )  {
    console.log("obj19" ,obj);

    let frecord=[]
    if(location==="list")
        frecord= record[0].rcd[obj.rowIndex].colr
    else   
        frecord = record;
    //  setting inital states before changing values
    fcf_intital({index, name:ob, value:vl, type, id ,frecord:record ,setRecord ,tableName});

    //  runs ui policy
   // fcf_Uipolicy(ob, vl, frecord);
    console.log("vll", frecord);
    console.log("index", index);

    if (type === "reference") {
      fcf_reference({index, value:vl, refId:id, type, colName:ob, object:obj, verify:vrf, properties:props,
         depend_column:depCol, frecord,location,setRecord ,record ,loca,token});
    } else if (type === "depend_table") {
      console.log(vl, id, ob, frecord[index].name);
      if (frecord[index].name === ob) {
        console.log(vl, id);
        if (vl === "") {
          frecord[index].value.name = "";
          frecord[index].value.id = "0";
        } else {
          frecord[index].value.name = vl;
          frecord[index].value.id = id;
        }
        frecord[index].verified = "";
        frecord[index].rcd_info = "true";
        console.log(frecord[index]);

         if(location==="list")
        setRecord([...record])
        else
        setRecord([...frecord]);
      }
    } else if (type === "choice"  || type === "boolean") {
      console.log(vl, readonly);
      if (readonly === "false") {
        console.log(vl);
        frecord[index].value = vl;
        if(location==="list")
            setRecord([...record])
            else
            setRecord([...frecord]);
      }
    } else if (type === "dynamic_key_value") {
    //  fcf_dynamicKeyValue(vl, index, innerIndex, outerIndex, frecord);
    }
    else if (type === "key_value") {
    //   if (frecord[index].name === ob) {
    //     console.log(" key value check", nme, vl);
    //     const updatedProperties = [...keyValueJson.properties];
    //     updatedProperties[outerIndex] = {
    //       name: nme,
    //       value: vl,
    //     };
    //     setKeyValueJson({
    //       properties: updatedProperties,
    //     });
    //   }
    }
    else {
      if (frecord[index].name === ob) {
        frecord[index].value = vl;
        if(location==="list")
            setRecord([...record])
            else
            setRecord([...frecord]);
      }
    }

    // ui script for onchange
   // fcf_Uiscript(ob, vl, obj);

    // console.log(record);
    addInChangedRowAndCols(index, obj);
  };

  // setShouldBlock  setChoice_mn  setRecord  callColumnByColId  isBackAlertShow ,tableName

  const fcf_intital = ({index, name, value, object, type, refId, frecord ,setShouldBlock ,setChoice_mn ,setRecord,callColumnByColId ,isBackAlertShow , tableName}) => {

    // if (isBackAlertShow.current) {
    //   setShouldBlock(true);
    // } else {
    //   isBackAlertShow.current = true;
    // }

    // if (name === "form_table_id" && value === "") {
    //   setChoice_mn([]);
    // }
     console.log("1612" ,value);

    if (name === "item_details_id") {
      let a = frecord.findIndex((e) => e.name === name);
      index = a;
    }

    if (type === "reference" && value.length < 3) {
      let a = frecord.findIndex(
        (e) => e.depend_column === object.name && e.name !== object.name
      );

      console.log(a);
      if (a >= 0) {
        frecord[a].dep_choice = [];
        setRecord(frecord);
      }
    }

    if (tableName.current === "reference_filter") {
    //  console.log("ref filt check", refId);
      if (name === "form_column_id") {
        callColumnByColId(type, "form_column_id");
      }
    }
  };

  // viewList policyFilter defUiValid   
  // const fcf_Uipolicy = ({colName, value, frecord}) => {
  //   let selectedId = "";
  //   for (let t = 0; t < viewList?.length; t++) {
  //     if (viewList[t].selected === "true") selectedId = viewList[t].id;
  //   }

  //   if (policyFilter != undefined) {
  //     for (let s = 0; s < policyFilter.length; s++) {
  //       let pf = policyFilter[s].filter;
  //       let pfId = policyFilter[s].id;
  //       let global = policyFilter[s].global;
  //       let form_view_id = policyFilter[s].form_view_id;
  //       let reverse = policyFilter[s].reverse_if_false;

  //       if (
  //         pf !== undefined &&
  //         pf !== "" &&
  //         (global === "true" || selectedId === form_view_id)
  //       ) {
  //         console.log(pf);
  //         var pfilt = pf.filter;
  //         for (let i = 0; i < pfilt.length; i++) {
  //           let p_policy = pfilt;
  //           let name = pfilt[i].co;
  //           let type = pfilt[i].ct;
  //           let sign = pfilt[i].mc;
  //           let answer;
  //           if (type === "reference") {
  //             answer = pfilt[i].rf.value;
  //           } else if (type === "depend_table") {
  //             answer = pfilt[i].dc.value;
  //           } else {
  //             answer = pfilt[i].an;
  //           }
  //           if (name == colName) {
  //             let arr = defUiValid;
  //             console.log("check policy", sign);
  //             console.log("defUiValid g ", defUiValid);
  //             switch (sign) {
  //               case "is":
  //                 console.log(answer, value);
  //                 if (answer == value) {
  //                   setPolicy(frecord, pfId, colName, reverse);
  //                   // setPolicy(frecord, pfId, colName, reverse);
  //                 } else {
  //                   removeAction(frecord, colName);
  //                   // removeAction(frecord, colName);
  //                 }
  //                 break;
  //               case ">":
  //                 if (answer > value) {
  //                   if (
  //                     !isNaN(Date.parse(answer)) &&
  //                     !isNaN(Date.parse(value))
  //                   ) {
  //                   } else {
  //                   }
  //                   setPolicy(frecord, pfId, colName);
  //                 } else {
  //                   removeAction(frecord, colName);
  //                 }
  //                 break;
  //               case "<":
  //                 if (answer < value) {
  //                   setPolicy(frecord, pfId, colName);
  //                 } else {
  //                   removeAction(frecord);
  //                 }
  //                 break;

  //               default:
  //                 break;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // };


  // defUiValid   policyaction setDefUiValid
  // const setPolicy = (frecord, policyId, ob, reverse) => {
  //   console.log(" setPolicy ", policyaction, reverse);
  //   let arr = defUiValid;
  //   // if (!(reverse === "false")) {
  //   if (!(reverse === "false")) {
  //     for (let t = 0; t < policyaction.length; t++) {
  //       for (let n = 0; n < frecord.length; n++) {
  //         if (
  //           policyId === policyaction[t].policy_id &&
  //           policyaction[t].form_column_id === frecord[n].id
  //         ) {
  //           let foundObject = arr.find((obj) => obj.id === frecord[n].id);
  //           if (!foundObject) {
  //             arr.push({
  //               id: frecord[n].id,
  //               uivalid: JSON.parse(JSON.stringify(frecord[n].uivalid)),
  //               name: ob,
  //             });
  //           }
  //         }
  //       }
  //     }
  //   }
  //   console.log("arr", arr);
  //   setDefUiValid(arr);
  //   for (let t = 0; t < policyaction.length; t++) {
  //     if (policyId === policyaction[t].policy_id) {
  //       for (let j = 0; j < frecord.length; j++) {
  //         if (frecord[j].id === policyaction[t].form_column_id) {
  //           if (
  //             policyaction[t].visible !== "null" &&
  //             policyaction[t].visible !== "leave"
  //           ) {
  //             frecord[j].uivalid.visible = policyaction[t].visible;
  //           }
  //           if (
  //             policyaction[t].read_only !== "null" &&
  //             policyaction[t].read_only !== "leave"
  //           ) {
  //             frecord[j].uivalid.read_only = policyaction[t].read_only;
  //           }
  //           if (
  //             policyaction[t].mandatory !== "null" &&
  //             policyaction[t].mandatory !== "leave"
  //           ) {
  //             frecord[j].uivalid.mandatory = policyaction[t].mandatory;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   // }
  // };

  
  // const removeAction = (frecord, ob) => {
  //   let arr = [];

  //   for (let o = 0; o < frecord.length; o++) {
  //     for (let p = 0; p < defUiValid.length; p++) {
  //       if (frecord[o].id === defUiValid[p].id && ob === defUiValid[p].name) {
  //         frecord[o].uivalid = defUiValid[p].uivalid;
  //         arr.push(defUiValid[p]);
  //       }
  //     }
  //   }
  //   let newArray2 = defUiValid.filter(
  //     (obj2) => !arr.some((obj1) => obj1.id === obj2.id)
  //   );
  //   setDefUiValid(newArray2);
  // };


  // setVerifyError 
  export function  validationFunction  ({vl, index, ob, type, id ,setVerifyError, record  ,setRecord,location,obj,loca,token}) {
 //   var formrecord = record;
 let formrecord=[]
    if(location==="list")
        formrecord= record[0].rcd[obj.rowIndex].colr
    else   
        formrecord = record;
    console.log(vl, index, ob, type, id);
    if (type === "reference") {
      if (vl !== "") {
        console.log(vl);
        var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
        veri += '{"tabvalue":' + JSON.stringify(vl) + "}]}";
        console.log(veri);
        axios
          .post(loca + `/marketplace/reference/verify`, veri.toString(), {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
              "market-application":appname
            },
          })
          .then((resp) => {
            const refrencercd = resp.data;
            console.log(refrencercd);
            if (refrencercd.Result === "Unverified") {
              formrecord[index].verified = "unverified";
              // this.setState({ record: formrecord });
              setRecord([...formrecord]);
            } else {
              formrecord[index].verified = "verified";
              // this.setState({ record: formrecord, refrecord: [] });
              setRecord([...formrecord]);
             // setRefRecord([]);
            }

            // this.setState({ record: formrecord });
            setRecord([...formrecord]);

            return;
          });
      } else {
        formrecord[index].verified = "initial";
        // this.setState({ record: formrecord });
        setRecord([...formrecord]);
      }
    } else {
      var minLength = formrecord[index].uivalid.min_length;
      if (minLength !== 0 && vl.length < minLength && vl.length != 0) {
        setVerifyError("Length of character or number should be " + minLength);
        formrecord[index].verified = "unverified";
      } else {
        if (formrecord[index].name === ob) {
          console.log(type);

          if (type === "int" || vl !== "" || type === "long") {
             let type=formrecord[index].type
             let vld=formrecord[index].validation
            formrecord[index].verified = fieldverify({type, vl,vld ,setVerifyError});
            console.log(formrecord[index].verified);
          } else {
            formrecord[index].verified = "initial";
          }
        }
      }
      // this.setState({ record: formrecord });

    //  setRecord([...formrecord]);
      if(location==="list")
        setRecord([...record])
        else
        setRecord([...formrecord]);
    }
    // if (type === "boolean") {
    // } else {
    //   // setCheck(!check);
    // }
  };


  const fieldverify = ({type, vl, validation,setVerifyError}) => {
    if (type === "String") {
      if (validation === "number") {
        if (/^[0-9]*$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your number validation");
          return "unverified";
        }
      } else if (validation === "name") {
        if (/^[a-z][a-z0-9_]*$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your name validation");
          return "unverified";
        }
      } else if (validation === "numbersAndCharacters") {
        if (/^[a-z0-9_]*$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your numbersAndCharacters validation");
          return "unverified";
        }
      } else if (validation === "character") {
        if (/^[a-zA-Z\s]+$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your character validation");
          return "unverified";
        }
      } else if (validation === "withoutSpecialCharacter") {
        if (/^[_A-z0-9\s]*((-|\s)*[_A-z0-9])*$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError(
            "Please verify your withoutSpecialCharacter validation"
          );
          return "unverified";
        }
      } else if (validation === "zipCode") {
        if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your zipCode validation");
          return "unverified";
        }
      } else if (validation === "decimal") {
        if (/^\d*\.?\d*$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your decimal validation");
          return "unverified";
        }
      } else if (validation === "app_code") {
        if (/^[a-z][a-z0-9]*$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your app_code validation");
          return "unverified";
        }
      } else if (validation === "ipAddress") {
        if (/((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}$/.test(vl)) {
          return "verified";
        } else
         {
          setVerifyError("Please verify your ipAddress validation");
          return "unverified";
        }
      } else if (validation === "all") {
        return "verified";
      } else {
        return "verified";
      }
    } else if (type === "email") {
      console.log("check email");
      if (/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(vl)) {
        return "verified";
      } else {
        setVerifyError("Please verify your email!");
        return "unverified";
      }
    } else if (type === "int" || type === "long") {
      if (/^[0-9]*$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    } else if (type === "datetime") {
      return "verified";
    } else {
      return "verified";
    }
  };

  export  function callbtnFunction (nam) {
    // setIsClick(true);
    // setSubmitLoading(true);
    // setShouldBlock(false);
    // setWarning("");
    // let btn = button;
    // var mnrecord = mainRecord;
    // var rcd = record;
    // var mandatory = [];
    // var unverified = [];
    // let imggg = 0;
    // var btntype = "";
    // var btnRtype = "";
    // var foundbtn = false;

    // console.log("abc");

    // for (let i = 0; i < uiScript?.length; i++) {
    //   let func = uiScript[i].script;
    //   let type = uiScript[i].type;
    //   if (type === "onsubmit") {
    //     onSubmit(func);
    //   }
    // }
    // if (insert.current != "") {
    //      addInsertButton(btn)
    // }
    // checkMandatories(rcd, mandatory, unverified, imggg);
    // for (var ij = 0; ij < btn.length; ij++) {
    //   if (btn[ij].value === nam) {
    //     btntype = btn[ij].returnWebLocation;
    //     btnRtype = btn[ij].recordReturnType;
    //     foundbtn = true;
    //     break;
    //   }
    // }
    // console.log("final form submit", btntype, foundbtn);
    // if (foundbtn === true || insert.current !== "") {
    //   setPageError(false);
    //   setError("");
    //   setPageMessage(false);
    //   setMessage("");
    //   setTimeout(() => {
    //     if (mandatory.length === 0 && unverified.length === 0) {
    //       finalFormSubmit(btnRtype, btntype, rcd, mnrecord, btn, ij, nam);
    //     } else {
    //       showMandatoryError(mandatory, unverified);
    //     }
    //   }, 100);
    // } else {
    //   setIsClick(false);
    //   setSubmitLoading(false);
    //   setShouldBlock(true);
    // }
  };

  export async function  updateRecord({changedRowAndCols,refList,list , setSubmitLoading,onSubmit 
    ,uiScript ,listName ,token ,loca,button ,listRcd ,setRefList}){
    let rcd = [];
   // let changedRowAndCols = this.state.changedRowAndCols;
   // let refList = this.state.refList;
   // let list = this.state.list;
   let tabName=listName.current
   console.log("tabName" ,tabName);
   console.log("changedRowAndCols",changedRowAndCols);
    let tabId=listRcd.formRecordList[1].table.id;
    let tablable=listRcd.formRecordList[1].table.label;

    let appId=listRcd.formRecordList[0].application.id;
    let appName=listRcd.formRecordList[0].application.value;
    let dupRefList=[...refList];
   
    for (const [row, cols] of changedRowAndCols) {
      //console.log(`Key: ${key}, Value:`, value);
      for (let k = 0; k < cols?.length; k++) {
        let col = cols[k];
        let unChanged = dupRefList[0].rcd[row].colr[col];
        let changed = list[0].rcd[row].colr[col];

        if (JSON.stringify(unChanged.value) !== JSON.stringify(changed.value)) {
         let rrr= [...list[0].rcd[row].colr];
         rrr.shift?.()
          let obj = {
            formRecord: [
              { application: { id: appId, value: appName } },
              { table: { id: tabId, name: tabName ,label:tablable} },
              { record: rrr },
              {button:button}
            ],
          };
          //rcd.push(JSON.stringify(obj));
          dupRefList[0].rcd[row].colr[col]=changed;
          rcd.push(obj);

          break;
        }
      }
    }


    console.log("rcd", rcd);

    let error_String = "";
    let isGreenSingal = true;
    for (let k = 0; k < rcd.length; k++) {
      let singleRow = rcd[k];
  
      let res = runScriptOnEachRecord(singleRow);
      console.log("runScriptOnEachRecord", res);

      if (res.mandatory.length > 0) {
        error_String +=
          " Check mandatory fields not set: " + res.mandatory.toString() +" of record "+res?.rcdId;
        break;
      }
      if (res.unverified.length > 0) {
        error_String += " Fields unverified:" + res.unverified  +" of record "+res?.rcdId;
        break;
      }
    }

    if (error_String) {
      isGreenSingal = false;
    }

  //  let token = localStorage.getItem("token");
//    console.log("token" ,token);
    
    if (rcd?.length!=0) {

      try {
        let res=  await  axios
        .post(loca + "/marketplace/update/multiplerecord", rcd, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application":appname
          },
        })

        console.log("ressss" ,res.data);
        console.log("dupRefList" ,dupRefList);
        
        setRefList(dupRefList);
      } catch (error) {
        
      }

    //     .then((resp) => {
    //       const refrencercd = resp.data;
    //       console.log("multipleUpdate: ",refrencercd);
    //    //   this.refresh();
    //   //  this.multipleSelectedRows.current=[];
    //   //  this.setState({
    //   //   loading: false,
    //   //   btn_disable: false, 
    //   // });
    //     });
    // } else {
    //   // this.setState({
    //   //   loading: false,
    //   //   btn_disable: false, 
    //   // });
    // }

    

  
    
    }

  }

  const runScriptOnEachRecord = (rcd, imggg) => {
    var mandatory = [];
    var unverified = [];
    let idFound=false;
    let isGreenSingal = true;
    let error_String=""
    let rcdId=-1;
    //rcd = JSON.parse(rcd);
    console.log("rcd ",rcd);
    rcd = rcd.formRecord[2].record;
    
    console.log("rcd 2",rcd);
    for (var i = 0; i < rcd.length; i++) {
      if (rcd[i].uivalid?.visible === "true" && rcd[i]?.type !== "boolean") {
        if (rcd[i].uivalid.mandatory === "true") {
          if (rcd[i].value === "") {
            mandatory.push(rcd[i].label.name);
          } else if (
            (rcd[i].type === "reference" || rcd[i].type === "depend_table") &&
            rcd[i].value.name === ""
          ) {
            mandatory.push(rcd[i].label.name);
          } else if (
            typeof rcd[i].value === "string" &&
            rcd[i].value.trim() === ""
          ) {
            mandatory.push(rcd[i].label.name);
          }
        }
        if (
          rcd[i].type === "String" ||
          rcd[i].type === "int" ||
          rcd[i].type === "email" ||
          rcd[i].type === "date"
        ) {
          var veri = rcd[i].verified;
          console.log("verif : " + veri);
          if (veri === "unverified") {
            unverified.push(rcd[i].label.name);
          }
        }
        if (
          rcd[i].type === "image_upload" &&
          rcd[i].value !== "" &&
          rcd[i].value.includes("blob")
        ) {
          // waitt = true;
        //  uploadImage(rcd[i], imggg, null);
          // imggg++;
        }
        if (rcd[i].type === "reference") {
          // var veri =refVerify(rcd[i].id,rcd[i].value);
          if (rcd[i].verified === "unverified") {
            unverified.push(rcd[i].label.name);
          }
        }

        if(rcd[i].name==="id" && !idFound){
          rcdId=rcd[i].value;
          idFound=true;
        }
      }
    }
    if (error_String) {
      isGreenSingal = false;

    }
    return {
   /*    onSubmitResponse: onSubmitResponse, */
      unverified: unverified,
      mandatory: mandatory,
      rcdId:rcdId
    };

    
  };


  const fcf_reference = (
{    index,
  value,
  refId,
  type,
  colName,
  object,
  verify,
  properties,
  depend_column,
  frecord ,location ,setRecord ,tableName,record ,loca,token}
  ) => {
   // console.log(refId, tableName.current, frecord[index]);
    if (frecord[index].type.includes("group_key_value")) {
      // if (value.properties) {
      //   keyValueJson.properties = [];
      //   let len = value.properties.length;
      //   for (let i = 0; i < len; i++) {
      //     keyValueJson.properties.push({
      //       name: "",
      //       value: "",
      //     });
      //   }
      //   setKeyValueJson(keyValueJson);
      // }
      // for (let i = 0; i < frecord.length; i++) {
      //   if (frecord[i].type === "key_value") {
      //     frecord[i].value = properties;
      //     break;
      //   }
      // }
      // console.log(frecord);
      // setRecord([...frecord]);
    }

    if (tableName?.current === "reference_filter") {
      // console.log("ref filt check");
      // if (colName === "form_column_id") {
      //   callColumnByColId(refId, "form_column_id");
      // }
      // if (colName === "form_table_id") {
      //   console.log(value);
      //   callTableColumn(value, false, depend_column, true);
      // }
    } else if (
      (colName === "form_table_id" ||
        colName === "u_form_table_id" ||
        colName === "u_teacher_ref" ||
        object === "form_table") &&
      tableName?.current !== "table_access" &&
      verify
    ) {
      // console.log("obbbbbbbb");
      // callTableColumn(value, false, depend_column);
  
    } else {
      if (tableName?.current === "report") {
        //callColumnByColId(refId);
      }
    }
    console.log(colName, refId);

    if (colName === "reference_id" && refId !== "0") {
   //   callRefFieldList(refId, true);
    }
    if (verify === false) {
      frecord[index].clicked = false;
    }
    if (value.length > 2) {
      if (verify === true) {
        if (frecord[index].name === colName) {
          console.log("iffffff", refId, index, frecord);
          frecord[index].value.name = value;
          frecord[index].clicked = true;
          frecord[index].value.id = refId;
          frecord[index].verified = "verified";
          frecord[index].rcd_info = "true";
          console.log(frecord);

        }
      } else {
        console.log("hjjhjhjhjjhj ");
        if (frecord[index].name === colName) {
          frecord[index].value.name = value;
          frecord[index].value.id = refId;
          frecord[index].rcd_info = "true";
          // console.log("checking2");
          // setState({ record: frecord });
          // getSecondaryFields(
          //   index,
          //   colName,
          //   type,
          //   refId,
          //   frecord[index]?.refTable?.value
          // );
          console.log("rcd_info", frecord);
        //  setRecord([...frecord]);
          if(location==="list")
            setRecord([...record])
            else
            setRecord([...frecord]);
        }

        var veri = '{"referenceRecord":[{"columnid":"' + refId + '"},';

        veri += '{"tabvalue":"' + value + '"}]}';
        var rff = [];
        axios
          .post(loca + `/marketplace/reference/record`, veri.toString(), {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
              "market-application":appname
            },
          })
          .then(
            (resp) => {
              const refrencercd = resp.data;
              console.log("ref : " + JSON.stringify(refrencercd));
              if ("Error" in refrencercd) {
              } else {
                rff = {
                  index: index,
                  record: refrencercd.referenceRecordList[2].records,
                };
                if (refrencercd.referenceRecordList[2].records.length > 0) {
                  frecord[index].verified = "";
                } else {
                  frecord[index].verified = "unverified";
                }
                frecord[index].value.name = value;
                frecord[index].value.id = refId;

            //    setShowContainer(true);
             //   setRefRecord({ ...rff });
               // setRecord([...frecord]);
               if(location==="list")
                setRecord([...record])
                else
                setRecord([...frecord]);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    } else {
      if (frecord[index].name === colName) {
        frecord[index].value.name = value;
        if (value === "") {
          frecord[index].value.id = "0";
        } else {
          frecord[index].value.id = refId;
        }
        frecord[index].verified = "";
        if(location==="list")
          setRecord([...record])
          else
          setRecord([...frecord]);
      //  setRecord([...frecord]);
       // setRefRecord({});
      }
    }
  };

  const getFieldValue = async (col_id,obj ,list) => {
 //   var rcd = record;
    let otb = list;
    var rcd = otb[0].rcd[obj.rowIndex].colr;
   // console.log(col_id, record);
    if (rcd !== "null" && rcd !== "") {
      for (var r = 0; r < rcd.length; r++) {
        if (rcd[r].id === col_id) {
          console.log(rcd[r].value);
          return rcd[r].value;
        }
      }
    }
  };
  
  export async function  setcolumn ({
    index,
    name,
    type,
    col,
    readonly,
    depCol,
    refTable ,record_rq ,setref_filter ,setColumnId ,setshowModelList,setObjRefTable,setObjName,setDepCol,setObjType,setObjIndex ,setCurObj,obj,list,mainFilt}
  )  {
    if (readonly === "false") {
      var rf = record_rq?.referenceQualifier;
      console.log(index, name, type, col, readonly, depCol, refTable);
      console.log("rff", rf, col,record_rq);

      // var ref_filt = ref_filter;
      let ref_filt = [];
      let ref_check = false;
      let filtt = [];
      console.log("rf", rf);

      for (var r = 0; r < rf.length; r++) {
        if (rf[r].formColumn.id === col && "filter" in rf[r]) {
          filtt = rf[r].filter.filter;
          console.log(filtt);
          for (var f = 0; f < filtt.length; f++) {
            if (
              filtt[f].mc === "is dependent" ||
              filtt[f].ct === "depend_table"
            ) {
              var addFilt = "";
              if (f > 0) {
                addFilt = filtt[f].af;
              }

              var col_name = filtt[f].rf.value;
              console.log("is depend");
              var co_label = filtt[f].rf.label;
              let ab = await getFieldValue(filtt[f].dc.id,obj,list);
              var filtt1 = JSON.parse(JSON.stringify(mainFilt));

              filtt1.co = col_name;
              filtt1.cl = co_label;
              filtt1.ct = filtt[f].ct;
              filtt1.mc = "is";
              filtt1.an = "";
              filtt1.af = addFilt;
              filtt1.rf = { id: ab?.id, value: ab?.name };
              filtt1.dc = { id: 0, value: "" };
              ref_filt.push(filtt1);
              console.log(filtt1);
            } else {
              ref_filt.push(filtt[f]);
            }
          }
        }
      }
      console.log("lopg rf " + JSON.stringify(rf));
      if (ref_filt.length === 0) {
        console.log("reffilt");
        const refarray = {
          filter: [
            {
              co: "",
              cl: "",
              mc: "",
              an: "",
              ct: "",
              af: "",
              rf: { id: "", value: "" },
              dc: { id: "", value: "" },
            },
          ],
        };
        setref_filter(refarray);
      } else {
        console.log(filtt);
        const refarray = {
          filter: ref_filt,
        };
        console.log("ref_filt", ref_filt);
        setref_filter(refarray);
      }

      //if(arr?.kry)
      // var a=new ArrayList<>()  

      setColumnId(col);
      setObjIndex(index);
      setObjType(type);
      setDepCol(depCol);
      setObjName(name);
      setObjRefTable(refTable);
      setshowModelList(true);
      setCurObj(obj)
      // setTimeout(() => {
      //   if (name === "field_id" || name === "form_column_id") {
      //     if (ref_filter.length > 0) {
      //       setReference();
      //     }
      //   }
      // }, 500);
    }
  };






