import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import axios from "axios";
import "../css/FormView.css";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import { MarketContext } from "../Context";

const MultipleAppReq = () => {
  const { token, loca, appCode } = useContext(MarketContext);

  const [loading, setLoading] = useState(false);
  // const [viewData, setViewData] = useState({});
  const [tabState, setTabState] = useState(true);
  const [col_mn, setCol_mn] = useState([]);
  const [columnarray2, setColumnarray2] = useState([""]);
  // const [type, setType] = useState("");
  // const [tablename, setTableName] = useState("");
  const [page_error, setPage_Error] = useState(false);
  const [error, setError] = useState("");
  const [flag, setFlag] = useState(false);
  const [viewList, setViewList] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [isColArrSelected, setIsColArrSelected] = useState(false);
  const [isColArr2Selected, setIsColArr2Selected] = useState(false);
  const [isRefFieldSelected, setisRefFieldSelected] = useState(false);
  const [allcol_mn, setAllcol_mn] = useState(new Map());
  const [refCols, setrefCols] = useState(new Map());
  const [selectedRefTab, setselectedRefTab] = useState("");
  const [srch, setSrch] = useState(true);

  const ind = useRef(0);
  const index = useRef(0);
  const loc = useLocation().state;

  useEffect(() => {
    console.log(loca);
    if (loca) {
      formChangeTable();
    } else {
    }
  }, []);

  const formChangeTable = () => {
    setIsColArrSelected(false);
    setIsColArr2Selected(false);
    setCol_mn([]);
    setColumnarray2([]);
    var userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);

    axios
      .post(
        loca + "/getAllApplicationList",
        { userName: userDetails.name },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then((resp) => {
        const coldata = resp.data;
        console.log(coldata);
        if (coldata !== "") {
          if ("Error" in coldata) {
            setLoading(false);
            setPage_Error(true);
            setError(coldata.Error);
          } else {
            let clmArr2 = coldata.filter((app) => app.check === true);

            console.log(clmArr2);
            setCol_mn(coldata);
            setColumnarray2(clmArr2);
            setFlag(true);
          }
        }
      });
  };

  const submitColumnbtn = () => {
    var colm = col_mn;
    console.log(colm);
    var colm2 = columnarray2;
    var sub = [];
    console.log(colm2);

    for (var i = 0; i < colm2.length; i++) {
      for (var j = 0; j < colm.length; j++) {
        if (colm2[i].name === colm[j].name) {
          sub.push(colm2[i].appCode);
          break;
        }
      }
    }

    let json = {};
    let userDetails = localStorage.getItem("userDetails"); //userDetails
    userDetails = JSON.parse(userDetails);
    json.username = [userDetails.name];
    json.secApps = sub;
    json.mainApp = appCode;
    console.log(json);
    axios
      .post(loca + "/multiapp/access", json, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        const coldata = resp.data;
        console.log(coldata);
        if (coldata !== "") {
          if ("Error" in coldata) {
            setLoading(false);
            setPage_Error(true);
            setError(coldata.Error);
          } else {
            formChangeTable();
          }
        }
      });
  };

  const selected = (index, clm) => {
    console.log(index);
    setIsColArrSelected(true);
    setIsColArr2Selected(false);
    console.log(col_mn, clm);

    setisRefFieldSelected(false);
    if (clm === "clm1") {
      for (let i = 0; i < col_mn.length; i++) {
        if (i === index) {
          document
            .getElementById("colm1")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("colm1")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < columnarray2.length; i++) {
        document
          .getElementById("colm2")
          .children.item(i)
          .classList.remove("selected-row");
      }
    } else {
      setIsColArrSelected(false);
      setIsColArr2Selected(true);
      ind.current = index;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === index) {
          document
            .getElementById("colm2")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("colm2")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < col_mn.length; i++) {
        document
          .getElementById("colm1")
          .children.item(i)
          .classList.remove("selected-row");
      }
    }
  };

  const setColumnOrder = (pm) => {
    var clm = [];
    var col_odr = ind.current;
    var col_odr2 = 0;
    console.log(col_odr);

    if (pm) {
      if (col_odr < columnarray2.length - 1) {
        col_odr2 = col_odr + 1;
        for (let i = 0; i < columnarray2.length; i++) {
          if (i !== col_odr2 && i !== col_odr) {
            clm.push(columnarray2[i]);
          } else if (i === col_odr2) {
            document
              .getElementById("colm2")
              .children.item(col_odr2)
              .classList.add("selected-row");
            console.log("col_odr2");
            let cjj = columnarray2[i];
            cjj.co = (col_odr + 1).toString();
            clm.push(cjj);
          } else if (i === col_odr) {
            document
              .getElementById("colm2")
              .children.item(col_odr)
              .classList.remove("selected-row");
            console.log("col_odr");
            let cjj = columnarray2[i];
            cjj.co = (col_odr2 + 1).toString();
            clm.push(cjj);
          }
        }
        clm.sort((a, b) =>
          parseInt(a.co) > parseInt(b.co)
            ? 1
            : parseInt(a.co) < parseInt(b.co)
            ? -1
            : 0
        );
        ind.current = col_odr2;
        console.log(clm);
        setColumnarray2([...clm]);
      }
    } else {
      if (col_odr > 0) {
        col_odr2 = col_odr - 1;
        for (let i = 0; i < columnarray2.length; i++) {
          if (i !== col_odr2 && i !== col_odr) {
            clm.push(columnarray2[i]);
          } else if (i === col_odr2) {
            document
              .getElementById("colm2")
              .children.item(col_odr2)
              .classList.add("selected-row");
            console.log("col_odr2");
            let cjj = columnarray2[i];
            cjj.co = (col_odr + 1).toString();
            clm.push(cjj);
          } else if (i === col_odr) {
            document
              .getElementById("colm2")
              .children.item(col_odr)
              .classList.remove("selected-row");
            console.log("col_odr");
            let cjj = columnarray2[i];
            cjj.co = (col_odr2 + 1).toString();
            clm.push(cjj);
          }
        }
        clm.sort((a, b) =>
          parseInt(a.co) > parseInt(b.co)
            ? 1
            : parseInt(a.co) < parseInt(b.co)
            ? -1
            : 0
        );
        ind.current = col_odr2;
        console.log(clm);
        setColumnarray2([...clm]);
      }
    }
  };

  const shiftRight = () => {
    let clm = col_mn;
    let clm2 = columnarray2;
    if (document.getElementsByClassName("selected-row").item(0)) {
      let lab = document
        .getElementsByClassName("selected-row")
        .item(0).innerText;
      console.log(index.current, clm);
      for (let i = 0; i < col_mn.length; i++) {
        if (i === index.current) {
          if (clm[i].type !== "ui") {
            console.log("inside");
            clm[i].check = true;
          }
          let n_co = clm2.length + 1;

          let n = {
            name: clm[i].name,
            co: n_co,
            appCode: clm[i].appCode,
            securityType: clm[i].securityType,
            label: clm[i].label,
          };
          clm2.push(n);
        }
      }
      console.log(clm2);
      setIsColArrSelected(false);
      setCol_mn([...clm]);
      setColumnarray2([...clm2]);
    }
  };

  const shiftLeft = () => {
    let clm = col_mn;
    console.log(col_mn);
    let clm2 = columnarray2;
    let name = "";
    let obj = {};
    if (document.getElementsByClassName("selected-row").item(0)) {
      // let lab = document.getElementsByClassName("selected-row").item(0).innerText;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === ind.current) {
          name = clm2[i].name;
          obj = clm2[i];
          clm2.splice(i, 1);
          document
            .getElementsByClassName("selected-row")
            .item(0)
            .classList.remove("selected-row");
        }
      }
      console.log(obj);

      console.log(allcol_mn.has(obj.key));
      for (let i = 0; i < col_mn.length; i++) {
        if (clm[i].name === name) {
          console.log(name);
          clm[i].check = false;
        }
      }
      console.log("before", clm);
      clm = col_mn;
      console.log("after", clm);
      setIsColArrSelected(false);
      setIsColArr2Selected(false);
      setCol_mn([...clm]);
      setColumnarray2([...clm2]);
    }
  };

  return (
    <div
      className="Card"
      style={
        isMobile
          ? { height: "93vh", overflow: "auto", flexGrow: 1 }
          : { height: "95vh", overflow: "auto", flexGrow: 1 }
      }
    >
      <div className="formViewBack">
        {flag && (
          <div className="row" style={{ marginTop: "2rem" }}>
            <div className="col-md-2"></div>
            <div className="col-md-8">
              {/* <div className="input-group">
                  <div className="form-outline" >
                    <input
                      type="search"
                      id="form1"
                      className="form-control"
                      value={searchVal}
                      onChange={(e) => {
                        
                        console.log(col_mn);
                        setSearchVal(e.target.value);
                        let ot=[];
                         ot = col_mn.map((obj) => {
                          if (obj.name.includes(searchVal)) {
                            obj.inSearch = true;
                          } else {
                            obj.inSearch = false;
                          }
                          return obj;
                        });
                        setCol_mn(ot);
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary mt-0"
                    data-mdb-ripple-init
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div> */}
              <div className="row fr  mb-2">
                <div
                  id=""
                  className="col-md-5" /* style={{ marginRight: "2.7rem" }} */
                >
                  <div className="search-container">
                    {srch && <i className="fa fa-search"></i>}
                    <input
                      type="search"
                      id="form1"
                      className="form-control"
                      value={searchVal}
                      onChange={(e) => {
                        console.log(col_mn);
                        setSearchVal(e.target.value);
                        let ot = [];
                        ot = col_mn.map((obj) => {
                          if (
                            obj.label
                              .toLowerCase?.()
                              .includes(searchVal.toLowerCase?.())
                          ) {
                            obj.inSearch = true;
                          } else {
                            obj.inSearch = false;
                          }
                          return obj;
                        });
                        setCol_mn(ot);
                      }}
                      onMouseOver={(e) => setSrch(false)}
                      onMouseOut={(e) => {
                        if (searchVal.length > 0) {
                          setSrch(false);
                        } else {
                          setSrch(true);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="up-down"></div>
                <div id="" className="col-md-4 "></div>
              </div>
              <div className="row fr">
                <div id="colm1" className="col-md-5 hiegtMan">
                  {console.log("col_mn", col_mn)}

                  {col_mn.length > 0 &&
                    searchVal &&
                    col_mn.map((obj, obj_i) => (
                      <div key={obj_i} className="columnarray2">
                        {!obj.check && obj.inSearch && (
                          <div className="row fr">
                            <div
                              className="col-md heading"
                              onClick={() => {
                                selected(obj_i, "clm1");
                                index.current = obj_i;
                              }}
                            >
                              <span className={"blackLab"}>{obj.label}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  {col_mn.length > 0 &&
                    !searchVal &&
                    col_mn.map((obj, obj_i) => (
                      <div key={obj_i} className="columnarray2">
                        {!obj.check && (
                          <div className="row fr">
                            <div
                              className="col-md heading"
                              onClick={() => {
                                selected(obj_i, "clm1");
                                index.current = obj_i;
                              }}
                            >
                              <span className={"blackLab"}>{obj.label}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                <div className="col-md-1 up-down-dis">
                  <div>
                    <button
                      className="view_btn"
                      disabled={isColArr2Selected}
                      onClick={() => {
                        shiftRight();
                      }}
                    >
                      {">"}
                    </button>
                  </div>
                  <div>
                    <button
                      className="view_btn"
                      disabled={isColArrSelected}
                      onClick={() => {
                        shiftLeft();
                      }}
                    >
                      {"<"}
                    </button>
                  </div>
                </div>
                <div id="colm2" className="col-md-5 hiegtMan">
                  {console.log("columnarray2", columnarray2)}
                  {columnarray2.map((obj, index) => (
                    <p
                      style={{ margin: 0 }}
                      key={index}
                      className="columnarray2 heading"
                      onClick={() => {
                        selected(index, "clm2");
                      }}
                      value={obj.name}
                    >
                      {obj.label}
                    </p>
                  ))}
                </div>
                <div className="col-md-1 up-down-dis">
                  <div>
                    <button
                      className="up-down-view"
                      onClick={(e) => setColumnOrder(false)}
                    >
                      {">"}
                    </button>
                  </div>
                  <div>
                    <button
                      className="up-down-view"
                      onClick={(e) => setColumnOrder(true)}
                    >
                      {"<"}
                    </button>
                  </div>
                </div>
              </div>
              <button
                style={{
                  fontSize: "15px",
                  // width: "20%",
                  borderRadius: "3px",
                  marginTop: "0.5vh",
                }}
                type="button"
                className=" btnnn btn btn-primary"
                onClick={() => submitColumnbtn()}
              >
                Submit
              </button>
            </div>
            <div className="col-md-2"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultipleAppReq;
