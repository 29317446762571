

const mayaRecordDefinition = `
/**
 * Represents a record for a table.
 * Provides methods for querying, updating, and managing data.
 */
declare class MayaRecord {
    /**
     * Creates a new MayaRecord instance.
     * @param tableName The name of the table to associate with this record.
     */
    constructor(tableName: string);

    /**
     * Creates a new MayaRecord instance.
     * 
     */
    constructor();

    /**
     * Moves to the next record.
     * @returns True if a next record exists, false otherwise.
     */
    next(): boolean;

    /**
     * Executes the current query.
     * @returns The result of the query as a string.
     */
    execute(): string;

    /**
     * Inserts a new record based on the provided object.
     * @param st The object to insert as a string.
     * @returns The result of the insert operation as a string.
     */
    InsertByObject(st: string): string;

    /**
     * Sets a blank value for a specified field.
     * @param field The field name.
     * @param newValue The new value to set.
     */
    setBlankValue(field: string, newValue: string): void;

    /**
     * Sets a value for a specified field of current iteration object.
     * for multi-select type value's id should be seprated by comma
     * @param field The field name.
     * @param newValue The new value to set.
     */
    setValue(field: string, newValue: string): void;

    /**
     * Executes the current update operation.
     */
    executeUpdate(): void;

    /**
     * Executes an update with specific parameters.
     * @param clmName The column name.
     * @param clmValue The column value.
     * @param id The identifier for the update.
     * @returns The result of the update as a string.
     */
    executeUpdate(clmName: string, clmValue: string, id: string): string;

    /**
     * Deletes the current record.
     * @returns The result of the delete operation as a string.
     */
    delete(): string;

    /**
     * Deletes all records associated with this instance.
     */
    deleteAll(): void;

    /**
     * Adds a query parameter.
     * @param field The field name.
     * @param value The field value.
     */
    addQuery(field: string, value: string): void;

    /**
     * Adds a query parameter with a specific condition.
     * @param field The field name.
     * @param sign The comparison operator like contains , > ,< ,>= ,=<.
     * @param value The field value.
     */
    addQuery(field: string, sign: string, value: string): void;

    /**
     * Adds an OR query parameter.
     * @param field The field name.
     * @param value The field value.
     */
    addOrQuery(field: string, value: string): void;

    /**
     * Adds an IN query parameter.
     * @param field The field name.
     * @param value The array of field values.
     */
    addInQuery(field: string, value: string[]): void;

    /**
     * Adds an OR IN query parameter.
     * @param field The field name.
     * @param value The array of field values.
     */
    addOrInQuery(field: string, value: string[]): void;

    /**
     * Sorts the results in descending order by the specified column.
     * @param column The column name.
     */
    descOrder(column: string): void;

    /**
     * Sorts the results in ascending order by the specified column.
     * @param column The column name.
     */
    ascOrder(column: string): void;

    /**
     * Limits the number of results.
     * @param limit The maximum number of results.
     */
    limit(limit: string): void;

    /**
     * Limits the number of results with pagination.
     * @param limit The maximum number of results.
     * @param page The page number.
     */
    limit(limit: object, page: object): void;

    /**
     * Logs the provided arguments.
     * @param args The arguments to log.
     */
    log(...args: any[]): void;

    /**
     * Inserts a new record.
     * @returns The result of the insert operation as a string.
     */
    insert(): string;

    /**
     * Checks if a column exists in the table.
     * @param field The field name.
     * @returns True if the column exists, false otherwise.
     */
    hasColumn(field: string): boolean;

    /**
     * Retrieves the value of a specified field of current iteration object.
     * @param field The field name.
     * @returns The value of the field.
     */
    getValue(field: string): any;

    /**
     * Retrieves the  current iteration object.
     * @returns The record object.
     */
    getRecord(): any;

    /**
     * Generates a filter string for a query.
     * @param field The field name.
     * @param sign The comparison operator.
     * @param value The field value.
     * @returns The filter query string.
     */
    getFilter(field: string, sign: string, value: string): string;

    /**
     * Retrieves the table name associated with this instance.
     * @returns The table name.
     */
    getTableName(): string;

    /**
     * Retrieves the current query string.
     * @returns The query string.
     */
    getQeuery(): string;
}








`;

// documentation for RestMessage
const restMessageDefinition = `
/**
 * Represents a REST message for API interactions.
 */
declare class RestMessage {

    /**
     * Creates a new RestMessage instance.
     * @param ExternalRestApi The name of the ExternalRestApi .
     * @param http-method The name of http method .
     */
    constructor(ExternalRestApi: string , http-method:string);

    /**
     * Creates a new RestMessage instance.
     * 
     */
    constructor();   
    
    /**
     * Sets a parameter for the REST message.
     * @param key The parameter key.
     * @param value The parameter value.
     * @returns The result as a string.
     */
    setParametrs(key: string, value: string): string;

    /**
     * Sets a header for the REST message.
     * @param key The header key.
     * @param value The header value.
     * @returns The result as a string.
     */
    setHeaders(key: string, value: string): string;

    /**
     * Sets a value for a placeholder in the endpoint URL.
     * @param placeHolder The placeholder key.
     * @param value The value to set.
     * @returns The result as a string.
     */
    setendPointValue(placeHolder: string, value: string): string;

    /**
     * Sets a value for a placeholder in the body.
     * @param placeHolder The placeholder key.
     * @param value The value to set.
     * @returns The result as a string.
     */
    setbodyValue(placeHolder: string, value: string): string;

    /**
     * Logs the provided arguments.
     * @param args The arguments to log.
     */
    log(...args: object[]): void;

    /**
     * Executes the REST message.
     * @returns The result of the execution as a string.
     */
    execute(): string;
}
`;

const currentDefinition = `
/**
 * Global object 'current' for accessing the current record.
 */
declare const current: {
    /**
     * Retrieves the value of the specified column in the current record.
     * @param columnName The name of the column to retrieve the value from.
     * @returns The value of the specified column as an object.
     */
    get(columnName: string): object;

        /**
     * Retrieves the value of the specified column in the current record.
     * @param current json record.
     * 
     */
    setCurrentRecord(json:string):void;
};
`;

const myDefinition = `
/**
 * Global object 'my' for accessing user and record-related information.
 */
declare const my: {
    /**
     * Retrieves the user ID of the current user.
     * @returns The user ID as a string.
     */
    getUserId(): string;

    /**
     * Retrieves the username of the current user.
     * @returns The username as a string.
     */
    getUserName(): string;

    /**
     * Retrieves the record ID of the current record.
     * @returns The current record ID as a string.
     */
    getCurrentRecordId(): string;

    /**
     * Retrieves the current record as an object.
     * @returns The current record as an object.
     */
    getCurrentRcd(): any;

    getLocalUser(): LocalUser;

    getApplication():Application

     /**
     * set update json
     *
     */
    setUpdateJSON():void;
};
`;

const apFormDefination=`
/**
 * Global object 'ap_form' for modifying the current record.
 */
declare const ap_form: {
    /**
     * Retrieves the value of the specified field in the current record.
     * @param name The name of the field.
     * @returns The value of the specified field.
     */
    getFieldValue(name: string): any;

    /**
     * Sets the value of a specific field in the current record of refrence and depend table type.
     * @param name The name of the field.
     * @param value The new value to set.
     * @param id (Optional) The record ID for which the value should be set.
     */
    setFieldValue(name: string, value: any, id?: string): void;

    /**
     * Sets the value of a specific field in the current record.
     * @param name The name of the field.
     * @param value The new value to set.
     * @param id (Optional) The record ID for which the value should be set.
     */
    setFieldValue(name: string, value: any): void;

    /**
     * Sets the label of a specific field.
     * @param name The name of the field.
     * @param label The new label to set.
     */
    setFieldLabel(name: string, label: string): void;

    /**
     * Retrieves the type of a specific field.
     * @param name The name of the field.
     * @returns The type of the field as a string.
     */
    getFieldType(name: string): string;

    /**
     * Sets the type of a specific field.
     * @param name The name of the field.
     * @param type The new type to set.
     */
    setFieldType(name: string, type: string): void;

    /**
     * Sets whether a specific field is mandatory.
     * @param name The name of the field.
     * @param value True to make the field mandatory, false otherwise.
     */
    setMandatory(name: string, value: boolean): void;

    /**
     * Checks whether a specific field is mandatory.
     * @param name The name of the field.
     * @returns True if the field is mandatory, otherwise false.
     */
    isMandatory(name: string): boolean;

    /**
     * Sets whether a specific field is read-only.
     * @param name The name of the field.
     * @param value True to make the field read-only, false otherwise.
     */
    setReadOnly(name: string, value: boolean): void;

    /**
     * Sets the visibility of a specific field.
     * @param name The name of the field.
     * @param value True to make the field visible, false otherwise.
     */
    setVisible(name: string, value: boolean): void;

    /**
     * Applies a filter to a field based on a given value and operator.
     * @param value The value to filter by.
     * @param field The field to apply the filter on.
     * @param op The operator to use for filtering (e.g., '=', '>', '<').
     */
    setFilter(value: any, field: string, op: string): void;

    /**
     * Adds an option to a dropdown/select field.
     * @param value The value of the option.
     * @param label The label to display for the option.
     * @param name The name of the field to which the option should be added.
     */
    addOption(value: string, label: string, name: string): void;

    /**
     * Removes an option from a dropdown/select field.
     * @param label The label of the option to remove.
     * @param name The name of the field from which the option should be removed.
     */
    removeOption(label: string, name: string): void;

    /**
     * Removes all options from a dropdown/select field.
     * @param name The name of the field to clear options from.
     */
    removeAllOption(name: string): void;

    /**
     * Sets the visibility of a relation list.
     * @param name The name of the relation list.
     * @param visible True to make the relation list visible, false otherwise.
     */
    setRelationListVisible(name: string, visible: boolean): void;
};
`;

const requestDefinition = `
/**
 * Global object 'request' for accessing HTTP request-related information.
 */
declare const request: {
    /**
     * Retrieves the full URL of the current request.
     * @returns The request URL as a string.
     */
    getRequestUrl(): string;

    /**
     * Retrieves the body of a POST request.
     * @returns The request body as a string.
     */
    getPostBody(): string;

    /**
     * Retrieves a path variable from the request by key.
     * @param key The key of the path variable.
     * @returns The corresponding path variable value as a string.
     */
    getPathVariable(key: string): string;

    /**
     * Retrieves a query parameter from the request by key.
     * @param key The key of the query parameter.
     * @returns The corresponding query parameter value as a string.
     */
    getQueryParam(key: string): string;
};
`;

const responseDefinition = `
/**
 * Global object 'response' for handling HTTP responses.
 */
declare const response: {
    /**
     * Retrieves the full response object.
     * @returns The response object.
     */
    getResponse(): object;

    /**
     * Adds a key-value pair as a string to the response.
     * @param key The key for the string value.
     * @param value The string value to be added.
     */
    addString(key: string, value: string): void;

    /**
     * Puts a string value directly into the response.
     * @param value The string value to be added.
     */
    putString(value: string): void;

    /**
     * Adds a key-value pair where the value is an object.
     * @param key The key for the object.
     * @param obj The object to be added.
     */
    addObject(key: string, obj: object): void;

    /**
     * Puts an object directly into the response.
     * @param obj The object to be added.
     */
    putObject(obj: String): void;

    /**
     * Adds a key-value pair where the value is an array.
     * @param key The key for the array.
     * @param arr The array to be added.
     */
    addArray(key: string, arr: any[]): void;
};
`;
const resultDefinition = `
/**
 * Global object 'response' for handling HTTP responses.
 */
declare const result: {
    /**
     * Retrieves the full response object.
     * @returns The response object.
     */
    getResponse(): object;

    /**
     * Adds a key-value pair as a string to the response.
     * @param key The key for the string value.
     * @param value The string value to be added.
     */
    addString(key: string, value: string): void;

    /**
     * Puts a string value directly into the response.
     * @param value The string value to be added.
     */
    putString(value: string): void;

    /**
     * Adds a key-value pair where the value is an object.
     * @param key The key for the object.
     * @param obj The object to be added.
     */
    addObject(key: string, obj: object): void;

    /**
     * Puts an object directly into the response.
     * @param obj The object to be added.
     */
    putObject(obj: object): void;

    /**
     * Adds a key-value pair where the value is an array.
     * @param key The key for the array.
     * @param arr The array to be added.
     */
    addArray(key: string, arr: any[]): void;
};
`;
const mayaAggregateDefinition = `
/**
 * Class 'MayaAggregate' for handling database queries with aggregation and filtering.
 */
declare class MayaAggregate {
    /**
     * Creates an instance of MayaAggregate for a specific table.
     * @param tableName The name of the table to perform aggregation on.
     */
    constructor(tableName: string);

    /**
     * Adds a query condition with a field, comparison operator, and value.
     * @param field The field name to apply the condition on.
     * @param sign The comparison operator (e.g., '=', '>', '<', etc.).
     * @param value The value to compare the field against.
     */
    addQuery(field: string, sign: string, value: string): void;

    /**
     * Adds an aggregate function (e.g., SUM, COUNT, AVG).
     * @param aggregate The aggregate function to apply.
     */
    addAggregate(aggregate: string): void;

    /**
     * Groups the query results by a specific field.
     * @param field The field to group by.
     */
    groupBy(field: string): void;

    /**
     * Checks if the table contains a specific column.
     * @param field The column name to check.
     * @returns True if the column exists, otherwise false.
     */
    hasColumn(field: string): boolean;

    /**
     * Executes the query with the specified conditions and aggregations.
     */
    query(): void;

    /**
     * Moves to the next row in the result set.
     * @returns True if there is another row, otherwise false.
     */
    next(): boolean;

    /**
     * Retrieves the aggregated value for a given key.
     * @param key The aggregation key to retrieve the value for.
     * @returns The aggregated value as a string.
     */
    aggregate(key: string): string;

    /**
     * Retrieves the value of the current row.
     * @returns The value of the current row as a string.
     */
    getValue(): string;
};
`;

const mayaAjaxDefinition=`/**
 * Class 'MayaAjax' for handling AJAX requests.
 */
declare class MayaAjax {

    /**
     * Creates a new MayaAjax instance.
     * @param Resusable  script name .
     */
    constructor(ScriptName: string);
    /**
     * Adds a parameter to the AJAX request.
     * @param key The name of the parameter.
     * @param value The value of the parameter.
     */
    addParam(key: string, value: any): void;

    /**
     * Sends the AJAX request asynchronously.
     * @param callBack A callback function that is executed when the request completes.
     */
    callAsync(callBack: (response: any) => void): void;

    /**
     * Sends the AJAX request synchronously.
     * @returns The response of the request.
     */
    callSync(): any;
}`






// export function onEditorMount  (editor, monaco) {
//     monaco.languages.typescript.javascriptDefaults.addExtraLib(mayaRecordDefinition);
//     monaco.languages.typescript.javascriptDefaults.addExtraLib(restMessageDefinition);
//     monaco.languages.typescript.javascriptDefaults.addExtraLib(currentDefinition);
//     monaco.languages.typescript.javascriptDefaults.addExtraLib(myDefinition);
//     monaco.languages.typescript.javascriptDefaults.addExtraLib(apFormDefination);
//     monaco.languages.typescript.javascriptDefaults.addExtraLib(requestDefinition);
//     monaco.languages.typescript.javascriptDefaults.addExtraLib(responseDefinition);
//     monaco.languages.typescript.javascriptDefaults.addExtraLib(mayaAggregateDefinition);

//     monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
//       target: monaco.languages.typescript.ScriptTarget.ESNext,
//       allowNonTsExtensions: true,
//     });
  
//   };
export function onEditorMount(editor, monaco) {
    // Define global object names
    const globalObjects = [
        "current",
        "my",
        "ap_form",
        "request",
        "response",
        "result"
    ];

    monaco.languages.typescript.javascriptDefaults.addExtraLib(mayaRecordDefinition);
    monaco.languages.typescript.javascriptDefaults.addExtraLib(restMessageDefinition);
    monaco.languages.typescript.javascriptDefaults.addExtraLib(currentDefinition);
    monaco.languages.typescript.javascriptDefaults.addExtraLib(myDefinition);
    monaco.languages.typescript.javascriptDefaults.addExtraLib(apFormDefination);
    monaco.languages.typescript.javascriptDefaults.addExtraLib(requestDefinition);
    monaco.languages.typescript.javascriptDefaults.addExtraLib(responseDefinition);
    monaco.languages.typescript.javascriptDefaults.addExtraLib(mayaAggregateDefinition);
    monaco.languages.typescript.javascriptDefaults.addExtraLib(resultDefinition);
    monaco.languages.typescript.javascriptDefaults.addExtraLib(mayaAjaxDefinition);


    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ESNext,
        allowNonTsExtensions: true,
    });


    monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: false,
        noSyntaxValidation: false,
        diagnosticCodesToIgnore: []
    });

    monaco.languages.registerCodeActionProvider('javascript', {
        provideCodeActions: function(model, range) {
            const codeActions = [];
            const code = model.getValue();

   
            const variableDeclarationRegex = /\b(?:var|let|const)\s+(\w+)/g;
            let match;

            while ((match = variableDeclarationRegex.exec(code)) !== null) {
                const variableName = match[1];

       
                if (globalObjects.includes(variableName)) {
                    codeActions.push({
                        title: `Error: '${variableName}' is a reserved global object.`,
                        diagnostics: [
                            {
                                message: `You cannot use '${variableName}' as a variable name because it is a reserved global object.`,
                                severity: monaco.MarkerSeverity.Error,
                                startLineNumber: model.getPositionAt(match.index).lineNumber,
                                startColumn: model.getPositionAt(match.index).column,
                                endLineNumber: model.getPositionAt(match.index + variableName.length).lineNumber,
                                endColumn: model.getPositionAt(match.index + variableName.length).column,
                            },
                        ],
                        kind: "quickfix",
                    });
                }
            }

            return {
                actions: codeActions,
                dispose: () => {},
            };
        },
    });
}

