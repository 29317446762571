import React from "react";
import Skeleton from "react-loading-skeleton";

export default function LeftNavLoading() {
  return (
    <div>
      <div className="list-group-item appm">
        <Skeleton width={100} height={20} />
      </div>
      {Array(20)
        .fill(0)
        .map((obj, i) => (
          <div>
            <div>
              <div className="sec">
                <Skeleton height={20} />
              </div>
              <div>
                <div>
                  <div className="list-group-item list-group-item-action navpointer ">
                    <Skeleton height={20} />
                  </div>

                  <div
                    className="list-group-item list-group-item-action navpointer"
                    style={{ justifyContent: "space-between" }}
                  >
                    <Skeleton height={20} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
