import React, { Component } from "react";

export class ApForm extends Component {
  state = {
    record: [],
    sr: null,
    tabRelation: {},
    setTabRelation: null,
  };

  constructor(record, setRecord, tabRelation, setTabRelation) {
    super(record);
    this.state.record = record;
    this.state.sr = setRecord;
    this.state.tabRelation = tabRelation;
    this.state.setTabRelation = setTabRelation;
  }

  getFieldValue(name) {
    const frecord = this.state.record;
    // console.log("nnnnnn " + JSON.stringify(frecord), name);
    for (let i = 0; i < frecord.length; i++) {
      // console.log(
      //   "lllllllllllllllllllllllllll : " + JSON.stringify(frecord[i].value),
      //   name
      // );
      if (frecord[i].name === name) {
        return frecord[i].value;
      }
    }
  }

  setFieldValue(name, value, id) {
    const frecord = this.state.record;
    // console.log(frecord, name, value, id);
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].type === "reference") {
        if (frecord[i].name === name) {
          // console.log("chekkkkkk111111333 " + id, name);
          frecord[i].value.name = value;
          if (!id) {
            // console.log("idddd");
            frecord[i].value.id = "0";
          } else {
            frecord[i].value.id = id;
          }
          console.log(frecord[i], value);
          this.state.sr([...frecord]);
        }
      } else if (frecord[i].type === "dynamic_key_value") {
        // console.log(frecord[i]);
        if (frecord[i].name === name) {
          console.log(value);
          frecord[i].value = JSON.parse(value);
          // console.log(frecord);
          this.state.sr([...frecord]);
        }
      } else {
        if (frecord[i].name === name) {
          frecord[i].value = value;
          this.state.sr([...frecord]);
        }
      }
    }
  }

  setFieldLabel(name, label) {
    const frecord = this.state.record;
    // console.log(frecord, name, value, id);
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name) {
        frecord[i].label.name = label;
        this.state.sr([...frecord]);
      }
    }
  }

  getFieldType(name) {
    const frecord = this.state.record;
    // console.log("nnnnnn " + JSON.stringify(frecord));
    for (let i = 0; i < frecord.length; i++) {
      if (frecord[i].name === name) {
        // console.log("lllllllllllllllllllllllllll : " + JSON.stringify(frecord[i].value));
        return frecord[i].type;
      }
    }
  }

  setFieldType(name, type) {
    const frecord = this.state.record;
    // console.log(frecord, name, value, id);
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }

      if (type === "multi_select" || type === "reference") {
        break;
      }
      if (frecord[i].name === name) {
        frecord[i].type = type;
        console.log(frecord[i].value);
        if (type === "dynamic_key_value") {
          console.log("tech");
          let str = frecord[i].value;
          let startChar = str.charAt(0);
          let endChar = str.charAt(str.length - 1);
          if (startChar === "[" && endChar === "]") {
            frecord[i].value = JSON.parse(frecord[i].value);
          } else {
            frecord[i].value = [{}];
          }
        }

        console.log(frecord[i]);

        this.state.sr([...frecord]);
      }
    }
  }

  setMandatory(name, value) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name) {
        frecord[i].uivalid.mandatory = value;
        // for choice type which do not have none in choice array, mandatory should be false for that field
        if (frecord[i]?.type === "choice") {
          if (
            frecord[i]?.choice[0]?.name !== "none" &&
            frecord[i]?.choice[0]?.name !== ""
          ) {
            frecord[i].uivalid.mandatory = "false";
          }
        }
        this.state.sr([...frecord]);
      }
    }
  }

  isMandatory(name) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name) {
        let val = frecord[i].uivalid.mandatory;
        if (val === "true") {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  setReadOnly(name, value) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name) {
        frecord[i].uivalid.read_only = value;
        this.state.sr([...frecord]);
      }
    }
  }

  setVisible(name, value) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].formView.in_view === "true") {
        if (frecord[i].name === name) {
          frecord[i].uivalid.visible = value;
          let type = frecord[i].type;
          if (value === "false") {
            if (type === "filter") {
              this.setFieldValue(name, {
                filter: [
                  {
                    co: "",
                    cl: "",
                    mc: "",
                    an: "",
                    ct: "",
                    af: "",
                    rf: { id: "", value: "" },
                    dc: { id: "", value: "" },
                    ch: [],
                  },
                ],
                timeline: "",
              });
            } else if (type === "depend_table") {
              this.setFieldValue(name, { name: "", id: "0" });
            } else if (type === "multi_select") {
              this.setFieldValue(name, { record: [] });
            } else if (type === "boolean") {
              this.setFieldValue(name, "false");
            } else {
              this.setFieldValue(name, "");
            }
          }
          this.state.sr([...frecord]);
        }
      }
    }
  }

  setFilter(value, field, op) {
    // console.log("checkkkkkkkkkkkkkkkkkk!1!!!!"+field);
    const frecord = this.state.record;
    let filt = "";
    for (let i = 0; i < frecord.length; i++) {
      // console.log("name"+frecord[i].name);
      if (frecord[i].name === field) {
        let type = frecord[i].type;
        let label = frecord[i].label;

        filt =
          '{"co": "' +
          field +
          '", "cl": "' +
          label +
          '", "mc": "' +
          op +
          '","ct": "' +
          type +
          '","af": ""';
        if (type === "reference") {
          filt +=
            ',"an": ""' +
            ',"rf": { "id": "' +
            value.id +
            '", "value": "' +
            value.name +
            '" }}';
        } else {
          filt += ',"an": "' + value + ',"rf": { "id": "", "value": "" }}';
        }
        // console.log("filt : " + filt);
        break;
      }
    }
    return JSON.parse(filt);
  }

  addOption(value, label, name) {
    // console.log(name)
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name && frecord[i].type === "choice") {
        frecord[i].choice.push({ name: value, label: label });
        break;
      }
    }
  }

  removeOption(label, name) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name && frecord[i].type === "choice") {
        for (let j = 0; j < frecord[i].choice.length; j++) {
          if (frecord[i].choice[j].label === label) {
            frecord[i].choice.splice(j, 1);
            break;
          }
        }
        break;
      }
    }
  }

  removeAllOption(name) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name && frecord[i].type === "choice") {
        frecord[i].choice = [];
        break;
      }
    }
  }

  setRelationListVisible(name, visible) {
    const frecord = this.state.tabRelation;
    console.log(frecord);
    if (frecord !== "" && "relation" in frecord) {
      for (let i = 0; i < frecord.relation.length; i++) {
        if (
          name === "uni_id" ||
          name === "id" ||
          name === "created" ||
          name === "created_by" ||
          name === "updated" ||
          name === "updated_by"
        ) {
          break;
        }
        let tabName = frecord.relation[i].formRecordList[1].table.name;

        if (tabName === name) {
          frecord.relation[i].visible = visible;
        }
      }
      // console.log(frecord);
      this.state.setTabRelation({ ...frecord });
    }
  }

  render() {
    return <div>ApForm</div>;
  }
}

export default ApForm;
