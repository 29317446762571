import React, {
    Component,
    useState,
    useContext,
    useEffect,
    useRef,
  } from "react";
  import axios from "axios";
  import WorkInProgress from "./WorkInProgress";
  import "../css/FormView.css";
  import { isMobile } from "react-device-detect";
  import { toast } from "react-toastify";
  import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
  import Header from "./Header";
import { MarketContext } from "../Context";
  
  const ImportMapping = () => {
    const { token, loca, user } = useContext(MarketContext);
  
    const navigation = useNavigate();
  
    const [tab_mn, setTab_mn] = useState([{}]);
    const [loading, setLoading] = useState(false);
    const [tabname, setTabname] = useState(false);
    const [typename, setTypename] = useState(false);
    const [colState, setcolState] = useState(false);
    // const [viewData, setViewData] = useState({});
    const [tabState, setTabState] = useState(true);
    const [col_mn, setCol_mn] = useState([]);
    const [columnarray2, setColumnarray2] = useState([""]);
    const [col_mnB, setCol_mnB] = useState([]);
    const [columnarray2B, setColumnarray2B] = useState([""]);
    const [sourceLabel, setSourceLabel] = useState([""]);
    const [targetLabel, settargetLabel] = useState([""]);
    const [tarnsformId, setTarnsformId] = useState([""]);
    
    

    // const [type, setType] = useState("");
    // const [tablename, setTableName] = useState("");
    const [page_error, setPage_Error] = useState(false);
    const [error, setError] = useState("");
    const [warning, setWarning] = useState(false);
    const [json, setJson] = useState([]);
    const [flag, setFlag] = useState(false);
    const [viewList, setViewList] = useState([]);
    const [mncolor, setMncolor] = useState(false);
    const [disableColArr, setDisableColArr] = useState(false);
    const [disableColArr2, setDisableColArr2] = useState(false);
    const [disableColArrB, setDisableColArrB] = useState(false);
    const [disableColArrB2, setDisableColArrB2] = useState(false);
    const [isRefFieldSelected, setisRefFieldSelected] = useState(false);
    const [allcol_mn, setAllcol_mn] = useState(new Map());
    const [refCols, setrefCols] = useState(new Map());
    const [isClick, setIsClick] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selectedRefTab, setselectedRefTab] = useState("");
    const [searchParam, setSearchParam] = useSearchParams();
  
    let parentApp = useRef("");
    const ind = useRef(-1);
    const indexA = useRef(-1);
    const indexB = useRef(-1);
    const index = useRef(-1);
    const loc = useLocation().state;
  
    useEffect(() => {
      console.log(loc);
      ind.current = -1;
      // if (loc) {
      //   // setTabname(loc.tabName);
      //   parentApp.current = "Autoportal";
      //   setTabState(true);
      //   formChangeTable();
      //   //getFormViewRecord();
      //   formChangeType()
      // } else {
      //   parentApp.current = "Autoportal";
      //  // getFormViewRecord();
      //  formChangeTable();
     
      // }
      let uni_id= searchParam.get("rid") ;
      setTarnsformId(uni_id)
      console.log(" uni_id ",uni_id);
      
      getInitialRecord(uni_id)
    }, []);



  
    const getFormViewRecord = () => {
      setLoading(true);
      setTabState(false);
      setWarning(false);
      axios
        .get(loca + `/marketplace/get/tables`, {
          headers: {
            authorization: "Bearer " + token,
            "market-application": "marketplace",
          },
        })
        .then((resp) => {
          const formdata = resp.data;
          if (formdata !== "") {
            if ("Error" in formdata) {
              setLoading(false);
              setPage_Error(true);
              setError(formdata.Error);
            } else {
              setLoading(false);
              setTabState(true);
              formdata.tableRecords.sort((a, b) => {
                return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
              });
              setTab_mn(formdata.tableRecords);
              setFlag(false);
            }
          }
        });
    };
    const getInitialRecord=(id)=>{
        axios.get(loca+"/marketplace/get/field-map/"+id ,{
          headers: {
            authorization: "Bearer " + token,
          },
        }).then((res)=>{
           let data=res.data
           console.log(data);
           
           setCol_mn([...data.sourceColumns]);
           setColumnarray2([...data.mapSourceColumns])
           setCol_mnB([...data.targetColumns])
           setColumnarray2B([...data.mapTargetColumns])
           setSourceLabel(data.sourceLabel)
           settargetLabel(data.targetLabel)
           setFlag(true)
        })
    }
  
    const formChangeTable = (val, dontFalse, key) => {
  
      console.log("dontFalse", dontFalse, val);
      if (!dontFalse) {
        setMncolor(false);
        setFlag(false);
      }
  
      setDisableColArr(false);
      setDisableColArr2(false);
      setDisableColArrB(false);
      setDisableColArrB2(false);
      val="form_table"
      if (val !== "" && val !== "none") {
        setcolState(false);
  
        setCol_mn([]);
        if (!dontFalse) {
          setTabname(val);
          setColumnarray2([]);
          setViewList([]);
          const newMap2 = new Map(allcol_mn);
          newMap2.clear()
          setAllcol_mn(newMap2);
          const newMap3 = new Map(refCols)
          newMap3.clear();
          setrefCols(newMap3)
  
        }
       // document.getElementById("one")?.value = "none";
        axios
          .get(loca + "/marketplace/get/columnview/" + val, {
            headers: {
              authorization: "Bearer " + token,
            },
          })
          .then((resp) => {
            const coldata = resp.data;
            console.log(coldata);
            if (coldata !== "") {
              if ("Error" in coldata) {
                setLoading(false);
                setPage_Error(true);
                setError(coldata.Error);
              } else {
                setcolState(true);
                let arr = [...coldata.colView[0].columnRecords];
                setCol_mn([...coldata.colView[0].columnRecords]);
           //     setCol_mnB(JSON.parse(JSON.stringify([...coldata.colView[0].columnRecords])))
               setCol_mnB([])
                formChangeType( "xbd",[...coldata.colView[0].columnRecords])
                if (!dontFalse) {
                  setViewList([...coldata.colView[1].viewRecords]);
                }
  
  
                if (coldata.colView[1].viewRecords.length === 0) {
                  setWarning(true);
                } else {
                  setWarning(true);
                }
  
                setMncolor(true);
              }
            }
          });
      } else {
        setTabname(val);
        setViewList([]);
        setWarning(false);
      }
    };
    const callReferenceCol = () => {
  
      console.log("fafd", selectedRefTab);
      setisRefFieldSelected(false)
      if (selectedRefTab) {
  
        let key2 = "";
        let st = "";
        const newMap = new Map(refCols);
        console.log("1", refCols, newMap.has(selectedRefTab.refTbName));
        //    if (!newMap.has(selectedRefTab.refTbName)) {
  
        if (newMap.size == 0) {
          const key1 = selectedRefTab.tabName;
          newMap.set(key1, {
            "one": selectedRefTab.tabLabel, "two": st + selectedRefTab.tabLabel + " fields",
            path: selectedRefTab.tabName, tabPath: selectedRefTab.tabName
          });
        }
        console.log("size", newMap.size);
        /*        if (newMap.size == 1) {
                 st += ". "
               } else
                 if (newMap.size == 2) {
                   st += ". . "
                 }
                 else
                 if (newMap.size == 3) {
                   st += ". . . "
                 }
                 else
                 if (newMap.size == 4) {
                   st += ". . . ."
                 } */
  
        let lastKey;
        // let path = "";
        st = "";
  
        for (let [kk, value] of newMap) {
          lastKey = kk
          st += ". "
  
        }
  
        key2 = lastKey;
        let leb = selectedRefTab.label;
        leb = leb.substring(0, leb.length - 3);
        key2 += leb;
        // newMap.set(key2, { "one": selectedRefTab.refTbLabel, "two": st + selectedRefTab.refTbLabel + " fields", path: selectedRefTab.refTbName });
        //}
  
        newMap.set(key2, {
          "one": leb, "two": st + leb + " fields",
          path: selectedRefTab.name, tabPath: selectedRefTab.refTbName
        });
        console.log(key2);
        console.log(newMap);
        formChangeTable(selectedRefTab.refTbName, true, key2)
        setrefCols(newMap)
  
  
      }
    }
  
    const setColumn = (prefArray, cmm) => {
      let clm = col_mn;
      console.log("col_mn" ,JSON.parse(JSON.stringify(col_mn)));
      
        clm = cmm;
  
      let clm2 = [];
      let co = 1;
      console.log(clm);
      console.log(prefArray);
  
      for (var i = 0; i < clm.length; i++) {
        clm[i].check = false;
      }
      for (let i = 0; i < prefArray.length; i++) {
        for (let j = 0, cnt = 0; j < clm.length; j++) {
  
          if (clm[j].name !== "--split--" && clm[j].name !== "--split-start--" && clm[j].name !== "--split-end--" && clm[j].name === prefArray[i].name ) {
  
            clm[j].check = true;
            let jj = {
              label: clm[j].label, name: clm[j].name, co: co, tabName: prefArray[i].tabName,
              //key: prefArray[i].key, path: prefArray[i].path,
               type: prefArray[i].type, colId: prefArray[i].colId,
             // tabPath: prefArray[i].tabPath,
            }
            clm2.push(jj);
            co++;
            break;
          } 
          cnt++;
        }
      }
      setCol_mn([...clm]);
      setColumnarray2([...clm2]);
      // setCol_mn([]);
      // setColumnarray2([]);
      setCol_mnB(JSON.parse(JSON.stringify([...clm])))
      setColumnarray2B(JSON.parse(JSON.stringify([...clm2])))
      setFlag(true);
    };
  
  
  
    const formChangeType = (val ,clm) => {
      setDisableColArr(false);
      setDisableColArr2(false);
      setDisableColArrB(false);
      setDisableColArrB2(false);
      console.log(val);
      console.log("hello");
      if (val !== "" && val !== "none") {
     //   let obj = viewList.find((obj, ind) => obj.id === val).type;
  
      //  setTypename(obj);
        if (tabname !== "" && tabname !== "none") {
          axios
            .get(loca + "/marketplace/get/view/" + "form_table/ebc84abe-47dc-42b0-8b73-6b375383ee7e", {
              headers: {
                authorization: "Bearer " + token,
              },
            })
            .then((resp) => {
              const data = resp.data;
              console.log(data);
              if (data !== "") {
                if ("Error" in data) {
                  setLoading(false);
                  setPage_Error(true);
                  setError(data.Error);
                } else {
                  setJson(data);
                 // let clm = changeIntoOriginalColmn(tabname)  // target key will be maintableName
                 // console.log("clm" );
                  
                  if (clm) {
                    setColumn(data.formView[2].column, clm);
                  } else
                    setColumn(data.formView[2].column,clm);
  
                }
              }
            });
        }
      } else {
        setFlag(false);
      }
    };
    const submitColumnbtn = () => {
      setIsClick(true);

     //  var colm = changeIntoOriginalColmn(tabname);
    let obj={sourceColumns:col_mn,
      targetColumns:col_mnB,
      mapSourceColumns:columnarray2,
      mapTargetColumns:columnarray2B,
      tarnsformId:tarnsformId
    }

      console.log(obj);
      axios
          .post(loca + "/marketplace/set/field-map", obj, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          }).then((res)=>{
            console.log("res" ,res.data);
            let record=res.data;

            console.log(record);
            toast(record.message, {
              position: "top-center",
              theme: "colored",
              type: "success",
            });
            navigation({
              pathname: user + "/form",
              search: createSearchParams({
                rty: "record",
                tableName: "transform_map",
                rid:tarnsformId
              }).toString(),
            });
            
          })
    };
  

  

    const selected = (index, clm ,section) => {
      console.log(index);
     // section=""
     if(section=="A"){
      setDisableColArr(true);
      setDisableColArr2(false);
     }else{
      setDisableColArrB(true);
      setDisableColArrB2(false);
     }
      
      console.log(col_mn, clm);
      let _col_mn=[]
      let _columnarray2=[]
      console.log("section" ,section);
      let oppCol_mn=[];
      let oppColumnarray2=[];
      let oppSection=""
      
      if(section==="A"){
        _col_mn=col_mn;
        _columnarray2=columnarray2
        oppCol_mn=col_mnB;
        oppColumnarray2=columnarray2B;
        oppSection="B"

      }else{
        console.log("in b");
        _col_mn=col_mnB;
        _columnarray2=columnarray2B
        oppCol_mn=col_mn;
        oppColumnarray2=columnarray2
        oppSection="A"
      }

      if (clm === "clm1") {
        for (let i = 0; i < _col_mn.length; i++) {
          if (i === index) {
            document
              .getElementById("colm"+section+"1")
              .children.item(i)
              .classList.add("selected-row"+section);
          } else {
            document
              .getElementById("colm"+section+"1")
              .children.item(i)
              .classList.remove("selected-row"+section);
          }
        }
        for (let i = 0; i < _columnarray2.length; i++) {
          document
            .getElementById("colm"+section+"2")
            .children.item(i)
            .classList.remove("selected-row"+section);
        }
        /* removing selected row from section */
         
      } else {
        // if (_columnarray2.length == 1) {
        //   if(section=="A")
        //   setDisableColArr(true);
        //   else
        //   setDisableColArrB(true);
        // } else {
          if(section=="A")
          setDisableColArr(false);
          else
          setDisableColArrB(false);
    //    }

        if(section=="A")
        setDisableColArr2(true);
        else
        setDisableColArrB2(true);
        ind.current = index;
        for (let i = 0; i < _columnarray2.length; i++) {
          if (i === index) {
            document
              .getElementById("colm"+section+"2")
              .children.item(i)
              .classList.add("selected-row"+section);
          } else {
            document
              .getElementById("colm"+section+"2")
              .children.item(i)
              .classList.remove("selected-row"+section);
          }
        }
        for (let i = 0; i < _col_mn.length; i++) {
          document
            .getElementById("colm"+section+"1")
            .children.item(i)
            .classList.remove("selected-row"+section);
        }
      }
      for (let i = 0; i < oppCol_mn.length; i++) {
        document
          .getElementById("colm"+oppSection+"1")
          .children.item(i)
          .classList.remove("selected-row"+oppSection);
      }

      for (let i = 0; i < oppColumnarray2.length; i++) {
        document
          .getElementById("colm"+oppSection+"2")
          .children.item(i)
          .classList.remove("selected-row"+oppSection);
      }


      if(section==="A"){
        setCol_mn(_col_mn);
        setColumnarray2(_columnarray2);
      }else{
       // _col_mn=col_mnB;
      //  __columnarray2=columnarray2B
        setCol_mnB(_col_mn);
        setColumnarray2B(_columnarray2);
      }
    };
  
  
    const shiftRight = (section) => {
      let clm ;
      let clm2 ;
      let cc=""
      console.log("sec" ,section);
      
      if(section==="A"){
        console.log("in A");
         clm = col_mn;
         clm2 = columnarray2;
         cc="selected-rowA"
         index.current=indexA.current
      }else{
        console.log("in B");
        clm = col_mnB;
        clm2 = columnarray2B;
         cc="selected-rowB";
         index.current=indexB.current
      }
    
      if (document.getElementsByClassName(cc).item(0)) {
        let lab = document
          .getElementsByClassName(cc)
          .item(0).innerText;
        console.log(col_mn);
        console.log(clm);
        console.log(columnarray2);
        console.log(clm2);
        console.log(index.current);
        for (let i = 0; i < clm.length; i++) {
          if (i === index.current) {
            if (clm[i].type !== "ui") {
              console.log("inside");
              clm[i].check = true;
            }
            let n_co = clm2.length + 1;
            let n = {
               name: clm[i].name, 
               label: clm[i].label, 
               co: n_co,
               id:clm[i].id,
              type: clm[i].type,
        
                };
            clm2.push(n);
          }
        }
        ind.current = -1
        console.log(clm);
    
        if(section==="A"){
          setCol_mn(clm);
          setColumnarray2(clm2);
          setDisableColArr(true);
          setDisableColArr2(true);
        }else{
         // _col_mn=col_mnB;
        //  __columnarray2=columnarray2B
          setCol_mnB(clm);
          setColumnarray2B(clm2);
          setDisableColArrB(true);
          setDisableColArrB2(true);
        }
      }else{
        console.log("in else");
        
      }

    };
  
    const shiftLeft = (section) => {
     let clm = [];
      console.log(col_mn);
     let clm2 = [];
     let cc=""
      let name = "";
      let obj = {};
      let removedIndex = -1
      if(section==="A"){
        clm = col_mn;
        clm2 = columnarray2;
        cc="selected-rowA"
     }else{
      console.log("bbbbbbbbbbbbbb");
      
       clm = col_mnB;
       clm2 = columnarray2B;
        cc="selected-rowB";
     }
      if (document.getElementsByClassName(cc).item(0)) {
        // let lab = document.getElementsByClassName("selected-row").item(0).innerText;
        for (let i = 0; i < clm2.length; i++) {
          if (i === ind.current) {
            name = clm2[i].name;
            obj = clm2[i];
            removedIndex = i;
  
          }
          if (i > ind.current) {
            console.log(clm2[i].co);
            let newCO = Number(clm2[i].co) - 1
            clm2[i].co = newCO.toString()
          }
  
        }

  
        if (removedIndex != -1) {
          clm2.splice(removedIndex, 1);
          document
            .getElementsByClassName(cc)
            .item(0)
            .classList.remove(cc);
        }
  
        for (let i = 0; i < clm.length; i++) {
          if (
           /*  clm[i].key === obj.key && */
            clm[i].name === name &&
            !(
              clm[i].label === "--split--" ||
              clm[i].label === "--split-end--" ||
              clm[i].label === "--split-start--"
            )
          ) {
            console.log(name);
            clm[i].check = false;
          }
        }
        ind.current = -1
        if(section==="A"){
          setCol_mn(clm);
          setColumnarray2(clm2);
          setDisableColArr(true);
          setDisableColArr2(true);
        }else{
         // _col_mn=col_mnB;
        //  __columnarray2=columnarray2B
          setCol_mnB(clm);
          setColumnarray2B(clm2);
          setDisableColArrB(true);
          setDisableColArrB2(true);
        }
      }
    };
  
    return (
      <div
        className="Card"
        style={
          isMobile
            ? { height: "93vh", overflow: "auto", flexGrow: 1 }
            : { height: "95vh", overflow: "auto", flexGrow: 1 }
        }
      >
        <div style={{ position: "sticky", top: "0", zIndex: "1" }}>
          <Header tabName={"Import Field Mapping"}
            isClick={isClick}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </div>
        <div className="formViewBack" aria-readonly={getFormViewRecord}>
          {loading === true ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ height: "95vh" }}
            >
              <WorkInProgress />
            </div>
          ) : (
            <div>
              {tabState === true && (
                <div
                  className="row fr"
                  style={{
                    // width: "1002px",
                    marginLeft: "0rem",
                  }}
                >
        
                </div>
              )}
            </div>
          )}
          {console.log("flg" ,flag)
          }
          {flag && (
            <div>
              <div className="row" style={{ marginTop: "2rem"   }}>
                <div className="col-md-1"></div>
                 
                 {/* SectionAA */}
                <div className="col-md-5" style={{paddingRight:"0"}}>
                   <h6><b>Source :{sourceLabel}</b></h6>
                  <div className="row fr" style={{height:"100%"}}>
     
                    <div id="colmA1" className={`col-md-5  ${columnarray2.length > 15 || "dan"  ? "box-scl" : ""}`} >
                      {col_mn.length > 0 &&
                        col_mn.sort((a, b) => (a.label > b.label ? 1 : -1)).map((obj, obj_i) => (
                          <div key={obj_i}>
                            {!obj.check && (
                              <div className="row fr">
                                <div
                                  className="col-md heading"
                                  onClick={() => {
                                    selected(obj_i, "clm1","A");
                                    indexA.current = obj_i;
                                  }}
                                >
                                  <span className={obj?.type === "reference" ? "greenLab" : "blackLab"}>{obj.label}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                    <div className="col-md-2 up-down-dis">
          
                      <div>
                        <button
                          className="view_btn"
                          disabled={disableColArr2}
                          onClick={() => {
                            shiftRight("A");
                          }}
                        >
                          {">"}
                        </button>
                      </div>
                      <div>
                        <button
                          className="view_btn"
                          disabled={disableColArr}
                          onClick={() => {
                            shiftLeft("A");
                          }}
                        >
                          {"<"}
                        </button>
                      </div>
                    </div>
                    <div id="colmA2" className={`col-md-5 ${columnarray2.length > 15  || "dan" ? "box-scl" : ""}`} >
                      {columnarray2.map((obj, index) => (
                        <p
                        /*   style={{ margin: 0 }} */
                          key={index}
                          className="columnarray2 heading"
                          style={{
                            borderBottom:
                            obj !== columnarray2.length - 1 &&
                              "1px solid #8c8c8c",
                           margin:0}}
                          onClick={() => {
                            selected(index, "clm2","A");
                          }}
                          value={obj.label}
                        >
                          {obj.label}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
                {/* SectionBB */}
                <div className="col-md-5 " style={{paddingLeft:"0"}}>
                <h6 style={{textAlign:"end"}} ><b>Target : {targetLabel}</b></h6>
                  <div className="row fr"  style={{height:"100%"}} >
                  <div id="colmB2" className={`col-md-5 ${columnarray2B.length > 15|| "dan" ? "box-scl" : ""}`} >
                  
                  {columnarray2B.map((obj, index) => (
                    <p
                     /*  style={{ margin: 0 }} */
                      key={index}
                      className="columnarray2 heading"
                      onClick={() => {
                        selected(index, "clm2","B");
                      }}
                      style={{
                        borderBottom:
                        obj !== columnarray2B.length - 1 &&
                          "1px solid #8c8c8c",
                       margin:0}}
                      value={obj.label}
                    >
                      {obj.label}
                    </p>
                  ))}
                </div>
      
                    <div className="col-md-2 up-down-dis">
                      <div>
                        <button
                          className="view_btn"
                        //    disabled={disableColArrB2}
                        disabled={disableColArrB}
                          onClick={() => {
                          //  shiftRight();
                          shiftLeft("B");
                          }}
                        >
                          {">"}
                        </button>
                      </div>
                      <div>
                        <button
                          className="view_btn"
                          //disabled={disableColArrB}
                          disabled={disableColArrB2}
                          onClick={() => {
                           // shiftLeft();
                           shiftRight("B")
                          }}
                        >
                          {"<"}
                        </button>
                      </div>
                    </div>
         
                    <div id="colmB1" className={`col-md-5 ${columnarray2B.length > 15 || "dan"? "box-scl" : ""}`} >
                      {col_mnB.length > 0 &&
                        col_mnB.sort((a, b) => (a.label > b.label ? 1 : -1)).map((obj, obj_i) => (
                          <div key={obj_i}>
                            {!obj.check && (
                              <div className="row fr">
                                <div
                                  className="col-md heading"
                                  onClick={() => {
                                    selected(obj_i, "clm1","B");
                                    indexB.current = obj_i;
                                  }}
                                >
                                  <span className={obj?.type === "reference" ? "greenLab" : "blackLab"}>{obj.label}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
           
                  </div>
               </div>
                <div className="col-md-1"></div>
              </div>
              <div className="row my-4">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <div className="row" style={{marginTop:"10px"}}>
                    <div className="col-md-4"></div>
                    <div className="col-md-3 cen" style={{ textAlign: "center" }}>
                      <button
                        style={{
                          fontSize: "15px",
                          // width: "20%",
                          borderRadius: "3px",
                        }}
                        type="button"
                        className=" btnnn btn btn-primary"
                        onClick={() => submitColumnbtn()}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          )}
        </div>
      </div >
    );
  };
  
  export default ImportMapping;