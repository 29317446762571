import axios from "axios";
import React from "react";
import { useState, useRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MarketContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import Header from "../small_compo/Header";
import { toast } from "react-toastify";

const RoleSelectionComponent = () => {
  // useContext hooks
  const { token, loca, appname } = useContext(MarketContext);
  const navigation = useNavigate();
  const [columnarray, setColumnarray] = useState([]);
  const [columnarray2, setColumnarray2] = useState([]);
  const [rcd, setRcd] = useState([]);
  const [loading, setLoading] = useState(true);
  const [srch, setSrch] = useState(true);
  const [srchVal, setSrchVal] = useState("");
  const [isProcessing, setProcessing] = useState(false);
  const [isSet, setIsSet] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isColArrSelected, setIsColArrSelected] = useState(false);
  const [isColArr2Selected, setIsColArr2Selected] = useState(false);
  const [parentRole, setParentRole] = useState();
  const [searchParam, setSearchParam] = useSearchParams();

  // useRef hook
  let parentApp = useRef("");
  let i_d = useRef("");
  let rt = useRef("");
  const firstRender = useRef(true);
  const ind = useRef(0);
  const index = useRef(0);
  const oneRef = useRef(false);

  const getInitialDetails = (col_mn) => {
    let json = { id: i_d.current };
    let url = "";
    if (rt.current === "user_role") {
      url = loca + "/marketplace/get/role";
    } else if (rt.current === "group_role") {
      url = loca + "/marketplace/get/group/role";
    } else if (rt.current === "group_user") {
      url = loca + "/marketplace/get/group/user";
    }
    console.log("rt : " + rt.current);
    axios
      .post(url, json, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application":appname
        },
      })
      .then(
        (resp) => {
          let roleList = resp.data;
          setColumn(roleList.roleList, col_mn);
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const getDetailsList = () => {
    let name = "";
    let url = "";
    let header = "";
    if (rt.current === "group_user") {
      name = "local_user";
    } else {
      name = "role";
    }
    var tablere = '{"formRecordList":[';
 
      url = loca + "/marketplace/get/multiple/allrecord";
      tablere += '{"application":{"id":"2","name":"marketplace"}}';
      tablere += ',{"table":{"id":"","name":"' + name + '"}}';
      header = {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
        "market-application":appname
      };

    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    tablere +=
      ',{"filter": [{"co": "","cl": "","mc": "", "an": "","ct": "","af": "","rf":{"id":"","value":""}}]}';
    tablere += ',{"timeLine":"' + "" + '"}]}';
    console.log(url);
    axios
      .post(url, tablere.toString(), {
        headers: header,
      })
      .then(
        (resp) => {
          let roleList = resp.data;
          let recordList = roleList.formRecordList[2].records;
          if ("Error" in roleList) {
          } else {
            let name = [];
            let id = [];
            for (let i = 0; i < recordList.length; i++) {
              for (let j = 0; j < recordList[i].record.length; j++) {
                if (j == 0 && recordList[i].record[j].name === "id") {
                  id.push(recordList[i].record[j].value);
                }
                if (recordList[i].record[j].name === "name") {
                  name.push(recordList[i].record[j].value);
                  break;
                }
              }
            }
            let col_mn = [];
            for (let i = 0; i < name.length; i++) {
                col_mn.push({
                  label: name[i],
                  name: name[i],
                  id: id[i],
                  check: false,
                });
            }
            setColumnarray([...col_mn]);
            setRcd([...col_mn]);
            getInitialDetails(col_mn);
            setLoading(false);
          }
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const changeCheckBox = (e, index) => {
    var col = columnarray;
    col[index].check = e;
    setColumnarray([...col]);
    setRcd([...col]);
  };

  const setColumn = (prf, col_mn) => {
    var clm = col_mn;
    var clm2 = [];
    for (var ii = 0; ii < clm.length; ii++) {
      clm[ii].check = false;
    }
    for (var i = 0; i < clm.length; i++) {
      for (var p = 0; p < prf.length; p++) {
        if (clm[i].id === prf[p].id) {
          clm[i].check = true;
          clm2.push({ label: clm[i].label, name: clm[i].label, id: clm[i].id });
        }
      }
    }

    setColumnarray([...clm]);
    setColumnarray2([...clm2]);
  };

  const submitColumnbtn = () => {
    setIsClick(true);
    setIsSet(false);
    if (rt.current === "group_user") {
      setProcessing(true);
      let url = "";
      let header = "";
      let json = { users: [], id: i_d.current };
      for (let i = 0; i < columnarray2.length; i++) {
        json.users.push(columnarray2[i]);
      }
        url = loca + "/marketplace/set/group/user";
        header = {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application":appname
        };
      axios
        .post(url, json, {
          headers: header,
        })
        .then(
          (resp) => {
            let result = resp.data;
            setProcessing(false);
          },
          (error) => {
            navigation("/error");
            setProcessing(false);
            toast(error, { position: "top-center", type: "error" });
          }
        )
        .finally(() => {
          setIsClick(false);
        });
    } else {
      let json = { roles: [], id: i_d.current };
      for (let i = 0; i < columnarray2.length; i++) {
        json.roles.push(columnarray2[i]);
      }
      setIsClick(true);
      let url = "";
      let header = "";
        if (rt.current === "user_role") {
          url = loca + "/marketplace/set/role";
        } else if (rt.current === "group_role") {
          url = loca + "/marketplace/set/group/role";
        }
        header = {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application":appname
        };
      axios
        .post(url, json, {
          headers: header,
        })
        .then(
          (resp) => {
            let result = resp.data;
            toast.success("Successfully  Inserted");
            setProcessing(false);
          },
          (error) => {
            navigation("/error");
            setProcessing(false);
            toast(error, { position: "top-center", type: "error" });
          }
        )
        .finally(() => {
          console.log("finally");
          setIsClick(false);
        });
    }
  };

  //   useEffect hooks
  useEffect(() => {
    setIsSet(false);
    i_d.current = searchParam.get("rid");
    rt.current = searchParam.get("tableName");
    getDetailsList();
  }, [searchParam, refresh]);

  const refreshPage = () => {
    setLoading(true);
    getDetailsList();
  };

  const selected = (index, clm) => {
    console.log(index, clm);
    if (clm === "clm1") {
      setIsColArrSelected(true);
      setIsColArr2Selected(false);
      for (let i = 0; i < columnarray.length; i++) {
        if (i === index) {
          document
            .getElementById("clm1")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("clm1")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < columnarray2.length; i++) {
        document
          .getElementById("clm2")
          .children.item(i)
          .classList.remove("selected-row");
      }
    } else {
      setIsColArrSelected(false);
      setIsColArr2Selected(true);
      ind.current = index;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === index) {
          document
            .getElementById("clm2")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("clm2")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < columnarray.length; i++) {
        document
          .getElementById("clm1")
          .children.item(i)
          .classList.remove("selected-row");
      }
    }
  };

  const setColumnOrder = (pm) => {
    var clm = [];
    var col_odr = ind.current;
    var col_odr2 = 0;
    console.log(col_odr);

    if (pm) {
      if (col_odr < columnarray2.length - 1) {
        col_odr2 = col_odr + 1;
        for (let i = 0; i < columnarray2.length; i++) {
          if (i !== col_odr2 && i !== col_odr) {
            clm.push(columnarray2[i]);
          } else if (i === col_odr2) {
            document
              .getElementById("clm2")
              .children.item(col_odr2)
              .classList.add("selected-row");
            console.log("col_odr2");
            let cjj = columnarray2[i];
            cjj.co = (col_odr + 1).toString();
            clm.push(cjj);
          } else if (i === col_odr) {
            document
              .getElementById("clm2")
              .children.item(col_odr)
              .classList.remove("selected-row");
            console.log("col_odr");
            let cjj = columnarray2[i];
            cjj.co = (col_odr2 + 1).toString();
            clm.push(cjj);
          }
        }
        clm.sort((a, b) => (a.co > b.co ? 1 : a.co < b.co ? -1 : 0));
        ind.current = col_odr2;
        console.log(clm);
        setColumnarray2([...clm]);
      }
    } else {
      if (col_odr > 0) {
        col_odr2 = col_odr - 1;
        for (let i = 0; i < columnarray2.length; i++) {
          if (i !== col_odr2 && i !== col_odr) {
            clm.push(columnarray2[i]);
          } else if (i === col_odr2) {
            document
              .getElementById("clm2")
              .children.item(col_odr2)
              .classList.add("selected-row");
            console.log("col_odr2");
            let cjj = columnarray2[i];
            cjj.co = (col_odr + 1).toString();
            clm.push(cjj);
          } else if (i === col_odr) {
            document
              .getElementById("clm2")
              .children.item(col_odr)
              .classList.remove("selected-row");
            console.log("col_odr");
            let cjj = columnarray2[i];
            cjj.co = (col_odr2 + 1).toString();
            clm.push(cjj);
          }
        }
        clm.sort((a, b) => (a.co > b.co ? 1 : a.co < b.co ? -1 : 0));
        ind.current = col_odr2;
        console.log(clm);
        setColumnarray2([...clm]);
      }
    }
  };

  const shiftRight = () => {
    let clm = columnarray;
    let clm2 = columnarray2;
    if (document.getElementsByClassName("selected-row").item(0)) {
      let lab = document
        .getElementsByClassName("selected-row")
        .item(0).innerText;
      console.log(lab);
      for (let i = 0; i < columnarray.length; i++) {
        // console.log(clm[i].label, lab, clm[i].type);
        if (i === index.current) {
          console.log("checkkkkkkk");
          document
            .getElementsByClassName("selected-row")
            .item(0)
            .classList.remove("selected-row");
          if (clm[i].type !== "ui") {
            clm[i].check = true;
          }
          // let n_co = clm2.length + 1;
          let n = { label: lab, name: clm[i].name, id: clm[i].id };
          clm2.push(n);
        }
      }
      console.log(clm);
      console.log(clm2);
      setIsColArrSelected(false);
      console.log(isColArrSelected);
      setColumnarray([...clm]);
      // setRcd([...clm]);
      setColumnarray2([...clm2]);
      setIsSet(true);
    }
  };

  const shiftLeft = () => {
    let clm = columnarray;
    let clm2 = columnarray2;
    let name = "";
    if (document.getElementsByClassName("selected-row").item(0)) {
      console.log(
        "shift left : " +
          document.getElementsByClassName("selected-row").item(0)
      );
      let lab = document
        .getElementsByClassName("selected-row")
        .item(0).innerText;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === ind.current) {
          name = clm2[i].name;
          clm2.splice(i, 1);
          document
            .getElementsByClassName("selected-row")
            .item(0)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < columnarray.length; i++) {
        if (clm[i].name === name) {
          clm[i].check = false;
        }
      }
      console.log(clm);
      setIsColArr2Selected(false);
      console.log(isColArr2Selected);
      setColumnarray([...clm]);
      // setRcd([...clm]);
      setColumnarray2([...clm2]);
      setIsSet(true);
    }
  };

  const modSearch = (nam) => {
    if (nam.length > 0) {
      setSrch(false);
      setSrchVal(nam);
    } else {
      setSrchVal("");
    }
    if (nam.length > 2) {
      var abc = [];
      for (let i = 0; i < columnarray.length; i++) {
        var role = columnarray[i].name.toLowerCase();
        if (role.includes(nam.toLowerCase())) {
          console.log(columnarray[i]);
          abc.push(columnarray[i]);
        }
      }
      setColumnarray([...abc]);
    } else {
      console.log(rcd);
      setColumnarray([...rcd]);
    }
  };
  if (!loading) {
    return (
      <div id={"form-role-selection"} className="pagesetup">
        <Header tabName={"Role Selection"}
          isClick={isClick}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        <div
          style={{ flexGrow: 1 }}
          className="container justifyContentCenter align-items-center my-5 "
        >
          {parentRole &&
            <div className="row fr justify-content-center">
              <div id="" className="col-md-4" style={{ marginRight: "2.7rem" }}>
                <div className="search-container">
                  {srch && <i className="fa fa-search"></i>}
                  <input
                    style={{ marginTop: "1.6rem" }}
                    className="form-control mr-sm-2 "
                    type="search"
                    aria-label="Search"
                    onChange={(e) => modSearch(e.target.value)}
                    onMouseOver={(e) => setSrch(false)}
                    onMouseOut={(e) => {
                      if (srchVal.length > 0) {
                        setSrch(false);
                      } else {
                        setSrch(true);
                      }
                    }}
                  ></input>
                </div>
              </div>
              <div className="up-down"></div>
              <div id="" className="col-md-4 ">
                <div style={{ fontSize: "larger" }}>Contains Roles List</div>
                <p style={{ marginBottom: "0", paddingBlockStart: "10px", paddingBlockEnd: "15px" }}>Parent Role is {parentRole}</p>
              </div>
            </div>
          }
          <div className="row fr justify-content-center">
            <div id="clm1" className="col-md-4 box-pref">
              {columnarray.length > 0 &&
                columnarray.map((obj, obj_i) => (
                  <div key={obj_i}>
                    {!obj.check && (
                      <div className="row fr">
                        <div
                          className="col-md"
                          onClick={() => {
                            selected(obj_i, "clm1");
                            index.current = obj_i
                          }}
                        >
                          {obj.label}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
              <div className="up-down">
                <div>
                  <button
                    className="ryt-left-btn"
                    disabled={isColArr2Selected}
                    
                    onClick={() => {
                      shiftRight();
                    }}
                  >
                    {">"}
                  </button>
                </div>
                <div>
                  <button
                    className="ryt-left-btn"
                    disabled={isColArrSelected}
                    
                    onClick={() => {
                      shiftLeft();
                    }}
                  >
                    {"<"}
                  </button>
                </div>
              </div>
            <div id="clm2" className="col-md-4 box-pref">
              {columnarray2.map((obj, index) => (
                <p
                  style={{ margin: 0 }}
                  key={index}
                  className="columnarray2"
                  onClick={() => {
                    selected(index, "clm2");
                  }}
                  value={obj.label}
                >
                  {obj.label}
                </p>
              ))}
            </div>
          </div>
          <div className="row my-3  " style={{ justifyContent: "center" }}>
            <div className="col-md-3" style={{ textAlign: "center" }}>
              <button
                type="button"
                style={{ borderRadius: "5px" }}
                className=" btn btn-primary"
                disabled={!isSet}
                onClick={() => {
                  submitColumnbtn();
                }}
              >
                {isProcessing ? (
                  <span>
                    <span
                      className="spinner-grow spinner-grow-sm me-2"
                      role="status"
                      
                    ></span>
                    Loading...
                  </span>
                ) : (
                  "submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="compo-loader">
        <WorkInProgress />
      </div>
    );
  }
};

export default RoleSelectionComponent;
