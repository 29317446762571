import axios from "axios";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { MarketContext } from "../Context";
import { Modal, Button } from "react-bootstrap";
import React, { useState, useContext, useEffect, useRef } from "react";
import NewFilterCompo from "./NewFilterCompo";
import { isMobile } from "react-device-detect";
import PrefCompo from "./PrefCompo";
import WorkInProgress from "./WorkInProgress";
import FormInnerCompo from "./FormInnerCompo";
import {
  formChangeFunction,
  validationFunction,
  /*   callbtnFunction, */
  updateRecord,
  setcolumn,
} from "../HelperClasses/MultiUpdateService.js";
import { toast } from "react-toastify";

const RelationList = ({
  recordList,
  tableName,
  i_d,
  all_ref_cols,
  rd,
  prevTab
}) => {
  const { token, loca, imageloca, user, userDetails, appname, formHeight } = useContext(MarketContext);
  const navigation = useNavigate();
  const counter = useRef(0);
  const [searchParam, setSearchParam] = useSearchParams();
  const [btnNamee, setbtnNamee] = useState("");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [col_mn, setCol_mn] = useState([]);
  const [list, setlist] = useState([]);
  const [showlist, setShowList] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [button, setButton] = useState([]);
  const [mainrecord, setMainRecord] = useState({});
  const [sortColumn, setSortColumn] = useState({ name: "", sort: false });
  const [columnId, setColumnId] = useState();
  const [prefCall, setPrefCall] = useState([]);
  const [filtarray, setFiltArray] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const [modal, setModal] = useState(false);
  const [filString, setfilString] = useState("");
  const [filt, setFilt] = useState({
    co: "",
    cl: "",
    mc: "",
    an: "",
    ct: "",
    af: "",
    rf: { id: "", value: "" },
  });
  const [timeline, setTimeline] = useState("");
  const [pageClicked, setPageClicked] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageRecords, setPageRecords] = useState("");
  const [recordCount, setRecordCount] = useState();
  const [listName, setListName] = useState(tableName);
  const [showPref, setShowPref] = useState(false);
  const [columnList, setColumnList] = useState([]);
  const [srch, setSrch] = useState("");
  const [srch_column, setSrch_column] = useState("name");
  const [tableLabel, setTableLabel] = useState("");
  const [curObj, setCurObj] = useState()
  const [refList, setRefList] = useState([]);
  const [objIndex, setObjIndex] = useState();
  const [showModelList, setshowModelList] = useState();
  const [multiSelectCol, setMultiSelectCol] = useState(-1);
  const [multiSelectrow, setMultiSelectRow] = useState(-1);
  const [objName, setObjName] = useState();
  const [depCol, setDepCol] = useState();
  const [ref_filter, setref_filter] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartCell, setDragStartCell] = useState(null);
  const [record_rq, setrecord_rq] = useState();
  const [error, setError] = useState("");
  const [objType, setObjType] = useState();
  const [tablabel, setTabLabel] = useState("")
  const [objRefTable, setObjRefTable] = useState();
  const [listRcd, setListRcd] = useState([]);
  const [filter, setFilter] = useState();
  const [tabId, setTabId] = useState("");
  const [mainFilt, setMainFilt] = useState({
    co: "",
    cl: "",
    mc: "",
    an: "",
    ct: "",
    af: "",
    rf: { id: "", value: "" },
    dc: { id: "", value: "" },
    ch: [],
  });
  let notAllowedForUpdate = useRef([
    "filter",
    "filter_ref",
    "other_table_filter",
    "filter_script",
    "json",
    "JSONObject",
    "group_key_value",
    "html",
    "script",
    "multi_select", "table_insert", "table_lookup", "image_upload", "label", "dynamic_key_value", "password", "long_description", "note", "condition", "activity", "link"
  ]);

  let listOfTypes = useRef([
    "filter",
    "filter_ref",
    "json",
    "group_key_value",
    "html",
    "reference",
    "multi_select",
    "depend_table",
  ]);
  let rowsScriptHasBeenRun = useRef([]);
  let isSub = useRef(false);
  let multipleSelectedRows = useRef(new Map());
  let changedRowAndCols = useRef(new Map());
  let lstName = useRef(tableName);
  let mySelect = useRef([]);
  let listRef = useRef([]);
  let dataLoadForUpdate = useRef(false);
  let executedFilter = useRef([]);

  //................ Functions ............//

  useEffect(() => {
    if (counter.current === 0) {
      setListRecord(recordList);
      setTableLabel(recordList.formRecordList[1].table.label);
      counter.current = 1;
    }
  }, [searchParam]);



  const handleShow = () => {
    setShowPref(true);
  };

  const handleClose = () => {
    setShowPref(false);
  };

  const handleCloseRefresh = () => {
    handleClose();
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"marketplace"}}';
    tablere += ',{"table":{"id":"","name":"' + listName + '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"created","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filtarray) + "}";
    tablere += ',{"timeLine":"' + timeline + '"}]}';
    setList(tablere);
  };

  const leftReadOnly = () => {
    if (pageClicked === 1) {
      return true;
    } else {
      return false;
    }
  };

  const rightReadOnly = () => {
    if (pageClicked === pageCount) {
      return true;
    } else {
      return false;
    }
  };

  const previousPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (pageClicked - 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const nextPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (pageClicked + 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const firstPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const lastPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"3","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      pageCount +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };




  const setTimeLine = (e) => {
    setTimeline(e);
  };

  const showupdownbtn = (hd) => {
    var sarray = sortColumn;
    var clmn = "";
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
      console.log(hd,sarray.name);
      

    if (sarray.name === hd) {
      sarray.sort = !sarray.sort;
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].name === hd) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }

      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    } else {
      sarray.name = hd;
      sarray.sort = true;
      for (var li = 0; li < list.length; li++) {
        for (var lll = 0; lll < list[li].heading.length; lll++) {
          if (list[li].heading[lll].label === hd) {
            clmn = list[li].heading[lll].name;
            break;
          }
        }
      }
      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    }
    setCol_mn({ sortColumn: sarray });
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';
    console.log(pp);
    
    setList(pp);
  };

  const setSort = () => {
    var clmn = "";
    var pp = "";
    var srt = sortColumn.name;
    if (srt !== "") {
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].name === sortColumn.name) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }
      if (sortColumn.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
      return pp;
    } else {
      pp +=
        ',{"sort":{"asc":"true","column":"' +
        list[0].heading[0].name +
        '","init":"false"}}';
    }
    return pp;
  };

  //.................. Api Call ...............//

  const setListRecord = (listrecord ,isForUpdate) => {
    console.log(listrecord);
    var columnarry = [];
    var hd = [];
    var va_ll = [];

    let srrt = listrecord.formRecordList[4].sort;
    sortColumn.name = srrt.column;
    sortColumn.sort = srrt.asc === "true" ? true : false;

    if ("Error" in listrecord) {
    } else {
      var scnd = listrecord.formRecordList[2];
      if ("Error" in scnd) {
        if (scnd.Error === "No record found.") {
          setlist([]);

          // this.setState({ list: [] });
        }
      } else {
        setTabLabel(listrecord.formRecordList[1].table.label)
        var page_clicked1 = parseInt(
          listrecord.formRecordList[3].page.page_clicked
        );
        var page_count1 = parseInt(
          listrecord.formRecordList[3].page.page_count
        );
        var page_records1 = listrecord.formRecordList[3].page.page_records;
        var record_count1 = parseInt(
          listrecord.formRecordList[3].page.record_count
        );

        var flt = listrecord.formRecordList[5];
        var fltarr = listrecord.formRecordList[5].filter;
        var tmln = listrecord.formRecordList[6].timeLine;
        var filterString = "";
        var leng = fltarr.length;
        for (var f = 0; f < fltarr.length; f++) {
          if (leng === 1 && fltarr[f].cl === "") {
            break;
          } else {
            if (f > 0) {
              filterString += fltarr[f].af + " ";
            }
            filterString += fltarr[f].cl + " ";
            filterString += fltarr[f].mc + " ";
            if (
              fltarr[f].ct === "reference" ||
              fltarr[f].ct === "multi_select" ||
              fltarr[f].ct === "depend_table"
            ) {
              filterString += fltarr[f].rf.value + " ";
            } else {
              filterString += fltarr[f].an + " ";
            }
          }
        }
        console.log("records ", listrecord.formRecordList[2].records);
        for (var i = 0; i < listrecord.formRecordList[2].records.length; i++) {
          var in_vl = [];
          var record_id;
          // console.log("length "+listrecord.formRecordList[2].records[i].record.length);
          for (
            var j = 0;
            j < listrecord.formRecordList[2].records[i].record.length;
            j++
          ) {
            if (i === 0) {
              if (j === 0) {
              } else {
                // hd.push({
                //   label:
                //     listrecord.formRecordList[2].records[i].record[j].label,
                //   name: listrecord.formRecordList[2].records[i].record[j].name,
                // });
              }
            }
            if (j === 0) {
              record_id =
                listrecord.formRecordList[2].records[i].record[j].value;
            } else if (j === 1) {
              // if (
              //   listrecord.formRecordList[2].records[i].record[j].type ===
              //   "reference"
              // ) {
              //   in_vl.push({
              //     value:
              //       listrecord.formRecordList[2].records[i].record[j].value,
              //     type: listrecord.formRecordList[2].records[i].record[j].type,
              //     firstrecord: true,
              //     table: listrecord.formRecordList[1].table.name
              //       ? listrecord.formRecordList[1].table.name
              //       : listrecord.formRecordList[1].table.value,
              //     r_id: record_id,
              //   });
         //     } else {
          
            in_vl.push({
              value:
                listrecord.formRecordList[2].records[i].record[j].value,
              type: listrecord.formRecordList[2].records[i].record[j]
                .type,
              firstrecord: true,
              table: listrecord.formRecordList[1].table.name
                ? listrecord.formRecordList[1].table.name
                : listrecord.formRecordList[1].table.value,
              r_id: record_id,
              name: listrecord.formRecordList[2].records[i].record[j]
                .name,
              columnAccess:
                listrecord.formRecordList[2].records[i].record[j]
                  ?.columnAccess,
              displayColumn:
                listrecord.formRecordList[2].records[i].record[j]
                  ?.displayColumn,
              uivalid:
                listrecord.formRecordList[2].records[i].record[j]
                  ?.uivalid,
              validation:
                listrecord.formRecordList[2].records[i].record[j]
                  ?.validation,
              name: listrecord.formRecordList[2].records[i].record[j]
                ?.name,
              id: listrecord.formRecordList[2].records[i].record[j].id,
              rowIndex: i,
              isInPref:
                listrecord.formRecordList[2].records[i].record[j]
                  .isInPref,
              choice:
                listrecord.formRecordList[2].records[i].record[j]?.choice,
              label:
                listrecord.formRecordList[2].records[i].record[j]?.label,
                dep_choice:
                listrecord.formRecordList[2].records[i].record[j]?.dep_choice,
            });
          
              //}
            } else {
              // if (
              //   listrecord.formRecordList[2].records[i].record[j].type ===
              //   "reference"
              // ) {
              //   in_vl.push({
              //     value:
              //       listrecord.formRecordList[2].records[i].record[j].value,
              //     type: listrecord.formRecordList[2].records[i].record[j].type,
              //     firstrecord: false,
              //     table: listrecord.formRecordList[1].table.name
              //       ? listrecord.formRecordList[1].table.name
              //       : listrecord.formRecordList[1].table.value,
              //     r_id: record_id,
              //   });
          //    } else {

          
            in_vl.push({
              value:
                listrecord.formRecordList[2].records[i].record[j].value,
              type: listrecord.formRecordList[2].records[i].record[j]
                .type,
              firstrecord: false,
              table: listrecord.formRecordList[1].table.name
                ? listrecord.formRecordList[1].table.name
                : listrecord.formRecordList[1].table.value,
              r_id: record_id,
              name: listrecord.formRecordList[2].records[i].record[j]
                .name,

              columnAccess:
                listrecord.formRecordList[2].records[i].record[j]
                  ?.columnAccess,
              displayColumn:
                listrecord.formRecordList[2].records[i].record[j]
                  ?.displayColumn,
              uivalid:
                listrecord.formRecordList[2].records[i].record[j]
                  ?.uivalid,
              validation:
                listrecord.formRecordList[2].records[i].record[j]
                  ?.validation,
              name: listrecord.formRecordList[2].records[i].record[j]
                ?.name,
              id: listrecord.formRecordList[2].records[i].record[j].id,
              rowIndex: i,
              isInPref:
                listrecord.formRecordList[2].records[i].record[j]
                  .isInPref,
              choice:
                listrecord.formRecordList[2].records[i].record[j]?.choice,
              label:
                listrecord.formRecordList[2].records[i].record[j]?.label,
                dep_choice:
                listrecord.formRecordList[2].records[i].record[j]?.dep_choice,
            });
            //  }
            }
          }
          // console.log("heading: " + JSON.stringify(in_vl))
          in_vl.splice(0, 0, { ref: false, isInPref: true });

          va_ll.push({ colr: in_vl });
        }
        let col_list = [];
        for (var c = 0; c < listrecord.formRecordList[9].column.length; c++) {
          if (listrecord.formRecordList[9].column[c].type === "String") {
            col_list.push({
              label: listrecord.formRecordList[9].column[c].label,
              name: listrecord.formRecordList[9].column[c].name,
              type: listrecord.formRecordList[9].column[c].type,
            });
          }
        }
        for (
          var p = 0;
          p < listrecord.formRecordList[10].preference.length;
          p++
        ) {
          hd.push({
            label: listrecord.formRecordList[10].preference[p].label,
            name: listrecord.formRecordList[10].preference[p].name,
          });
        }
        hd.splice(0, 0, { ref: false });
        columnarry.push({ heading: hd, rcd: va_ll });
        listRef.current=columnarry
        var cla = JSON.parse(
          JSON.stringify(listrecord.formRecordList[9].column)
        );
        console.log(filterString);
        cla.unshift({ label: "None", name: "none" });
        // console.log(JSON.stringify(listrecord));
        console.log(columnarry);
        setlist(columnarry);
        setRefList(JSON.parse(JSON.stringify(columnarry)));
        setListRcd(listrecord);
        setPageClicked(page_clicked1);
        setPageCount(page_count1);
        setPageRecords(page_records1);
        setRecordCount(record_count1);
        setCol_mn(cla);
        setColumnList(col_list);
        setPrefCall(listrecord.formRecordList[9].column);
        setFilter(flt);
        console.log("filt : " + JSON.stringify(fltarr));
        executedFilter.current = JSON.parse(JSON.stringify(fltarr));
        setFiltArray(fltarr);
        setTimeLine(tmln);
        setfilString(filterString);
        // console.log(
        //   "lll : " + JSON.stringify(listrecord.formRecordList[11].button)
        // );
        setButton(listrecord.formRecordList[11].button);
        setLoading(false);
      }
    }
  };

  const setList = (tablere) => {
    axios
      .post(loca + "/marketplace/get/relation/record", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          console.log(resp.data);
          const listrecord = resp.data;
          setTabId(listrecord.formRecordList[1].table.id);
          setTabLabel(listrecord.formRecordList[1].table.label);
          setListRecord(listrecord);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const changeSearch = (val) => {
    if (val === "") {
      setSrch("");
      filterClear();
    } else {
      setSrch(val);
    }
  };

  const callSearchbtn = () => {
    var type = "";
    var label = "";
    for (var c = 0; c < columnList.length; c++) {
      if (srch_column === columnList[c].name) {
        type = columnList[c].type;
        label = columnList[c].label;
      }
    }
    let filte = [
      {
        ct: type,
        af: "",
        mc: "contains",
        cl: label,
        co: srch_column,
        an: srch,
        rf: { id: "", value: "" },
      },
    ];
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"marketplace"}}';
    fs += ',{"table":{"id":"","name":"' + listName + '","label":""}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"created","init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filte) + "}";
    fs += ',{"timeLine":"' + timeline + '"}]}';
    setFiltArray([...filte]);
    setList(fs);
  };

  const searchColumn = (e) => {
    // this.setState({ srch_column: e.target.value });
    setSrch_column(e.target.value);
  };

  const callform = (nam, tab, r_id) => {
    if (nam === "first") {
      navigation({
        pathname: user + "/form",
        search: createSearchParams({
          rty: "record",
          tableName: tab,
          rid: r_id,
        }).toString(),
      });
      // props.showFormCompo(tab, r_id, "record");
    } else if (nam === "new") {
      navigation({
        pathname: user + "/form",
        search: createSearchParams({
          rty: "new",
          tableName: tab,
          rid: r_id,
        }).toString(),
      });
      // props.showFormCompo(tab, 0, "new");
    }
  };

  // const dismiss = () => {
  //   unmountMe();
  // }
  const selectedRows = (checked, index) => {
    list[0].rcd[index].colr[0].ref = checked;
    if (!checked) {
      list[0].heading[0].ref = false;
    }
    setlist([...list]);
  };

  const selectedAllRows = (checked) => {
    let rcd = list[0].rcd;
    console.log(list);

    if (checked) {
      list[0].heading[0].ref = true;
      for (let i = 0; i < rcd.length; i++) {
        list[0].rcd[i].colr[0].ref = true;
      }
      setlist([...list]);
    } else {
      list[0].heading[0].ref = false;
      for (let i = 0; i < rcd.length; i++) {
        list[0].rcd[i].colr[0].ref = false;
      }
      setlist([...list]);
    }
  };
  const selectedAction = (url) => {
    if (list[0].rcd.length > 0) {
      if (url === "/marketplace/delete/record") {
        setbtnNamee("Delete");
        setModal(true);
        setUrl(url);
      } else if (url === "/marketplace/accept") {
        setbtnNamee("Accept");
        setModal(true);
        setUrl(url);
      } else {
        selectAction(url);
      }
    }
  };

  const selectAction = (url) => {
    setIsClick(true);
    let rcd = list[0].rcd;
    let postBody = [];
    for (let i = 0; i < rcd.length; i++) {
      if (rcd[i].colr[0].ref) {
        // rcd[i].colr.splice(0,1)
        postBody.push(
          rcd[i].colr[1].r_id
          // table: rcd[i].colr[1].table
        );
        continue;
      }
    }
    var jso = {
      url: url,
      table: rcd[0].colr[1].table,
      record: postBody,
    };
    // setLoading(true);
    axios
      .post(loca + "/marketplace/selectedAction", jso, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          const record = resp.data;
          if (record.Error) {
            toast(record.Error, {
              position: "top-center",
              theme: "colored",
              type: "error",
              style: {
                marginBottom: userDetails.OS !== "null" ? 12 : 0,
              },
            });
          } else {
            if (record.message) {
              toast(record.message, {
                position: "top-center",
                theme: "colored",
                type: "success",
                style: {
                  marginBottom: userDetails.OS !== "null" ? 12 : 0,
                },
              });
            } else {
              toast(record, {
                position: "top-center",
                theme: "colored",
                type: "success",
                style: {
                  marginBottom: userDetails.OS !== "null" ? 12 : 0,
                },
              });
            }
            // refresh();
            // setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
          setModal(false);
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      )
      .finally(() => {
        setModal(false);
        setIsClick(false);
      });

    if (url === "1") {
    }
  };
  const setDefaultOption = () => {
    const selectElement = mySelect.current;
    selectElement.selectedIndex = 0;
  };

  const filterItem = () => {
    setShowList(!showlist);
    setShowbtn(!showbtn);
  };

  const filterClear = () => {
    var fltarray = [];
    fltarray.push(filt);
    setFiltArray(fltarray);
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const filtersubmit = () => {
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"marketplace"}}';
    fs += ',{"table":{"id":"","name":"' + listName + '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"created","init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filtarray) + "}";
    fs += ',{"timeLine":"' + timeline + '"}]}';
    setList(fs);
  };

  const callbtn = (nam) => {
    var btn = button;
    if(nam==="update"){
      let changedRC=changedRowAndCols.current
      updateRecord({changedRowAndCols:changedRC,refList,list, listName:lstName ,token,loca,button ,listRcd})
    }
    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].value === nam) {
        console.log(JSON.stringify(btn[ij]));
        var btntype = btn[ij].returnWebLocation;
        var callType = btn[ij].call_type;
        setError("");
        setLoading(true);
        if (callType === "selection") {
          navigation({
            pathname: user + "/role-selection",
            search: createSearchParams({
              tableName: tableName,
              rid: i_d,
            }).toString(),
          });
        } else if (callType === "module") {
          console.log(btn[ij].script);
          var scpt = btn[ij].script;
          console.log(prevTab, rd);
          var setTab = "";
          var tabId = "";
          for (let i = 0; i < rd.length; i++) {
            if (rd[i].name === "form_table_id") {
              setTab = rd[i].value.name;
              tabId = rd[i].value.id;
            }
          }

          var f = new Function(
            [
              "navigation",
              "fieldName",
              "record",
              "all_ref_cols",
              "createSearchParams",
              "user",
              "setTab",
              "tabId",
            ],
            scpt
          );
          f(
            navigation,
            prevTab,
            rd,
            all_ref_cols,
            createSearchParams,
            user,
            setTab,
            tabId
          );
        } else {
          // mnrecord.formRecord[2].record = record;
          console.log("nexttttt");
          axios
            .get(loca + btn[ij].webUrl, {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
              },
            })
            .then((resp) => {
              const rcd = resp.data;
              setLoading(false);
              console.log(rcd);
              // var msg = rcd.formRecord[4];
              // if (msg !== "") {
              // setState({ page_message: true, message: msg });
              //   setPageMessage(true);
              //   setMessage(msg);
              // }
              console.log("nexttttt2", btntype);
              if (btntype === "NextPage") {
                console.log("nexttttt");
                navigation("/form", {
                  state: { rty: "new", tableName: listName, rid: "0" },
                });
              }
            });
        }
      }
    }
  };

  const getChoiceRcd = async (col_id) => {
    let chk = [];
    let ck = await axios
      .get(loca + "/marketplace/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          let chk = res.choiceRecords;
          return chk;
          // console.log(rcd);
        },
        (error) => {
          console.log(error);
        }
      );
    return ck;
  };

  const callfilter = async (farray, in_index, col_id) => {
    if (col_id !== -1) {
      if (
        farray[in_index].ct === "choice" &&
        farray[in_index].ch.length === 0
      ) {
        let chc = await getChoiceRcd(col_id).then((res) => {
          if (res.length > 0) {
            farray[in_index].ch = res;
            farray[in_index].an = res[0].name;
          } else {
            farray[in_index].ch = [];
          }
        });
      }
    }
    setFiltArray([...farray]);
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      callSearchbtn();
    }
  };

  const calltimeline = (timeline) => {
    setTimeline(timeline);
  };

  const formInner = (objr, ot) => {
    //   console.log("objr", formChangefn);

    objr.index = ot.colIndex;
    console.log("objr", objr);
    return (
      <FormInnerCompo
        obj={objr}
        multiUpdate={true}
        m_rid={ot?.recordId}
        formChangefn={formChangefn}
        validationfn={validationfn}
        clickRefrence={clickRefrence}
      ></FormInnerCompo>
    );
  };
  const handleUpdateSave = (rowIndex, colCell) => {
    let otb = [...list];
    otb[0].rcd[rowIndex].colr[colCell].editingCellIndex = false;

    callAgainFormChangeFn(colCell, otb[0].rcd[rowIndex].colr[colCell]);
    setlist(otb);

    setTimeout(() => {
      callbtn("update");
    }, 500)

  };

  const handleUpdateCancel = (rowIndex, colCell) => {
    let otb = [...list];
    otb[0].rcd[rowIndex].colr[colCell].editingCellIndex = false;
    removeInChangedRowsAndCols(colCell, list[0].rcd[rowIndex].colr[colCell]);
    // this.setState({list:otb})
    setlist(otb);
  };

  const checkIfTypeNotExists = (type) => {
    for (let i = 0; i < listOfTypes.current?.length; i++) {
      if (listOfTypes?.current[i] === type) {
        return false;
      }
    }
    return true;
  };


  const removeInChangedRowsAndCols = (index, obj) => {

    let refLst = [...refList];
    let lst = [...list];
    console.log(index);
    console.log(obj);
    let col = index;
    let unChanged = refLst[0].rcd[obj.rowIndex].colr[col];
    let changed = lst[0].rcd[obj.rowIndex].colr[col];

    console.log("unChanged", unChanged);
    console.log("changed", changed);

    if (unChanged.type === "reference" || unChanged.type === "depend_table") {
      formChangefn(
        unChanged.value.name,
        index,
        unChanged.name,
        unChanged.type,
        unChanged.value.id,
        true,
        unChanged,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        unChanged.uivalid.read_only
      );

    } else {
      formChangefn(
        unChanged.value,
        index,
        unChanged.name,
        unChanged.type,
        unChanged.id,
        true,
        unChanged,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        unChanged.uivalid.read_only
      );
    }

    if (changedRowAndCols.current) {
      const changedRowAndCols2 = changedRowAndCols.current;

      if (changedRowAndCols2.has(obj.rowIndex)) {
        let collArr = changedRowAndCols2.get(obj.rowIndex);
        let newCollArr = collArr.filter?.((e) => e !== index);
        if (newCollArr?.length !== 0) {
          changedRowAndCols2.set(obj.rowIndex, newCollArr);
        } else {
          changedRowAndCols2.delete(obj.rowIndex);
        }
      }

      console.log("changedRowAndCols after", changedRowAndCols2);
      changedRowAndCols.current = changedRowAndCols2
    }



  }


  const addInChangedRowAndCols = (index, obj) => {

    if (!changedRowAndCols.current) {
      changedRowAndCols.current = new Map();
    }
    const changedRowAndCols2 = changedRowAndCols.current;
    if (changedRowAndCols2.has(obj.rowIndex)) {
      let collArr = changedRowAndCols2.get(obj.rowIndex);
      let found = collArr.find?.((e) => e === index);
      if (!found) collArr.push(index);
    } else {
      let collsArr = [];
      collsArr.push(index);
      changedRowAndCols2.set(obj.rowIndex, collsArr);
    }

    changedRowAndCols.current = changedRowAndCols2;
  };
  const setRef = (id, val, properties, refTable) => {
    //  setKeyValueJson(properties);
    console.log("setref ls", id);
    formChangefn(
      val,
      objIndex,
      objName,
      objType,
      id,
      true,
      curObj,
      refTable,
      "",
      "",
      "",
      properties,
      depCol
    );
    cancelModelList();

  };

  const cancelModelList = () => {
    setshowModelList(false);
  };

  const addToMultipleSelectedRows = (varible, key, value) => {
    console.log("addToMultipleSelectedRows", varible);
    if (!varible) varible = new Map();
    if (varible) {
      varible.set(key, value);
    }
  };

  const removeFromMultipleSelectedRows = (varible, key) => {
    if (varible) {
      varible.delete(key);
    }
  };

  const getKeysFromMultipleSelectedRows = (varible) => {
    if (varible) {
      return Array.from(varible.keys());
    }
    return [];
  };

  const isColSelected = (row, col) => {
    // let multiRowSelect = new Map(this.state.multiRowSelect);

    // if(multiRowSelect.get(row)){
    //    if(col==multiRowSelect.get(row))
    //     return true;
    // }
    // return false;
    if (!multipleSelectedRows.current) {
      multipleSelectedRows.current = new Map();
    }
    let newMultipleSelectedRows = multipleSelectedRows.current;

    if (
      col === multiSelectCol &&
      getKeysFromMultipleSelectedRows(newMultipleSelectedRows)?.includes(row)
    ) {
      console.log(col, multiSelectCol);
      return true;
    }

    return false;
  };

  const getIdForUpdate = (allCols) => {
    for (let k = 0; k < allCols?.length; k++) {
      if (allCols[k].firstrecord) {
        return allCols[k].r_id;
      }
    }
  };

  const handleRowClick = (e, rowIndex, colCel) => {
    console.log("rowIndex", rowIndex, multiSelectCol, colCel);
    e.preventDefault();
    //  this.pushInMultiRowSelect(rowIndex ,colCel)

    let newMultipleSelectedRows = new Map();
    let otb = [...list];

    let allCols = otb[0].rcd[rowIndex].colr;
    let uni_id = "";
    uni_id = getIdForUpdate(allCols);
    console.log("uni_id ", uni_id);

    if (multipleSelectedRows.current) {
      newMultipleSelectedRows = multipleSelectedRows.current;
    }

    if (colCel != multiSelectCol || rowIndex != multiSelectrow) {
      //  this.setState({multiSelectCol:colCel});
      setMultiSelectCol(colCel);
      setMultiSelectRow(rowIndex);
      newMultipleSelectedRows = new Map();
    }
    if (!multipleSelectedRows.current) newMultipleSelectedRows = new Map();

    if (
      !getKeysFromMultipleSelectedRows(newMultipleSelectedRows).includes(
        rowIndex
      )
    ) {
      addToMultipleSelectedRows(newMultipleSelectedRows, rowIndex, uni_id);
      console.log("newMultipleSelectedRows", newMultipleSelectedRows);
      multipleSelectedRows.current = newMultipleSelectedRows;
    } else if (
      getKeysFromMultipleSelectedRows(newMultipleSelectedRows).includes?.(
        rowIndex
      ) &&
      e.ctrlKey
    ) {
      removeFromMultipleSelectedRows(newMultipleSelectedRows, rowIndex);
      multipleSelectedRows.current = newMultipleSelectedRows;
    }
  };

  const handleMouseDown = (rowIndex, colIndex, event) => {
    if (event.shiftKey) {
      console.log("changinf ");

      // this.setState({
      //   isDragging: true,
      //   dragStartCell: { rowIndex, colIndex },
      // });
      setIsDragging(true);
      setDragStartCell({ rowIndex, colIndex });
      // Prevent text selection during drag
      document.body.style.userSelect = "none";

      // Change cursor to + sign when dragging starts
      document.body.style.cursor = "crosshair";
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragStartCell(null);
    // Re-enable text selection
    document.body.style.userSelect = "auto";

    // Reset cursor back to default
    document.body.style.cursor = "default";
  };

  const handleMouseOver = (rowIndex, colIndex) => {
    //   const { isDragging, dragStartCell } = this.state;

    // console.log("rowIndex ",rowIndex);

    let otb = [...list];
    if (isDragging && dragStartCell) {
      const selected = multipleSelectedRows.current;
      for (
        let i = Math.min(dragStartCell.rowIndex, rowIndex);
        i <= Math.max(dragStartCell.rowIndex, rowIndex);
        i++
      ) {
        if (!getKeysFromMultipleSelectedRows(selected).includes?.(i)) {
          let allCols = otb[0].rcd[rowIndex].colr;
          let uni_id = "";
          uni_id = getIdForUpdate(allCols);
          addToMultipleSelectedRows(selected, rowIndex, uni_id);
        }
      }
      // this.setState({ selectedCells: selected })
      multipleSelectedRows.current = selected;
    }
  };

  const getRowsForUpdate = async (multipleSelectedRows) => {
    let filt = JSON.parse(
      '{"ct":"","rf":{"id":"","value":""},"af":"","ch":[],"mc":"","cl":"","co":"","an":"","dc":{"id":"","value":""}}'
    );
    let filtArr = [];

    if (multipleSelectedRows.current) {
      let cnt = 0;
      for (let [key, value] of multipleSelectedRows.current) {
        let obj = { ...filt };
        obj.ct = "String";
        obj.co = "uni_id";
        obj.cl = "Uni Id";
        obj.mc = "is";
        obj.an = value;
        if (cnt != 0) obj.af = "OR";
        filtArr.push(obj);
        cnt++;
      }
      var fs = '{"formRecordList":[';
      fs += '{"application":{"id":"","name":"marketplace"}}';
      fs += ',{"table":{"id":"","name":"' + tableName + '","label":""}}';
      fs += ',{"records":[]}';
      fs +=
        ',{"page":{"record_count":"0","page_count":"1",' +
        '"page_clicked":"' +
        (pageClicked) +
        '","page_records":"0"}}';

      fs += setSort();
      fs += ',{"filter":' + JSON.stringify(filtarray) + "}";
      fs += ',{"timeLine":"' + timeline + '"}] ,"forUpdate":true}';

      let res = await axios.post(`${loca}/marketplace/get/multiple/record`, fs, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      });

      const listrecord = res.data;
      console.log("resss", res.data);
      // processListRecords(listrecord, true);
      setListRecord(listrecord, true);
    }
  };


  const handleDoubleClickOpenForUpdate = async (rowIndex, colCell) => {
    console.log("rowsScriptHasBeenRun", multipleSelectedRows, dataLoadForUpdate);
    let otb = []
    if (!dataLoadForUpdate.current) {
      await getRowsForUpdate(multipleSelectedRows);
      console.log(" dataLoadForUpdate ", true);
      setTimeout(() => { dataLoadForUpdate.current = true }, 500)
      otb = [...listRef.current];
      checkRefrecord()
    } else
      otb = [...list]
    if (!otb[0].rcd[rowIndex].colr[colCell].editingCellIndex) {
      if (!rowsScriptHasBeenRun?.current) {
        rowsScriptHasBeenRun.current = [];
      }
      let row = rowsScriptHasBeenRun.current.find?.((e) => e == rowIndex);
      if (row === undefined) {
        rowsScriptHasBeenRun.current.push?.(rowIndex);
        // this.runScriptOnLoadWhenUpdate(rowIndex, colCell);
      }
      otb[0].rcd[rowIndex].colr[colCell].editingCellIndex = true;
    }

    setlist(otb);

  };

  const checkRefrecord = async () => {
    let resp = await axios
      .get(loca + "/marketplace/get/reference/qualifier/" + lstName.current, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
    const rq_data = JSON.parse(JSON.stringify(resp.data));
    setrecord_rq(rq_data);
  };

  const getMultiSelectValues = (obj) => {
    // console.log(obj);
    // let rcd=obj.record;
    // let res="";
    // if(rcd?.length!=0){
    //   for(let k=0; k<10 && k<rcd?.length;k++){
    //     if(k>0)
    //       res+=",";
    //      res=res+ rcd[k]?.value
    //   }
    // }
    // console.log(res);
    // return res;
  };

  const formChangefn = (
    vl,
    index,
    ob,
    type,
    id,
    vrf,
    obj,
    outerIndex,
    innerIndex,
    nme,
    props,
    depCol,
    readonly
  ) => {
    let record = [...list];
    //var record = otb[0].rcd[obj.rowIndex].colr;
    console.log("obj24", obj);

    let abc = {
      vl,
      index,
      ob,
      type,
      id,
      vrf,
      obj,
      outerIndex,
      innerIndex,
      nme,
      props,
      depCol,
      readonly,
    };
    console.log("abc", abc);

    formChangeFunction({
      location: "list",
      vl,
      index,
      ob,
      type,
      id,
      vrf,
      obj,
      outerIndex,
      innerIndex,
      nme,
      props,
      depCol,
      readonly,
      record,
      setRecord: setlist,
      tableName: listName, addInChangedRowAndCols, loca, token
    });

    // console.log(record);
  };

  const validationfn = (vl, index, ob, type, id, obj) => {
    let record = [...list];
    validationFunction({
      vl,
      index,
      ob,
      type,
      id,
      setError,
      record,
      setRecord: setlist,
      location: "list",
      obj,
      loca, token
    });
  };

  const clickRefrence = (
    e,
    id,
    name,
    type,
    index,
    label,
    readonly,
    depend_column,
    refTable,
    obj
  ) => {
    e.preventDefault();
    //  console.log(record_rq, depend_column);
    //  setcolumn({index, name, type, col:id, readonly, depend_column, refTable,record_rq,setref_filter});
    setcolumn({
      index,
      name,
      type,
      col: id,
      readonly,
      depend_column,
      refTable,
      record_rq,
      setref_filter,
      setColumnId,
      setshowModelList,
      setObjRefTable,
      setObjName,
      setDepCol,
      setObjType,
      setObjIndex,
      setCurObj,
      obj,
      list,
      mainFilt

    });
  };



  const callAgainFormChangeFn = (colIndex, obj) => {
    let otb = [...list];
    let allRows = getKeysFromMultipleSelectedRows(multipleSelectedRows.current);
    console.log("callAgainFormChangeFn",obj,allRows,allRows?.includes(obj?.rowIndex));
    if (allRows?.includes(obj?.rowIndex)) {
      let att = allRows;
      for (let k = 0; k < att?.length; k++) {
        console.log(att[k]);
        if (att[k] != obj.rowIndex) {
          let column = otb[0].rcd[att[k]].colr[colIndex];
          
          if (column.type === "reference" || column.type === "depend_table") {
            formChangefn(
              obj.value.name,
              colIndex,
              column.name,
              column.type,
              obj.value.id,
              true,
              column,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              column.uivalid.read_only
            );
          } else
            formChangefn(
              obj.value,
              colIndex,
              column.name,
              column.type,
              column.id,
              true,
              column,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              column.uivalid.read_only
            );
        }
      }
    }
    // this.callAginfm.current=false;
  };


  if (!loading) {
    return (
      <div className="pagesetup">
        {loading === true ? (
          <WorkInProgress />
        ) : (
          <div>
            {isMobile === true ? (
              <div className="row bck bck-rel">
                <div className="col-lg mid">
                  <span className="obj_head ">{listName}</span>
                </div>
                <div className="col-lg filter-ic">
                  {showlist === false && (
                    <select
                      className="form-select namelist-mob"
                      aria-label="Default"
                      value={srch_column}
                      onChange={(e) => searchColumn(e)}
                    >
                      {columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}

                  {showlist === false && (
                    <input
                      className="srch-mob form-"
                      type="search"
                      aria-label="Search"
                      value={srch}

                      onChange={(e) => changeSearch(e.target.value)}
                    ></input>
                  )}

                  {showlist === false && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Search"

                      onClick={() => callSearchbtn()}
                    ></input>
                  )}
                </div>
                <div className="col-lg martop10 disfl filter-ic">
                  <i
                    className="fa fa-filter vlpointer pdtop5"

                    onClick={filterItem}
                  ></i>
                  <i
                    className="fa fa-cog vlpointer cog_pd pdtop5"

                    onClick={handleShow}
                  ></i>
                  <div className="">
                    <span style={{ marginLeft: "0.5em" }}>
                      <input
                        className=" mybt"
                        type="button"
                        value="<<"
                        onClick={firstPage}
                        disabled={leftReadOnly()}
                      />
                      <input
                        className=" mybt"
                        type="button"
                        value="<"
                        onClick={previousPage}
                        disabled={leftReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className="in-put"
                        type="text"
                        readOnly={true}
                        value={pageRecords}
                      ></input>
                    </span>
                    <span className="text-uppercase spfont"> of </span>
                    <span className="rcd_count spfont">{recordCount}</span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">"
                        onClick={nextPage}
                        disabled={rightReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">>"
                        disabled={rightReadOnly()}
                        onClick={lastPage}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg martop10">
                  {button.length > 0 &&
                    button.map((obj, oo_i) => (
                      <button
                        type="button"
                        className="csm_btn csm_btn_pri col-md-2 sub-btn"
                        key={oo_i}
                        onClick={(e) => callbtn(obj.value)}
                      >
                        {obj.name}
                      </button>
                    ))}
                </div>
                <div className="col-lg martop10">
                  {showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Run"

                      onClick={filtersubmit}
                    ></input>
                  )}

                  {showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Clear"

                      onClick={filterClear}
                    ></input>
                  )}
                </div>
              </div>
            ) : (
              <div
                className="bck bck-rel"
                style={{
                  display: "grid",
                  gridTemplateColumns: "2fr 1fr 2fr",
                  justifyContent: "between",
                  alignItems: "center",
                  gridTemplateAreas: "a b c",
                }}
              >
                {/* <div className="row bck bck-rel"> */}
                <div className="filter-ic">
                  <i
                    className="fa fa-filter vlpointer"

                    onClick={filterItem}
                  ></i>

                  <i
                    className="fa fa-cog vlpointer cog_pd"

                    onClick={handleShow}
                  ></i>

                  {showlist === true && (
                    <input
                      className="btn btn-primary btn-sm me-2"
                      type="button"
                      value="Run"

                      onClick={filtersubmit}
                    ></input>
                  )}

                  {showlist === true && (
                    <input
                      className="btn btn-primary btn-sm"
                      type="button"
                      value="Clear"

                      onClick={filterClear}
                    ></input>
                  )}

                  {showlist === false && (
                    <select
                      style={{
                        // lineHeight: "0.8em",
                        width: "10em",
                        display: "inline",
                      }}
                      className="form-select namelist"
                      aria-label="Default"
                      value={srch_column}
                      onChange={(e) => searchColumn(e)}
                    >
                      {columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}

                  {showlist === false && (
                    <input
                      className="srch form-control"
                      type="search"
                      aria-label="Search"
                      value={srch}

                      style={{ height: "1.7rem" }}
                      onChange={(e) => changeSearch(e.target.value)}
                      onKeyDown={handleEnterPress}
                    ></input>
                  )}

                  {showlist === false && (
                    <button
                      className="btn btn-primary btn-sm"
                      type="button"
                      value="Search"

                      onClick={() => callSearchbtn()}
                    >
                      Search
                    </button>
                  )}
                </div>
                <div className="col-lg mid">
                  <p className="obj_head ">{tableLabel}</p>
                </div>
                <div className="bt_padd">
                <div>
                    <select
                      ref={mySelect}
                      className="dropdn"
                      onChange={(e) => {
                        e.target.value !== "none" &&
                          selectedAction(e.target.value);
                      }}
                    >
                      <option value={"none"}>None</option>
                      {button.length > 0 &&
                        list[0].rcd.length > 0 &&
                        button.map(
                          (obj, oo_i) =>
                            obj.ui_type === "selected_action" && (
                              <option key={oo_i} value={obj.webUrl}>
                                {obj.value}
                              </option>
                            )
                        )}
                    </select>
                  </div>
                  {button.length > 0 &&
                    button.map((obj, oo_i) => (
                      <button
                        className=" insrtbtn2 btn btn btn-primary btn-sm"
                        key={oo_i}
                        onClick={(e) => callbtn(obj.value)}
                      >
                        {obj.name}
                      </button>
                    ))}
                  <div>
                    <input
                      className=" mybt"
                      type="button"
                      value="<<"
                      onClick={firstPage}
                      disabled={leftReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value="<"
                      onClick={previousPage}
                      disabled={leftReadOnly()}
                    />

                    <span className="in-put">{pageRecords}</span>
                    <span className="text-uppercase spfont"> of </span>

                    <span className="rcd_count spfont">{recordCount}</span>
                    <input
                      className=" mybt"
                      type="button"
                      value=">"
                      onClick={nextPage}
                      disabled={rightReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value=">>"
                      disabled={rightReadOnly()}
                      onClick={lastPage}
                    />
                  </div>
                </div>
              </div>
            )}
            {showlist === false && filString !== "" && <div>{filString}</div>}
            <div></div>
            {showlist === true && (
              <NewFilterCompo
                showlist={showlist}
                col_mn={col_mn}
                call_fil={(filtarray, in_index, col_id) => {
                  callfilter(filtarray, in_index, col_id);
                }}
                filtarray={filtarray}
                setFiltArray={setFiltArray}
                timeline={timeline}
                setTimeLine={(timeline) => calltimeline(timeline)}
                setScript={(script) => { }}
                isScript={false}
                tableName={listName}
                isRelatedList={true}
              />
            )}
            {list.length > 0 &&
              list.map((lstobj, lst_i) => (
                <div
                  className="heading_top table_set overflow-auto"
                  style={{ position: "relative" }}
                  key={lst_i}
                >
                  {/* Moved key to the outer div */}
                  <table className="table table-bordered table-striped table-hover p-1">
                    <thead
                      className="table-dark"
                      style={{ position: "sticky" }}
                    >
                      <tr className="obj_name">
                        {lstobj.heading.map(
                          (obj, obj_i) =>
                            obj.type !== "group_key_value" &&
                            obj.type !== "key_value" &&
                            obj.type !== "password" && (
                              <th
                                key={obj_i}
                                className="vlpointer"
                                onClick={(e) => showupdownbtn(obj.label)}
                                style={{ textWrap: "nowrap" }}
                              >
                                  {obj_i === 0 ? (
                              <span>
                                <input
                                  type="checkbox"
                                  checked={obj.ref}
                                  onChange={(e) => {
                                    selectedAllRows(e.target.checked);
                                  }}
                                ></input>
                              </span>
                            ) : (
                              <div
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  padding: "0.5rem",
                                }}
                                onClick={(e) => {
                                  showupdownbtn(obj.name);
                                }}
                              >
                                {obj.label}
                                {sortColumn.name === obj.name &&
                                  sortColumn.sort === true && (
                                    <i className=" icpadding fa fa-arrow-up"></i>
                                  )}
                                {sortColumn.name === obj.name &&
                                  sortColumn.sort === false && (
                                    <i className=" icpadding fa fa-arrow-down"></i>
                                  )}
                              </div>
                            )}
                              </th>
                            )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {lstobj.rcd.map((objj, objj_i) => (
                        <tr className="obj_value" key={objj_i}>
                          {objj.colr.map(
                            (objr, objr_i) =>
                              objr.type !== "group_key_value" &&
                              objr.type !== "key_value" &&
                              objr.type !== "password" && objr.isInPref && (
                                <td
                                  key={objr_i}
                                  className={
                                    objr.firstrecord === true
                                      ? "val_pad val_under vlpointer"
                                      : isColSelected(objj_i, objr_i)
                                        ? "selected val_pad"
                                        : "val_pad"
                                  }
                                  onDoubleClick={() =>
                                    handleDoubleClickOpenForUpdate(objj_i, objr_i)
                                  }
                                  onClick={(e) => {
                                    handleRowClick(e, objj_i, objr_i);
                                  }}
                                  onMouseDown={
                                    objr.firstrecord === true
                                      ?
                                      (e) =>
                                        callform("first", objr.table, objr.r_id, e)
                                      : (e) => handleMouseDown(objj_i, objr_i, e)
                                  }
                                  onMouseUp={handleMouseUp}
                                  onMouseOver={() =>
                                    handleMouseOver(objj_i, objr_i)
                                  }
                                >
                                  {objr_i === 0 ? (
                                    <span>
                                      <input
                                        type="checkbox"
                                        checked={objr.ref}
                                        onChange={(e) => {
                                          selectedRows(e.target.checked, objj_i);
                                        }}
                                      ></input>
                                    </span>
                                  ) : objr.type === "image_upload" ? (
                                    objr.value !== "null" &&
                                      objr.value !== "" ? (
                                      <img
                                        className="list-images"
                                        src={imageloca + objr.value}
                                        alt=""
                                      />
                                    ) : (
                                      <span>No Image</span>
                                    )
                                  ) : objr.type === "video" ? (
                                    <video
                                      loop
                                      className="user-video-list"
                                      controls
                                    >
                                      <source
                                        src={imageloca + objr.value}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : objr.type === "reference" ? (
                                    <span>
                                      {objr.value.name !== "null"
                                        ? objr.value.name
                                        : ""}
                                    </span>
                                  ) : (
                                    <span>
                                      {(objr.type === "filter" ||
                                        objr.type === "filter_ref" ||
                                        objr.name === "json") &&
                                        // JSON.stringify(
                                        //   objr.value?.length > 50
                                        //     ? objr.value.slice(0, 50) + " ..."
                                        //     : objr.value
                                        // )
                                        (objr.editingCellIndex === true ? (
                                          <div
                                            key={objr_i}
                                            style={{ display: "flex" }}
                                            className="multiUpdateParent"
                                            onMouseDown={(e) =>
                                              e.stopPropagation()
                                            }
                                          >
                                            {!notAllowedForUpdate?.current.includes?.(
                                              objr.type
                                            ) &&
                                              formInner(objr, {
                                                colIndex: objr_i,
                                                rowIndex: objj_i,
                                              })}
                                            {notAllowedForUpdate?.current.includes?.(
                                              objr.type
                                            ) && (
                                                <p className="inVisibleField">
                                                  Not allowed update from list
                                                </p>
                                              )}
                                            <button
                                              className="multiUpdateBtn1"
                                              onClick={() => {
                                                handleUpdateSave(objj_i, objr_i);
                                              }}
                                            >
                                              {" "}
                                              <span style={{ color: "green" }}>
                                                <i className="fa-regular fa-circle-check"></i>
                                              </span>
                                            </button>
                                            <button
                                              className="multiUpdateBtn2"
                                              onClick={() => {
                                                handleUpdateCancel(
                                                  objj_i,
                                                  objr_i
                                                );
                                              }}
                                            >
                                              <span style={{ color: "red" }}>
                                                <i className="fa-regular fa-circle-xmark"></i>
                                              </span>
                                            </button>
                                          </div>
                                        ) : JSON.stringify(objr.value)?.length >
                                          50 ? (
                                          JSON.stringify(objr.value).slice(
                                            0,
                                            50
                                          ) + " ..."
                                        ) : (
                                          JSON.stringify(objr.value)
                                        ))}
                                      {objr.type === "group_key_value" &&
                                        // JSON.stringify(
                                        //   objr.value.properties?.length > 50
                                        //     ? objr.value.properties.slice(0, 50) +
                                        //         " ..."
                                        //     : objr.value.properties
                                        // )
                                        (objr.editingCellIndex === true ? (
                                          <div
                                            key={objr_i}
                                            style={{ display: "flex" }}
                                            className="multiUpdateParent"
                                            onMouseDown={(e) =>
                                              e.stopPropagation()
                                            }
                                          >
                                            {!notAllowedForUpdate?.current.includes?.(
                                              objr.type
                                            ) &&
                                              formInner(objr, {
                                                colIndex: objr_i,
                                                rowIndex: objj_i,
                                              })}
                                            {notAllowedForUpdate?.current.includes?.(
                                              objr.type
                                            ) && (
                                                <p className="inVisibleField">
                                                  Not allowed update from list
                                                </p>
                                              )}
                                            <button
                                              className="multiUpdateBtn1"
                                              onClick={() => {
                                                handleUpdateSave(objj_i, objr_i);
                                              }}
                                            >
                                              {" "}
                                              <span style={{ color: "green" }}>
                                                <i className="fa-regular fa-circle-check"></i>
                                              </span>
                                            </button>
                                            <button
                                              className="multiUpdateBtn2"
                                              onClick={() => {
                                                handleUpdateCancel(
                                                  objj_i,
                                                  objr_i
                                                );
                                              }}
                                            >
                                              <span style={{ color: "red" }}>
                                                <i className="fa-regular fa-circle-xmark"></i>
                                              </span>
                                            </button>
                                          </div>
                                        ) : (
                                          JSON.stringify(
                                            objr.value.properties?.length > 50
                                              ? objr.value.properties.slice(
                                                0,
                                                50
                                              ) + " ..."
                                              : objr.value.properties
                                          )
                                        ))}

                                      {objr.type === "html" &&
                                        (objr.editingCellIndex === true ? (
                                          <div
                                            key={objr_i}
                                            style={{ display: "flex" }}
                                            className="multiUpdateParent"
                                            onMouseDown={(e) =>
                                              e.stopPropagation()
                                            }
                                          >
                                            {!notAllowedForUpdate?.current.includes?.(
                                              objr.type
                                            ) &&
                                              formInner(objr, {
                                                colIndex: objr_i,
                                                rowIndex: objj_i,
                                              })}
                                            {notAllowedForUpdate?.current.includes?.(
                                              objr.type
                                            ) && (
                                                <p className="inVisibleField">
                                                  Not allowed update from list
                                                </p>
                                              )}
                                            <button
                                              className="multiUpdateBtn1"
                                              onClick={() => {
                                                handleUpdateSave(objj_i, objr_i);
                                              }}
                                            >
                                              {" "}
                                              <span style={{ color: "green" }}>
                                                <i className="fa-regular fa-circle-check"></i>
                                              </span>
                                            </button>
                                            <button
                                              className="multiUpdateBtn2"
                                              onClick={() => {
                                                handleUpdateCancel(
                                                  objj_i,
                                                  objr_i
                                                );
                                              }}
                                            >
                                              <span style={{ color: "red" }}>
                                                <i className="fa-regular fa-circle-xmark"></i>
                                              </span>
                                            </button>
                                          </div>
                                        ) : (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                objr.value?.length > 50
                                                  ? objr.value.slice(0, 50) +
                                                  " ..."
                                                  : objr.value,
                                            }}
                                          ></span>
                                        ))}

                                      {(objr.type === "reference" || objr.type === "depend_table") &&
                                        (objr.editingCellIndex === true ? (
                                          <div
                                            key={objr_i}
                                            style={{ display: "flex" }}
                                            className="multiUpdateParent"
                                            onMouseDown={(e) =>
                                              e.stopPropagation()
                                            }
                                          >
                                            {!notAllowedForUpdate?.current.includes?.(
                                              objr.type
                                            ) &&
                                              formInner(objr, {
                                                colIndex: objr_i,
                                                rowIndex: objj_i,
                                              })}
                                            {!notAllowedForUpdate?.current.includes?.(
                                              objr.type
                                            ) &&
                                              objr?.uivalid?.visible ===
                                              "false" && (
                                                <p className="inVisibleField">
                                                  Security prevents writing to
                                                  this field
                                                </p>
                                              )}
                                            <button
                                              className="multiUpdateBtn1"
                                              onClick={() => {
                                                handleUpdateSave(objj_i, objr_i);
                                              }}
                                            >
                                              {" "}
                                              <span style={{ color: "green" }}>
                                                <i className="fa-regular fa-circle-check"></i>
                                              </span>
                                            </button>
                                            <button
                                              className="multiUpdateBtn2"
                                              onClick={() => {
                                                handleUpdateCancel(
                                                  objj_i,
                                                  objr_i
                                                );
                                              }}
                                            >
                                              <span style={{ color: "red" }}>
                                                <i className="fa-regular fa-circle-xmark"></i>
                                              </span>
                                            </button>
                                          </div>
                                        ) : objr.value?.name?.length > 50 ? (
                                          objr.value?.name.slice(0, 50) + " ..."
                                        ) : (
                                          objr?.value?.name
                                        ))}

                                      {objr.type === "multi_select" &&
                                        (objr.editingCellIndex === true ? (
                                          <div
                                            key={objr_i}
                                            style={{ display: "flex" }}
                                            className="multiUpdateParent"
                                            onMouseDown={(e) =>
                                              e.stopPropagation()
                                            }
                                          >
                                            {!notAllowedForUpdate?.current.includes?.(
                                              objr.type
                                            ) &&
                                              formInner(objr, {
                                                colIndex: objr_i,
                                                rowIndex: objj_i,
                                              })}
                                            {notAllowedForUpdate?.current.includes?.(
                                              objr.type
                                            ) && (
                                                <p className="inVisibleField">
                                                  Not allowed update from list
                                                </p>
                                              )}
                                            <button
                                              className="multiUpdateBtn1"
                                              onClick={() => {
                                                handleUpdateSave(objj_i, objr_i);
                                              }}
                                            >
                                              {" "}
                                              <span style={{ color: "green" }}>
                                                <i className="fa-regular fa-circle-check"></i>
                                              </span>
                                            </button>
                                            <button
                                              className="multiUpdateBtn2"
                                              onClick={() => {
                                                handleUpdateCancel(
                                                  objj_i,
                                                  objr_i
                                                );
                                              }}
                                            >
                                              <span style={{ color: "red" }}>
                                                <i className="fa-regular fa-circle-xmark"></i>
                                              </span>
                                            </button>
                                          </div>
                                        ) : getMultiSelectValues(objr.value)
                                          .length > 50 ? (
                                          getMultiSelectValues(objr.value).slice(
                                            0,
                                            50
                                          ) + " ..."
                                        ) : (
                                          getMultiSelectValues(objr.value)
                                        ))}

                                      {checkIfTypeNotExists(objr.type) &&
                                        (objr.editingCellIndex === true ? (
                                          <div
                                            key={objr_i}
                                            style={{ display: "flex" }}
                                            className="multiUpdateParent"
                                            onMouseDown={(e) =>
                                              e.stopPropagation()
                                            }
                                          >
                                            {!notAllowedForUpdate?.current.includes?.(
                                              objr.type
                                            ) &&
                                              formInner(objr, {
                                                colIndex: objr_i,
                                                rowIndex: objj_i,
                                              })}

                                            {!notAllowedForUpdate?.current.includes?.(
                                              objr.type
                                            ) &&
                                              objr?.uivalid.visible ===
                                              "false" && (
                                                <p className="inVisibleField">
                                                  Security prevents writing to
                                                  this field
                                                </p>
                                              )}
                                            {notAllowedForUpdate?.current.includes?.(
                                              objr.type
                                            ) && (
                                                <p className="inVisibleField">
                                                  Not allowed update from list
                                                </p>
                                              )}
                                            <button
                                              className="multiUpdateBtn1"
                                              onClick={() => {
                                                handleUpdateSave(objj_i, objr_i);
                                              }}
                                            >
                                              {" "}
                                              <span style={{ color: "green" }}>
                                                <i className="fa-regular fa-circle-check"></i>
                                              </span>
                                            </button>
                                            <button
                                              className="multiUpdateBtn2"
                                              onClick={() => {
                                                handleUpdateCancel(
                                                  objj_i,
                                                  objr_i
                                                );
                                              }}
                                            >
                                              <span style={{ color: "red" }}>
                                                <i className="fa-regular fa-circle-xmark"></i>
                                              </span>
                                            </button>
                                          </div>
                                        ) : objr.value?.length > 50 ? (
                                          objr.value.slice(0, 50) + " ..."
                                        ) : (
                                          objr.value
                                        ))}
                                    </span>
                                  )}
                                </td>
                              )
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {lstobj.rcd.length === 0 && (
                    <div
                      style={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        fontStyle: "normal",
                      }}
                    >
                      No Record Found
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}

        <Modal
          dialogClassName="my-modal"
          size="xl"
          show={showPref}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Personalized List Column</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mod-ht">
            <PrefCompo
              pref="user"
              colarray={prefCall}
              tablename={tableName}
              handleClose={() => handleCloseRefresh()}
            ></PrefCompo>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  } else {
    <WorkInProgress />;
  }
};

export default RelationList;
