import React, { useContext, useState } from "react";
import { MarketContext } from "../Context";
import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AlertCompo from "../small_compo/AlertCompo";

const SetNewPassword = () => {
  // useContext hook
  const { token, loca, appname } = useContext(MarketContext);

  //   useLocation hook
  const { op, un, app } = useLocation().state || 0;

  //   useState hook
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [sp, setSp] = useState(true);
  const [flag, setFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const [pageError, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [alertType, setAlertType] = useState("");
  const navigation = useNavigate();

  const logincall = () => {
    navigation("/login");
  };

  const hideError = () => {
    if (pageError) {
      setPageError(false);
      setError("");
      setAlertType("");
    }
  };

  const updateCall = () => {
    let eMsg = [];
    if (op === oldPassword) {
      console.log("innnnnnn");
      setErrorMsg([]);
      setFlag(false);
    } else {
      eMsg.push("old password is wrong!!");
      setFlag(true);
    }
    if (newPassword === confirmPassword) {
      setErrorMsg([]);
      setFlag(false);
    } else {
      eMsg.push("NewPassword and ConfirmPassword does not match!!");
      setFlag(true);
    }
    if (!eMsg.length > 0) {
      let json = {
        username: un,
        password: newPassword,
        application: app,
      };
      axios
        .post(loca + "/marketplace/set/newpassword", json, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then((resp) => {
          let result = resp.data;
          if ("Error" in result || "error" in result) {
            setError("Failed to change password");
            setAlertType("danger");
            setPageError(true);
          } else {
            hideError();
            navigation("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setErrorMsg([...eMsg]);
    }
  };

  return (
    <div className="parent-div">
      <Card className="bg-auth-1 card-width-manual">
        <CardHeader>
          <h4 className="text-center mb-0 mt-1">Set New Password</h4>
          <hr className="my-2" />
          {pageError && <AlertCompo type={alertType} msg={error} />}
        </CardHeader>

        <CardBody className="bg-auth-1 pt-0 pb-0 text-center">
          <div className="mt-2">
            <i
              className="fa fa-user"
              style={{
                border: "2.5px solid white",
                height: "4.5rem",
                width: "4.5rem",
                fontSize: "62px",
                textAlign: "center",
                borderRadius: "39px",
              }}
            ></i>
          </div>
          <div className="mt-4">
            <div className="pb-2">
              <input
                type="text"
                name="password"
                placeholder="Oldpassword"
                className=" inpptt "
                value={oldPassword}
                onChange={(e) => {
                  hideError();
                  setOldPassword(e.target.value);
                }}
              ></input>
            </div>
            <div className="pb-2">
              <input
                type={sp ? "password" : "text"}
                name="password"
                placeholder="NewPassword"
                className=" inpptt "
                value={newPassword}
                onChange={(e) => {
                  hideError();
                  setNewPassword(e.target.value);
                }}
              ></input>
            </div>

            <div className="pb-2">
              <input
                type={sp ? "password" : "text"}
                name="password"
                placeholder="ConfirmPassword"
                className=" inpptt "
                value={confirmPassword}
                onChange={(e) => {
                  hideError();
                  setConfirmPassword(e.target.value);
                }}
              ></input>
            </div>

            <div className="d-flex align-items-center  justify-content-center">
              <span> Show Password</span>
              <input
                className="ms-2 manual-checkbox"
                type="checkbox"
                checked={!sp}
                onChange={(e) => {
                  hideError();
                  setSp(!e.target.checked);
                }}
              />
            </div>
          </div>

          {flag && errorMsg.map((obj, index) => <div key={index}>{obj}</div>)}
          <div className="mt-3 mt-2 mb-3">
            <button
              className="btn btn-warning btn-width"
              onClick={() => {
                hideError();
                updateCall();
              }}
            >
              Update
            </button>
          </div>
          <hr className="mt-2 mb-0" />
        </CardBody>

        <CardFooter>
          <div className="d-flex align-items-center gap-1 mb-1 mx-1">
            <i className="fa fa-arrow-left"></i>
            <span onClick={logincall}>Login</span>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default SetNewPassword;
