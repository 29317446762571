import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import AppRouters from "./AppRouters";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppRouters/>
);
