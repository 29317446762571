import React, { useEffect, useState } from "react";
import "../css/ContextMenu.css";

export default function ContextMenu({
  menuX,
  menuY,
  contextMenu,
  menuFn,
  excluded_menu,
  conditionFn,
}) {
  
  const [menus, setMenus] = useState([]);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  useEffect(() => {
    let temp = excluded_menu
      ? contextMenu.filter((e) => !excluded_menu.includes(e.name))
      : contextMenu;

    setMenus(temp);
  }, [contextMenu]);

  const handleMouseEnter = (uni_id) => {
    setActiveSubMenu(uni_id)
  }
  const handleMouseLeave = (index) => {
    setActiveSubMenu(null)
  }
  return (
    <ul
      className="dropdown-menu"
      style={{
        display: "block",
        top: menuY.current,
        left: menuX.current,
        padding: "0px",
        borderRadius: "0px",
        fontSize: "14px",
      }}
    >
      {conditionFn &&
        menus.map((obj, index) =>
          obj.isParent ? (
            <li
              className="cursor-pointer position-relative"
              key={index}

              onMouseEnter={() =>
                handleMouseEnter(
                  obj.contextMenu_id
                )
              }
              onMouseLeave={() => {
                handleMouseLeave()
              }}
            >
              <a
                style={{
                  borderBottom:
                    index !== menus.length - 1 && "1px solid #e3e3e3",
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="dropdown-item px-2"
              >
                {/*      {obj.Label} */}
                <span>{obj.Label}</span>
                <span>{">"}</span>
              </a>
              {obj.contextMenu_id === activeSubMenu && <ul
                className="submenu"
                style={{
                  listStyleType: "none",
                  paddingLeft: "0px",
                  top: "0px"
                  /*                   top: menuY.current,
                   */                /*  left: menuX.current+20,  */
                }}
              >
                {obj.subContextMenu.map((subobj, ind) => (
                  obj.contextMenu_id === activeSubMenu && (<li
                    // "xf" && (  <li
                    className="submenu-item"
                    key={ind}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                    onMouseDown={() => {
                      menuFn(subobj.script);
                    }}
                  >
                    <span style={{
                      borderBottom:
                        ind !== obj.subContextMenu.length - 1 && "1px solid #e3e3e3",
                      fontWeight: "500",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                      className="dropdown-item px-1 py-1">{subobj.Label}</span>
                  </li>)
                ))}
              </ul>}
            </li>
          ) : (
            <li
              className="cursor-pointer"
              key={index}
              onMouseDown={() => {
                menuFn(obj.script);
              }}
            >
              <a
                style={{
                  borderBottom:
                    index !== menus.length - 1 && "1px solid #e3e3e3",
                  fontWeight: "500",
                }}
                className="dropdown-item px-2 py-1"
              >
                {obj.Label}
              </a>
            </li>
          )
        )}
    </ul>
  );
}
