import React, { useContext, useEffect, useState } from 'react'
import { AutoPortalContext, MarketContext } from '../Context';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';


const KnowledgeCategoryCompo = () => {

  const { token ,loca,appname} = useContext(MarketContext);
  const navigation = useNavigate();
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState([]);
  const [error, setError] = useState(true);
  const [pageError, setPageError] = useState(true);
  const [categories, setCategories] = useState([]);
  const location = useLocation().state;

  const getKnowledgeCategory = (categoryName) => {

    axios
      .get(loca + "/marketplace/get/CategoryAndKnowledge/" + categoryName, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          const rvrecord = resp.data.knowledgeCategory;
          if ("Error" in rvrecord) {
            setPageError(true);
            setError(rvrecord.error);
            setLoading(false);
          } else {
            setCategories(rvrecord)
            setRecord(rvrecord);
            setLoading(false);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const callKnowledge = (obj) => {
    navigation("/knowledge", { state: { knowledge: obj } })
  };

  useEffect(() => {
    getKnowledgeCategory(location.value);
  }, []);

  const modSearch = (nam) => {
    if (nam.length > 2) {
      var srch = categories;
      let xyz = [];
      for (let u = 0; u < srch.length; u++) {
        let search = srch[u];
        var abc = [];
        // for (var i = 0; i < search.length; i++) {
          var cat_name = search.categoryName.toLowerCase();
          if (cat_name.includes(nam.toLowerCase())) {
            abc.push({
              categoryName: search.categoryName,
              knowledge: search.knowledge,
            });
          } else {
            var cnt = 0;
            var kno = [];
            for (var j = 0; j < search.knowledge.length; j++) {
              var sec_m = search.knowledge[j].knowledgeName.toLowerCase();
              if (sec_m.includes(nam.toLowerCase())) {
                kno.push(search.knowledge[j]);
                  cnt++;
              }
            }
            if (cnt > 0) {
              abc.push({
                categoryName: search.categoryName,
                knowledge: kno,
              });
            }
          }
        // }
        for (let i = 0; i < abc.length; i++) {
          
          xyz.push(abc[i]);
        }
      }
        setRecord(xyz);
    } else if (nam === "") {
      var srch = categories;
      setRecord([...srch]);
    }
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <div className="list-group ">
        <div className="card p-3" style={{ display: "flex" }}>
          <div id="sidebar" className="mb-2">
            <input
              className="form-control mr-sm-2 "
              type="search"
              placeholder="Search category ..."
              aria-label="Search"
              onChange={(e) => modSearch(e.target.value)}
            ></input>
          </div>
          <div>
            {record.length > 0 &&
              record.map((obj, ind) => (
                <ul key={ind}>
                  <li>
                    {obj.categoryName}
                    {obj.knowledge.length > 0 &&
                      obj.knowledge.map((objl, inx) => (
                        <div
                        key={inx}
                          style={{ cursor: "pointer" }}
                          onClick={() => callKnowledge(objl, ind)}
                        >
                          {objl.knowledgeName}
                        </div>
                      ))}
                  </li>
                </ul>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default KnowledgeCategoryCompo