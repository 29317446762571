import React, { useState, useContext, useEffect, useRef } from "react";
import "../css/LoadData.css";
import axios from "axios";
import { MarketContext } from "../Context";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";

const LoadData = () => {
  const { token, loca, user } = useContext(MarketContext);
  const [importSetTable, setImportSetTable] = useState("create");
  const [label, setLabel] = useState("");
  const [name, setName] = useState("");
  const [source, setSource] = useState("file");
  const [file, setFile] = useState(null);
  const [sheetNumber, setSheetNumber] = useState("1");
  const [headerRow, setHeaderRow] = useState("1");
  const [tableRecord, setTableRecord] = useState([{}]);
  const [importTable, setImportTable] = useState("");
  const [pageError, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [pageMessage, setPageMessage] = useState(false);
  const [searchParam, setSearchParam] = useSearchParams();
  const [message, setMessage] = useState("");
  const [buttonDis, setButtonDis] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [subLoadAck, setSubLoadAck] = useState("");

  const navigation = useNavigate();
  const loadtext = useRef(null);
  const parentApp = useRef(null);

  const handleErrMsg = () => {
    setPageError(false);
    setError("");
    setPageMessage(false);
    setMessage("");
  };

  const handleImportSetTableChange = (event) => {
    handleErrMsg();
    setImportSetTable(event.target.value);
    getTable();
  };

  const handleLabelChange = (event) => {
    handleErrMsg();
    setLabel(event.target.value);
    if (event.target.value !== "") {
      let val = "import_" + event.target.value;
      const transformed = val.toLowerCase().replace(/ /g, "_");
      setName(transformed);
    } else {
      setName(event.target.value);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSourceChange = (event) => {
    handleErrMsg();
    setSource(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSheetNumberChange = (event) => {
    setSheetNumber(event.target.value);
  };

  const handleHeaderRowChange = (event) => {
    setHeaderRow(event.target.value);
  };

  const getTable = () => {
    axios
      .get(loca + "/import/table/data", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        const tabRcd = resp.data;
        console.log("tabRcd: ", tabRcd);

        setTableRecord(tabRcd.setTable);
      });
  };

  const formChangeTable = (e) => {
    setImportTable(e);
  };

  const handleSubmit = () => {
    setButtonDis(true);
    setSubmitLoading(true);
    if (importSetTable === "create") {
      if (label === "" || file === null) {
        setPageError(true);
        setError("Fill required fields");
        setSubmitLoading(false);
      }
    } else if (importSetTable === "existing") {
      if (file === null) {
        setPageError(true);
        setError("Fill required fields");
        setSubmitLoading(false);
      }
    }

    if (file !== null) {
      let body = {
        records: [
          {
            importSetTable: importSetTable,
            label: label,
            name: name,
            source: source,
            sheetNumber: sheetNumber,
            headerRow: headerRow,
            importTable: importTable,
          },
        ],
      };
      console.log("body", body);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("jsonString", JSON.stringify(body));
      console.log("formData", formData);

      axios
        .post(loca + `/import/set/data`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          let rsp = resp.data;
          console.log(rsp);
          if ("Error" in rsp) {
            setPageError(true);
            setError(rsp.Error);
            setSubmitLoading(false);
          } else {
            setPageMessage(true);
            setMessage(rsp.Message);
            setSubmitLoading(false);
            navigation({
              pathname: user + "/list",
              search: createSearchParams({
                tableName: "import_set",
              }).toString(),
            });
          }
        }).catch((err)=>{
          setPageError(true);
          setError("Something went wrong");
          setSubmitLoading(false);
        })
        .finally(() => {
          setButtonDis(false);
        });
    }
  };

  useEffect(() => {
    if (submitLoading) {
      const messageTimeouts = [
        setTimeout(() => {
          loadtext.current?.classList.add("show");
          setSubLoadAck("Hang tight, we're processing your request...");
        }, 2000),
        setTimeout(
          () =>
            setSubLoadAck("Working on it... This might take a few moments."),
          7000
        ),
      ];

      // Clear timeouts when component unmounts
      return () => {
        loadtext.current?.classList.add("hide");
        setSubLoadAck("");
        messageTimeouts.forEach((timeout) => clearTimeout(timeout));
      };
    }
  }, [submitLoading]);

  return (
    <div style={{ width: "100%" }}>
      {submitLoading && (
        <>
          <div
            style={{ height: "100vh", width: "75%" }}
            className="loading-overlay d-flex align-items-center justify-content-center"
          ></div>
          <div style={{ height: "100vh" }} className="loading-ack">
            <h3 ref={loadtext} className="loading-msg">
              {subLoadAck}
            </h3>
          </div>
        </>
      )}
      <div className="pt-2">
        <div className="justify-content-between">
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "20px",
              backgroundColor: "#f5f7fa",
            }}
          >
            {"Load Data"}
          </div>
        </div>
      </div>

      <div>
        {pageError === true && (
          <div
            className="alert alert-danger"
            //  form_alt
            role="alert"
            style={{
              padding: "0.2rem 0.2rem",
              marginBottom: "0px",
              color: "black",
            }}
          >
            {error}
          </div>
        )}

        {pageMessage === true && (
          <div
            className="alert alert-success"
            style={{
              padding: "0.2rem 0.2rem",
              marginBottom: "0px",
              color: "black",
            }}
            role="alert"
          >
            {message}
          </div>
        )}
      </div>

      <div className="load-data-page">
        <div className="form-group">
          <label
            for="inputTable"
            className="col-sm-3 control-label"
            style={{ textAlign: "right", fontWeight: "bold" }}
          >
            Import Set Table
          </label>
          <div className="row">
            <div className="col-sm-4" style={{ marginLeft: "23rem" }}>
              <label for="used_table_rb">
                <input
                  style={{ marginRight: "5px" }}
                  type="radio"
                  value="create"
                  checked={importSetTable === "create"}
                  onChange={handleImportSetTableChange}
                />
                Create table
              </label>
            </div>
            <div className="col-sm-4" style={{ marginLeft: "23rem" }}>
              <label for="used_table_rb">
                <input
                  style={{ marginRight: "5px" }}
                  type="radio"
                  value="existing"
                  checked={importSetTable === "existing"}
                  onChange={handleImportSetTableChange}
                />
                Existing table
              </label>
            </div>
          </div>
        </div>

        {importSetTable === "create" && (
          <div>
            <div className="form-group ldata_mrg">
              {name.trim() !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}
              {name.trim() === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}
              <label className="mrg_nam">Label</label>
              <input
                className="txt_wdth"
                type="text"
                value={label}
                onChange={handleLabelChange}
              />
            </div>

            <div
              className="form-group"
              style={{ marginTop: "10px", marginLeft: "19rem" }}
            >
              <label className="mrg_nam">Name</label>
              <input
                className="txt_wdth"
                type="text"
                disabled="true"
                value={name}
                // onChange={handleNameChange}
              />
            </div>
          </div>
        )}

        {importSetTable === "existing" && (
          <div className="row form-group">
            {/* <div className="col-sm-3"> */}
            <label
              for="inputTable"
              className="col-sm-3 control-label"
              style={{ textAlign: "right" }}
            >
              Import set table
            </label>
            {/* </div> */}
            <div className="col-sm-5">
              <select
                className="form-control form-select"
                aria-label="Default select example"
                onChange={(e) => formChangeTable(e.target.value)}
                value={importTable}
              >
                {tableRecord?.map((obj, index) => (
                  <option key={index} value={obj.name}>
                    {obj.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        <div className="form-group">
          <label
            for="inputTable"
            className="col-sm-3 control-label"
            style={{ textAlign: "right", fontWeight: "bold" }}
          >
            Source of the import
          </label>
          <div className="row">
            <div className="col-sm-4" style={{ marginLeft: "23rem" }}>
              <label for="used_table_rb">
                <input
                  style={{ marginRight: "5px" }}
                  type="radio"
                  value="file"
                  checked={source === "file"}
                  onChange={handleSourceChange}
                />
                File
              </label>
            </div>
          </div>

          {source === "file" && (
            <div>
              <div style={{ marginLeft: "21rem", marginTop: "5px" }}>
                <label style={{ marginRight: "10px" }}>File</label>
                <input type="file" onChange={handleFileChange} />
              </div>

              <div
                className="form-group ldata_mrg"
                style={{ marginTop: "10px" }}
              >
                <label className="mrg_nam">Sheet number</label>
                <input
                  className="txt_wdth"
                  type="number"
                  value={sheetNumber}
                  onChange={handleSheetNumberChange}
                />
              </div>

              <div
                className="form-group"
                style={{ marginTop: "10px", marginLeft: "19rem" }}
              >
                <label className="mrg_nam">Header row</label>
                <input
                  className="txt_wdth"
                  type="number"
                  value={headerRow}
                  onChange={handleHeaderRowChange}
                />
              </div>
            </div>
          )}
        </div>

        <button
          className=" insrtbtn2 btn btn-primary btn-sm mx-1 "
          onClick={handleSubmit}
          disabled={buttonDis}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default LoadData;
