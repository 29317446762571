import React, { useContext, useEffect, useRef, useState } from "react";
import WorkInProgress from "./WorkInProgress";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import Header from "./Header";
import { isMobile, isTablet } from "react-device-detect";
import { MarketContext } from "../Context";

const MultipleAppAccess = () => {
  // useContext hooks
  const navigation = useNavigate();
  const { token, loca } = useContext(MarketContext);

  // useState hooks
  const [isColArrSelected, setIsColArrSelected] = useState(true);
  const [isColArr2Selected, setIsColArr2Selected] = useState(true);
  const [columnarray, setColumnarray] = useState([]);
  const [columnarray2, setColumnarray2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mainApps, setMainApps] = useState([
    { label: "None", id: "none", value: "none" },
  ]);
  const [json, setJson] = useState({ mainApp: "", secApps: [] });
  const [isProcessing, setProcessing] = useState(false);
  const [isSet, setIsSet] = useState(false);
  const [page_error, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [page_message, setPageMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isClick, setIsClick] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedApp, setSelectedApp] = useState({
    label: "None",
    id: "none",
    value: "none",
  });
  const [searchParam, setSearchParam] = useSearchParams();
  const [srcRecord, setSrcRecord] = useState([]);

  // useRef hooks
  const parentApp = useRef("");
  const ind = useRef(0);
  const index = useRef(0);

  const getAppList = () => {
    let url = loca + "/get/jwtapp/list";
    let header = {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    };

    axios
      .get(url, {
        headers: header,
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log(res);
          let recordList = res.jwtAppList;
          if ("Error" in recordList) {
          } else {
            let appId = [];
            let appName = [];
            let appLabel = [];
            for (let i = 0; i < recordList.length; i++) {
              appId.push(recordList[i].id);

              appName.push(recordList[i].name);

              appLabel.push(recordList[i].label);
            }
            let apps = [];
            for (let i = 0; i < appName.length; i++) {
              apps.push({
                label: appLabel[i],
                id: appId[i],
                value: appName[i],
              });
            }
            console.log(apps);

            apps.unshift({ label: "None", id: "none", value: "none" });
            setMainApps(apps);
            setLoading(false);
          }
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const getInitialDetails = (val) => {
    setError("");
    setMessage("");
    setPageMessage(false);
    setPageError(false);

    let url = loca + "/marketplace/get/applications";

    axios
      .post(url, json, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          console.log(result);
          setColumn(result.sec_apps);
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const setColumn = (prf) => {
    var clm = [];
    var clm2 = [];
    console.log("jjj : " + JSON.stringify(clm), prf);

    if (prf) {
      for (var p = 0; p < prf.length; p++) {
        clm.push(prf[p]);
        if (prf[p].access === "true") {
          clm2.push(prf[p]);
        }
      }
    }
    // }
    console.log(clm);
    setColumnarray([...clm]);
    setColumnarray2([...clm2]);
  };

  const selected = (index, clm) => {
    console.log(index, clm);
    if (clm === "clm1") {
      setIsColArrSelected(true);
      setIsColArr2Selected(false);
      for (let i = 0; i < srcRecord.length; i++) {
        if (i === index) {
          document
            .getElementById("clm1")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("clm1")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < columnarray2.length; i++) {
        document
          .getElementById("clm2")
          .children.item(i)
          .classList.remove("selected-row");
      }
    } else {
      setIsColArrSelected(false);
      setIsColArr2Selected(true);
      ind.current = index;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === index) {
          document
            .getElementById("clm2")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("clm2")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < srcRecord.length; i++) {
        document
          .getElementById("clm1")
          .children.item(i)
          .classList.remove("selected-row");
      }
    }
  };

  const shiftRight = () => {
    console.log(json);
    let clm = srcRecord;
    let clm2 = columnarray2;
    if (document.getElementsByClassName("selected-row").item(0)) {
      let lab = document
        .getElementsByClassName("selected-row")
        .item(0).innerText;
      console.log(lab);
      for (let i = 0; i < srcRecord.length; i++) {
        // console.log(clm[i].label, lab, clm[i].type);
        if (i === index.current) {
          console.log("checkkkkkkk");
          document
            .getElementsByClassName("selected-row")
            .item(0)
            .classList.remove("selected-row");
          if (clm[i].type !== "ui") {
            // clm[i].check = true;
            clm[i].access = "true";
          }

          clm2.push(clm[i]);
        }
      }
      console.log(clm);
      console.log(clm2);
      setIsColArrSelected(false);
      console.log(isColArrSelected);
      setSrcRecord([...clm]);
      // setRcd([...clm]);
      setColumnarray2([...clm2]);
      setIsSet(true);
    }
  };

  const shiftLeft = () => {
    console.log(json);

    let clm = columnarray;
    let clm2 = columnarray2;
    let name = "";
    if (document.getElementsByClassName("selected-row").item(0)) {
      console.log(
        "shift left : " +
          document.getElementsByClassName("selected-row").item(0)
      );
      let lab = document
        .getElementsByClassName("selected-row")
        .item(0).innerText;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === ind.current) {
          name = clm2[i].name;
          clm2.splice(i, 1);
          document
            .getElementsByClassName("selected-row")
            .item(0)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < columnarray.length; i++) {
        // console.log(clm[i], name);

        if (clm[i].name === name) {
          clm[i].access = "false";
        }
      }
      console.log(clm);
      setIsColArr2Selected(false);
      console.log(isColArr2Selected);
      setColumnarray2([...clm2]);
      setIsSet(true);
    }
  };

  const submitColumnbtn = () => {
    setIsClick(true);
    setLoading(true);
    setError("");
    setMessage("");
    setPageMessage(false);
    setPageError(false);
    setIsSet(false);
    setProcessing(true);

    json.secApps = [];
    for (let i = 0; i < columnarray2.length; i++) {
      // json.secApps_id.push(columnarray2[i].id);
      json.secApps.push(columnarray2[i].name);
    }

    console.log(JSON.stringify(json));
    axios
      .post(loca + "/marketplace/set/multi/appaccess", json, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          console.log("result : " + JSON.stringify(result));
          if ("Error" in result) {
            setPageError(true);
            setError(result.Error);
          } else {
            toast.success(result.Message);
            setPageMessage(true);
            setMessage(result.Message);
          }
          setLoading(false);
          setProcessing(false);
        },
        (error) => {
          navigation("/error");
          setProcessing(false);
          toast(error, { position: "top-center", type: "error" });
          console.log(error);
        }
      )
      .finally(() => {
        console.log("finally");
        setIsClick(false);
      });
  };

  const setAppId = (val) => {
    console.log(val);

    setSelectedApp(val);
    let name = val.value;
    let j = json;
    j.mainApp = name;
    setJson({ ...j });

    if (val.id !== "none") {
      getInitialDetails(val);
    }
  };

  const searchApp = (nam) => {
    console.log(nam, srcRecord);
    let arr = [];
    if (nam.length > 2) {
      for (let i = 0; i < columnarray.length; i++) {
        console.log(columnarray[i].label, nam);
        let srcLabel = columnarray[i].label.toLowerCase();
        let includes = srcLabel.includes(nam.toLowerCase());
        console.log("includes", includes);
        if (includes) {
          arr.push(columnarray[i]);
        }
      }
    } else {
      arr = columnarray;
    }

    setSrcRecord([...arr]);
  };

  //   useEffect hooks
  useEffect(() => {
    setLoading(true);
    parentApp.current = searchParam.get("parentApplication");
    setSelectedApp({
      label: "None",
      id: "none",
      value: "none",
    });

    setProcessing(false);
    getAppList();
  }, [searchParam, refresh]);

  useEffect(() => {
    console.log(columnarray, srcRecord);
    setProcessing(false);
    setSrcRecord([...columnarray]);
  }, [columnarray]);

  if (!loading) {
    return (
      <div id={"form"} className="pagesetup">
        <Header
          tabName={"Multiple Applications Access"}
          isClick={isClick}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        <div
          style={{ flexGrow: 1 }}
          className="container justifyContentCenter align-items-center my-5 "
        >
          <div className="d-flex justify-content-center mb-2">
            <div style={{ width: "60%" }}>
              <h4 className="text-start">Select Application </h4>

              <Select
                isSearchable={true}
                options={mainApps}
                value={selectedApp}
                onChange={(e) => {
                  setAppId(e);
                }}
              />
            </div>
            {page_error === true && (
              <div
                className="alert alert-danger"
                role="alert"
                style={{
                  padding: "0.2rem 0.2rem",
                  marginBottom: "0px",
                }}
              >
                {error}
              </div>
            )}
          </div>
          {selectedApp.value !== "none" && (
            <div className="row  justify-content-center">
              <div id="" className="col-md-4">
                <div className="search-container">
                  {/* search for columnarray */}

                  <input
                    placeholder="Search... "
                    type="search"
                    className="form-control mb-2"
                    style={{
                      width: "100%",
                      transform: "translateX(-8px)",
                    }}
                    onChange={(e) => {
                      searchApp(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="up-down">
                <div>
                  <button
                    style={{ visibility: "hidden" }}
                    className="ryt-left-btn"
                    disabled={isColArr2Selected}
                    onClick={() => {
                      shiftRight();
                    }}
                  >
                    {">"}
                  </button>
                </div>
              </div>
              <div className="col-md-4 "></div>
            </div>
          )}
          {selectedApp.value !== "none" && (
            <div
              className="row"
              style={{ justifyContent: "center", gap: "3px" }}
            >
              <div id="clm1" className="col-md-4 box-pref">
                {srcRecord.length > 0 &&
                  srcRecord.map((obj, obj_i) => (
                    <div key={obj_i}>
                      {obj.access === "false" && (
                        <div className="row fr">
                          <div
                            className="col-md columnarray2"
                            onClick={() => {
                              selected(obj_i, "clm1");
                              index.current = obj_i;
                            }}
                          >
                            {obj.label}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              {!(isMobile || isTablet) ? (
                <div className="up-down">
                  <div>
                    <button
                      className="ryt-left-btn"
                      disabled={isColArr2Selected}
                      onClick={() => {
                        shiftRight();
                      }}
                    >
                      {">"}
                    </button>
                  </div>
                  <div>
                    <button
                      className="ryt-left-btn"
                      disabled={isColArrSelected}
                      onClick={() => {
                        shiftLeft();
                      }}
                    >
                      {"<"}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="up_down d-flex justify-content-center">
                  <button
                    disabled={isColArrSelected}
                    className="up-down-view-pref"
                    onClick={(e) => shiftLeft()}
                  >
                    {">"}
                  </button>
                  <button
                    disabled={isColArr2Selected}
                    className="up-down-view-pref"
                    onClick={(e) => shiftRight()}
                  >
                    {"<"}
                  </button>
                </div>
              )}
              <div id="clm2" className="col-md-4 box-pref">
                {columnarray2.map((obj, index) => (
                  <p
                    style={{ margin: 0 }}
                    key={index}
                    className="columnarray2"
                    onClick={() => {
                      selected(index, "clm2");
                    }}
                    value={obj.label}
                  >
                    {obj.label}
                  </p>
                ))}
              </div>
              <div className="row my-3  " style={{ justifyContent: "center" }}>
                <div className="col-md-3" style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    style={{ borderRadius: "5px" }}
                    className=" btnnn btn btn-primary"
                    disabled={!isSet}
                    onClick={() => {
                      submitColumnbtn();
                    }}
                  >
                    {isProcessing ? (
                      <span>
                        <span
                          className="spinner-grow spinner-grow-sm me-2"
                          role="status"
                        ></span>
                        Loading...
                      </span>
                    ) : (
                      "submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="d-flex justify-content-center align-items-center w-100"
        style={{ height: "95vh" }}
      >
        <WorkInProgress />
      </div>
    );
  }
};

export default MultipleAppAccess;
