import React, { useContext, useEffect, useState } from "react";
import "../css/PrivateWrapper.css";
import { Outlet, useNavigate, createSearchParams, Navigate } from "react-router-dom"; //Navigate,
import { MarketContext } from "../Context.jsx";
import LeftNavigationComponent from "../component/LeftNavigationComponent.jsx";
import { Button, Dropdown} from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import CogCompo from "../component/CogCompo.jsx";
import axios from "axios";
import UserNotificationCompo from "../component/UserNotificationCompo.jsx";
import LogoutModal from "../small_compo/LogoutModal.jsx";
import CommonModal from "../small_compo/CommonModal.jsx";
import UpdateJsonCompo from "../component/UpdateJsonCompo.jsx";
import { } from "react-icons/fa"

const PrivateWrapper = () => {
  // let subtitle;
  const {
    token,
    flagg,
    setFlagg,
    adminVerified,
    userDetails,
    cnt,
    setCnt,
    loca,
    msg,
    setShowAll,
    showAll,
    user,
    appname,
    setheadHeight,
    impersonate,
  } = useContext(MarketContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showCog, setShowCog] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [logContent, setLogContent] = useState("");
  const [currentRec, setCurrentRec] = useState();
  const [unselectedRec, setUnselectedRec] = useState([]);
  const navigate = useNavigate();
  const Home = () => {
    navigate("/dashboard");
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const opennav = () => {
    document.getElementById("mySidebar").style.width = "100%";
    document.getElementById("mySidebar").style.marginLeft = "0";
    // window.scroll({ top: 0, behavior: "smooth" });
    setFlagg(true);
  };

  const openModal = () => {
    setIsOpen(true);
    // setFlagg(false);
  };

  const cogfn = () => {
    setShowCog(true);
  };

  const cancelNotification = () => {
    setShowNotification(false);
  };
  const cancelCog = () => {
    setShowCog(false);
  };
  const cogChangefn = (val) => {
    let url = "";
    if (val === "true") {
      url = "/marketplace/set/visible-all";
    } else {
      // url = "/marketplace/set/visible-all"
      url = "/marketplace/set/visible";
    }
    // setShowCog(false);
    axios
      .get(loca + url, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          setShowAll(val);
          localStorage.setItem("showAll", val);
          // getApplicationsDetails();
        },
        (error) => {
          navigate("/error");
          setShowAll(val);
          localStorage.setItem("showAll", val);
        }
      );
  };

  const callform = (tab, r_id, pageClicked, filtarray, sortColumn) => {
    let pg = { page: pageClicked, filter: filtarray, sort: sortColumn };
    localStorage.setItem("pageClicked", JSON.stringify(pg));
    navigate({
      pathname: user + "/form",
      search: createSearchParams({
        rty: "record",
        tableName: tab,
        rid: r_id,
      }).toString(),
    });
    cancelNotification();
  };
  const getLog = () => {
    const url = "/marketplace/get/log";
    axios
      .get(loca + url, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const data = resp.data;
          setLogContent(data.content);
        },
        (error) => {
          navigate("/error");
        }
      );
  };
  const logFun = () => {
    getLog();
    setShowLog(true);
  };

  const cancelLog = () => {
    setShowLog(false);
  };

  const getInitial = () => {
    axios
      .get(loca + "/marketplace/get/updatejson", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          let rec = res.record;
          let arr = [];
          let curr = undefined;
          for (let i = 0; i < rec.length; i++) {
            if (rec[i].selected === "true") {
              curr = rec[i];
            } else {
              arr.push(rec[i]);
            }
          }
          setCurrentRec(curr);
          setUnselectedRec([...arr]);
          // setUpdateJsonRec(res.record);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const clearLog = () => {
    const url = "/marketplace/delete/log";
    axios
      .get(loca + url, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const data = resp.data;
          setLogContent(data.content);
        },
        (error) => {
          navigate("/error");
        }
      );
  };

  useEffect(() => {
    let h = document.getElementById("topbar")?.getBoundingClientRect().height;
    if (h > 10) {
      setheadHeight(h);
    }
  }, []);

  useEffect(() => {
    console.log(adminVerified,userDetails);
    if (adminVerified && userDetails) {
      
      if (userDetails.isadmin !== "true" && impersonate) {
        navigate("/user/dashboard");
        return;
      }
    }
  }, [adminVerified, userDetails]);

  const navModals = (show, hide, title, body, footer) => {
    return (
      <CommonModal
        show={show}
        hide={hide}
        title={title}
        body={body}
        footer={footer}
      />
    );
  };
  if (adminVerified && userDetails) {
    return (
      <>
        <div>
          <div className="mainTopBar">
            <div
              style={
                showAll === "true"
                  ? { background: "red" }
                  : appname !== "marketplace"
                  ? { background: "#237fd1" }
                  : {}
              }
              className="disfl mainbgcolor gutter"
            >
            {/* <div
              style={
                showAll === "true"
                  ? { background: "red" }
                  : appname !== "marketplace"
                  ? { background: "cadetblue" }
                  : {}
              }
              className="disfl mainbgcolor gutter"
            > */}
              <div className="fl1 no_pad disfl">
                {!flagg && (
                  <div className="gutter">
                    <div className="arrow_icon no_pad">
                      <span>
                        <i
                          className="primary_clr backic fa fa-bars"
                          
                          onClick={opennav}
                        ></i>
                      </span>
                    </div>
                  </div>
                )}
                <div className="marleft10 marright10">
                  <div className="homeicon">
                    <i
                      className="fa fa-home home_top"
                      data-toggle="tooltip"
                      title="Home"
                      
                      onClick={() => Home()}
                    ></i>
                  </div>
                </div>
                <span className="text-light u-name text-capitalize">
                  {appname}
                </span>
              </div>
              <div className="fl1 no_pad">
                <div className="rig ">
                  <span className="u-name1 text-capitalize">
                    {userDetails.username}
                  </span>
                  <span
                    className="usericon "
                    onClick={() => navigate("/application")}
                  >
                    Set
                  </span>
                  <span className="usericon">
                    <i
                      className="fa fa-user-circle-o"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Profile"
                      
                      onClick={() => openModal()}
                    ></i>
                  </span>

                  <span className="usericon ">
                    <i
                      className="fa fa-bell"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Notification"
                      
                      onClick={() => setShowNotification(true)}
                    >
                      {cnt > 0 && <span className="span-cnt">{cnt}</span>}
                    </i>
                  </span>

                  <span className="usericon ">
                    <i
                      className="fa fa-cog"
                      
                      onClick={() => cogfn()}
                    ></i>
                  </span>
                  <span className="usericon ">
                      <i
                        className="fa-brands fa-css3"
                        onClick={() => logFun()}
                      ></i>
                    </span>
                    <span className="usericon ">
                      <Dropdown id="updatedrop" as="span">
                        <span>
                          <Dropdown.Toggle
                            as="span"
                            className="text-white "
                            variant="Dark"
                            id="dropdown-basic"
                          >
                            <span
                              onClick={() => {
                                if (
                                  !document
                                    .getElementById("updatemenu")
                                    ?.classList.contains("show")
                                ) {
                                  getInitial();
                                }
                              }}
                              className="text-warning fw-bold"
                              style={{
                                fontSize:"15px"
                              }}
                            >
                              {"{JSON}"}
                            </span>
                          </Dropdown.Toggle>
                        </span>
                        <Dropdown.Menu id="updatemenu" className="me-5">
                          <UpdateJsonCompo
                            unRec={unselectedRec}
                            currRec={currentRec}
                            getInitial={getInitial}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row fr">
            {flagg && (
              <div
                className="g-overlay"
                onClick={() => {
                  setFlagg(false);
                }}
              ></div>
            )}
            <div
              id="windowscreen"
              className={flagg ? "cl_small gx-0" : "cl_close"}
            >
              <LeftNavigationComponent />
            </div>

            <div className={flagg ? "cl_big gx-0 g-blur" : "cl_bigClose gx-0"}>
              <ToastContainer autoClose={2000} />
              <Outlet />
            </div>
          </div>
        </div>

        <LogoutModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          username={userDetails.username}
        />

        {navModals(
          showNotification,
          cancelNotification,
          "Notification",
          <UserNotificationCompo
            setCnt={setCnt}
            msg={msg}
            token={token}
            loca={loca}
            callform={callform}
            cancelNotification={cancelNotification}
          />,
          <Button variant="secondary" onClick={cancelNotification}>
            Close
          </Button>
        )}

        {navModals(
          showCog,
          cancelCog,
          "Extras",
          <CogCompo cogChangefn={cogChangefn} cancelCog={cancelCog} />,
          <button
            className="btn btn-danger"
            variant="secondary"
            onClick={cancelCog}
          >
            Close
          </button>
        )}
             {navModals(
              showLog,
              cancelLog,
              "Log File",
              <div>
                <pre
                  style={{
                    fontSize: "small",
                    whiteSpace: "pre-wrap",
                    overflowY: "auto",
                    maxHeight: "60vh",
                  }}
                >
                  {logContent}
                </pre>
              </div>,
              <>
                <button
                  className="btn btn-danger"
                  variant="secondary"
                  onClick={clearLog}
                >
                  Clear Log
                </button>
                <button
                  className="btn btn-danger"
                  variant="secondary"
                  onClick={cancelLog}
                >
                  Close
                </button>
              </>
            )}
      </>
    );
  }
  return <Navigate to="/login" replace={true} />;
};


export default PrivateWrapper;
